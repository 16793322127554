import { Column } from "primereact/column";
import { Footer } from "./Footer";
import { Table as TableComponent } from "./Table";

export { useTableSelection } from "./hooks";
export { buildColumnProps, type ColumnProps } from "./Column";
export const Table = Object.assign(TableComponent, {
  Column,
  Footer,
});
