import { useFeatureFlag, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";
import { asModuleTheme } from "@simplicate/utils";

export const BusinessIntelligence = () => {
  const { t } = useTranslation("main_menu");
  const hasPermission = usePermission("businessintelligence", "view").granted;
  const featureEnabled = useFeatureFlag("business-intelligence").enabled;
  const themeClass = asModuleTheme("business-intelligence");

  if (!featureEnabled || !hasPermission) {
    return null;
  }

  return (
    <NavMenu.Item
      href="/business-intelligence"
      className={themeClass}
      icon="business-intelligence"
      title={t("business_intelligence")}
      testId="business-intelligence-menu-item"
    />
  );
};
