import { useGetUnreadNotificationCountQuery } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Icon } from "@simplicate/ui";
import { useCallback } from "react";
import { TopBarButton } from "../TopBarButton";
import { NotificationList } from "./components";

const POLLING_INTERVAL = 5 * 60 * 1_000; // 5 minutes

export const Notifications = () => {
  const { t } = useTranslation("top_bar");
  const { data, refetch } = useGetUnreadNotificationCountQuery(undefined, {
    pollingInterval: POLLING_INTERVAL,
    refetchOnFocus: true,
    skipPollingIfUnfocused: true,
  });

  const refreshNotifications = useCallback(() => void refetch(), [refetch]);

  return (
    <TopBarButton
      aria-label={t("label_notifications_button")}
      testId="top-bar-notifications-button"
      title={t("notifications")}
      dropdownComponent={<NotificationList />}
      badge={data?.data.unread ?? 0}
      dropdownHeight="limitedWithScroll"
      onDropdownClose={refreshNotifications}
    >
      <Icon icon="bell" />
    </TopBarButton>
  );
};
