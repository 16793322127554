/* eslint-disable import/no-unresolved -- we actually want to import the legacy browser here */
//eslint-disable-next-line @nx/enforce-module-boundaries -- we actually want to import the legacy browser here
import "@simplicate/browser";
import { CSSProperties } from "react";
import { useBackdropEventListener } from "./useBackdropEventListener";
import { useNavigationDispatch } from "./useNavigationDispatch";
import { useNotificationListener } from "./useNotificationListener";
import { useWebComponentNavigationEffect } from "./useWebComponentNavigationEffect";

export const LegacyWebComponent = () => {
  useBackdropEventListener();
  useNavigationDispatch();
  useWebComponentNavigationEffect();
  useNotificationListener();

  /* istanbul ignore next */
  if (!window.customElements.get("legacy-app")) {
    console.error("Web component not available.");

    return null;
  }

  // React/JSX does not support className on web component, so we have to use
  // inline styles here.
  const style: CSSProperties = {
    display: "block",
  };

  return <legacy-app style={style} data-testid="legacy-app" />;
};
