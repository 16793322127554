import { simplicateApiV2 } from "../components";
import { createTagTypes } from "../tags";
import { Response } from "./types";
import { transformToIsoDates } from "./utils";

type ExportMeta = {
  countInProgress: number;
  countSuccess: number;
  countOpen: number;
  countFailed: number;
  countTotal: number;
};

export enum ExportStatusCode {
  OPEN = "1",
  IN_PROGRESS = "2",
  SUCCESS = "3",
  FAILED = "4",
  DOWNLOADED = "5",
  DELETED = "6",
}

export type Export = {
  status: ExportStatusCode;
  model: string;
  created_at: string;
  updated_at: string;
  id: string;
  employee: {
    id: string;
    name: string;
  };
  is_stuck: boolean;
};

const tags = createTagTypes({
  tagPrefix: "Exports",
  tags: ["list"],
});

const endpoints = simplicateApiV2.enhanceEndpoints({ addTagTypes: Object.values(tags) }).injectEndpoints({
  endpoints: (builder) => ({
    getExports: builder.query<Response<Export[], ExportMeta>, undefined>({
      query() {
        return {
          url: `/queue/export?q[status][in]=${ExportStatusCode.OPEN},${ExportStatusCode.IN_PROGRESS},${ExportStatusCode.SUCCESS},${ExportStatusCode.FAILED}&sort=-created_at`,
        };
      },
      transformResponse: (response: { data: Export[] }, meta) => ({
        ...transformToIsoDates(["created_at", "updated_at"])(response),
        metadata: {
          ...meta,
          countInProgress: response.data.filter((record) => record.status === ExportStatusCode.IN_PROGRESS).length,
          countOpen: response.data.filter((record) => record.status === ExportStatusCode.OPEN).length,
          countSuccess: response.data.filter((record) => record.status === ExportStatusCode.IN_PROGRESS).length,
          countFailed: response.data.filter((record) => record.status === ExportStatusCode.FAILED).length,
          countTotal: response.data.length,
        },
      }),

      providesTags: [tags.list],
    }),
    deleteExport: builder.mutation({
      query(id) {
        return {
          url: `/queue/export/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [tags.list],
    }),
  }),
});

export const { useGetExportsQuery, useDeleteExportMutation } = endpoints;
