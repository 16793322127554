import { useMemo } from "react";
import { useGetCurrentUserPermissionsQuery } from "../../endpoints/Users";
import { PermissionModule, PermissionKey } from "./permissions";

export const usePermission = <TModule extends PermissionModule, TKey extends PermissionKey<TModule>>(
  permissionModule: TModule,
  permissionKey: TKey,
) => {
  const { data } = useGetCurrentUserPermissionsQuery();

  return useMemo(
    () => ({
      granted:
        data?.data?.some(
          ({ module_key, right_key }) => module_key === permissionModule && right_key === permissionKey,
        ) ?? false,
    }),
    [permissionModule, permissionKey, data],
  );
};
