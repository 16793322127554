import classNames from "classnames";
import * as React from "react";
import { SelectMultiProps } from "../SelectMulti/SelectMulti";
import styles from "./GroupedControls.module.scss";

type GroupedVariant = "compact" | "default";

type GroupedControlsProps = {
  children: React.ReactNode;
  variant?: GroupedVariant;
  noGap?: boolean;
  testId?: string;
};

export const GroupedControls = ({ testId, children, variant = "default", noGap = false }: GroupedControlsProps) => {
  const variantStyle = variant === "default" ? styles.default : styles.compact;
  const modifiedChildren = React.Children.map(children, (child) => {
    if (
      React.isValidElement<SelectMultiProps<number | string>>(child) &&
      typeof child.type === "function" &&
      child.type.name === "SelectMulti"
    ) {
      return React.cloneElement(child, { ...child.props, variantPanelStyle: variantStyle });
    }

    return child;
  });

  return (
    <div data-testid={testId} className={classNames(styles.controlgroup, variantStyle, noGap && styles.noGap)}>
      {modifiedChildren}
    </div>
  );
};
