import { useEnvironmentSetting, useFeatureFlag, useLicenseGuard, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";

export const WeekReviewMenuItem = () => {
  const { t } = useTranslation("main_menu");
  const { enabled } = useLicenseGuard("hours_weekly_approval");
  const showTab = useFeatureFlag("hours-review-show-tab").enabled;
  const weekReviewEnabled = useEnvironmentSetting("hours", "hours_approval")?.value === 1;
  const hasGeneralWeekReviewPermission = usePermission("hours", "hours_approval").granted;
  const hasWeekReviewAsSupervisorPermission = usePermission("hours", "approve_as_supervisor").granted;
  const hasEitherPermission = hasGeneralWeekReviewPermission || hasWeekReviewAsSupervisorPermission;

  if (!showTab || !enabled || !weekReviewEnabled || !hasEitherPermission) {
    return null;
  }

  return <NavMenu.SubItem href="/hours/review" title={t("hours_week_review")} testId="hours-week-review-menu-item" />;
};
