import {
  fontSize1,
  fontSize2,
  fontSize3,
  fontSize4,
  fontSize5,
  fontSize6,
  fontSize7,
  fontSize8,
  lineHeight1,
  lineHeight2,
  lineHeight3,
  lineHeight4,
  lineHeight5,
  lineHeight6,
  size1,
  size10,
  size11,
  size12,
  size13,
  size14,
  size2,
  size3,
  size4,
  size5,
  size6,
  size7,
  size8,
  size9,
} from "@simplicate-software/design-tokens";
import { useMemo } from "react";
import classes from "./SkeletonLoader.module.scss";

type SkeletonLoaderProps = {
  lineHeight?:
    | typeof lineHeight1
    | typeof lineHeight2
    | typeof lineHeight3
    | typeof lineHeight4
    | typeof lineHeight5
    | typeof lineHeight6;
  fontSize?:
    | typeof fontSize1
    | typeof fontSize2
    | typeof fontSize3
    | typeof fontSize4
    | typeof fontSize5
    | typeof fontSize6
    | typeof fontSize7
    | typeof fontSize8;
  width?:
    | typeof size1
    | typeof size2
    | typeof size3
    | typeof size4
    | typeof size5
    | typeof size6
    | typeof size7
    | typeof size8
    | typeof size9
    | typeof size10
    | typeof size11
    | typeof size12
    | typeof size13
    | typeof size14
    | "default";
  testId?: string;
};

export const SkeletonLoader = ({
  lineHeight = lineHeight4,
  fontSize = fontSize3,
  width = "default",
  testId,
}: SkeletonLoaderProps) => {
  const styles = useMemo(
    () =>
      width === "default"
        ? {
            ["--line-height"]: lineHeight,
            ["--font-size"]: fontSize,
            minWidth: size6,
          }
        : {
            ["--line-height"]: lineHeight,
            ["--font-size"]: fontSize,
            minWidth: width,
            width,
          },
    [fontSize, lineHeight, width],
  );

  return <div className={classes.loader} style={styles} data-testid={testId} />;
};
