import {
  PostIFrameMessage,
  setPostIframeMessageHandler,
  useAppDispatch,
  iframeMessageReceived,
  IframeEvent,
  clearMessages,
} from "@simplicate/state";
import { RefObject, useEffect } from "react";

/* istanbul ignore next -- these events do not work properly in jest, this should be tested through other means */
export const useIFrameCommunication = (
  iFrameRef: RefObject<HTMLIFrameElement>,
  navigate: (location: string) => void,
) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const postMessageToIframe = (message: PostIFrameMessage) => {
      iFrameRef.current?.contentWindow?.postMessage(JSON.stringify(message));
    };

    dispatch(setPostIframeMessageHandler(postMessageToIframe));
  }, [dispatch, iFrameRef]);

  useEffect(() => {
    const onMessageReceived = (event: Event) => {
      // @ts-expect-error -- detail does not exist on default Event type. This is not the default Event type though.
      const message = event.detail as IframeEvent;
      const redirectEvent = message.params.find((m) => m.action === "IFRAME_REDIRECT");

      if (redirectEvent) {
        navigate(redirectEvent.payload.url ?? "/404");
      }

      dispatch(iframeMessageReceived(message));
    };

    window.addEventListener("simplicateIframeMessage", onMessageReceived);

    return () => {
      window.removeEventListener("simplicateIframeMessage", onMessageReceived);
    };
  }, [dispatch, iFrameRef, navigate]);

  useEffect(() => {
    // This cleanup effects clears all messages when the component is unmounted, i.e. the iframe is destroyed.
    return () => {
      dispatch(clearMessages());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- this is a cleanup effect it needs no dependencies
  }, []);
};
