import { useFeatureFlag } from "@simplicate/api-client";
import { posthog as posthogClient } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { PropsWithChildren, useEffect, useState } from "react";
import { getApplicationInfo, getPosthogSecrets } from "../../env";
import { useIsAdministrator } from "../MainMenu/Insights/useIsAdministrator";

function initPosthog(analyticsEnabled: boolean) {
  // TODO: [SIM-35332] identify user. Fetch tracking information from backend -> call identify on the posthog client.
  // NOTE: do not forget to call reset() when the user logs out.
  // https://posthog.com/docs/product-analytics/identify#best-practices-when-using-identify
  if (analyticsEnabled) {
    const { apiKey, apiHost } = getPosthogSecrets();
    const applicationInfo = getApplicationInfo();

    posthogClient.init(apiKey, { api_host: apiHost });

    if (applicationInfo.env === "production") {
      posthogClient.register({
        "Build Timestamp": applicationInfo.buildTimestamp,
        Commit: applicationInfo.commit,
        Version: applicationInfo.version,
      });
    } else {
      posthogClient.register({
        "Debug Mode": true,
      });
    }
  }
}

export const AnalyticsProvider = ({ children }: PropsWithChildren) => {
  const [isInitialized, setIsInitialized] = useState(false);

  // TODO: replace with user identification. See [SIM-35332]
  const { isAdministrator, isLoading } = useIsAdministrator();
  const analyticsEnabled = useFeatureFlag("system-product-analytics-browser-posthog").enabled;

  useEffect(() => {
    if (!isInitialized && !isLoading && !isAdministrator) {
      initPosthog(analyticsEnabled);
      setIsInitialized(true);
    }
  }, [analyticsEnabled, isAdministrator, isInitialized, isLoading]);

  if (analyticsEnabled) {
    return <PostHogProvider client={posthogClient}>{children}</PostHogProvider>;
  }

  return children;
};
