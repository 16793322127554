import { colorCommonGrey0, colorCommonGrey800 } from "@simplicate-software/design-tokens";
import ContrastColor from "contrast-color";

/**
 * Function to determine the text color based on the given background color
 * @param color - background color
 * @returns contrast color
 *
 * @example
 * ```ts
 *
 * getContrastColor("#000000") // "colorCommonGrey0"
 * getContrastColor("#ffffff") // "colorCommonGrey800"
 * getContrastColor("undefined") // "#000000"
 * ```
 */

export function getContrastColor(backgroundColor?: string) {
  // eslint-disable-next-line import/no-named-as-default-member -- We need the default export or it won't work
  return ContrastColor.contrastColor({
    bgColor: backgroundColor,
    fgLightColor: colorCommonGrey0,
    fgDarkColor: colorCommonGrey800,
  });
}
