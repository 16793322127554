import classnames from "classnames";
import { PropsWithChildren, useEffect, useRef } from "react";
import { Triangle } from "../Triangle";
import styles from "./Dropdown.module.scss";

type DropdownProps = PropsWithChildren<{
  height?: "auto" | "limitedWithScroll";
  hasPadding?: boolean;
  testId?: string;
  title?: string;
  variant?: "clean" | "withIndicator";
  width?: "auto" | "default" | "small";
  isOpen?: boolean;
  onClickOutside?: (event: MouseEvent) => void;
  onKeyupOutside?: (event: KeyboardEvent) => void;
  onClose?: (event: MouseEvent) => void;
}>;

const widthClassMapping = {
  auto: null,
  small: styles.widthSmall,
  default: styles.widthDefault,
};

const heightClassMapping = { auto: null, limitedWithScroll: styles.heightDefault };

export const Dropdown = ({
  children,
  height = "auto",
  hasPadding = false,
  testId,
  title,
  variant = "withIndicator",
  width = "default",
  isOpen = false,
  onClickOutside,
  onKeyupOutside,
}: DropdownProps) => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const isOutsideDropdown = (target: Node) =>
      divRef.current && target.isConnected && !divRef.current.contains(target);

    const handleClickOutside = (event: MouseEvent) => {
      if (isOutsideDropdown(event.target as Node)) {
        onClickOutside?.(event);
      }
    };

    const handleKeyup = (event: KeyboardEvent) => {
      if ((event.key === "Tab" && isOutsideDropdown(event.target as Node)) || event.key === "Escape") {
        onKeyupOutside?.(event);
      }
    };

    /**
     * setTimeout is used to prevent the click event from being triggered immediately
     * after the dropdown is opened. This is because the click event that opens the dropdown
     * is also registered as a click outside the dropdown.
     */
    if (isOpen) {
      document.addEventListener("pointerup", handleClickOutside);
      document.addEventListener("keyup", handleKeyup);

      return () => {
        document.removeEventListener("pointerup", handleClickOutside);
        document.removeEventListener("keyup", handleKeyup);
      };
    }
  }, [isOpen, onClickOutside, onKeyupOutside]);

  return (
    isOpen && (
      <div className={classnames(styles.dropdown, widthClassMapping[width])} data-testid={testId} ref={divRef}>
        {variant === "withIndicator" && (
          <div className={styles.dropdownTriangle}>
            <Triangle />
          </div>
        )}
        <div
          className={classnames(styles.content, hasPadding && styles.withPadding, heightClassMapping[height])}
          data-testid={`${testId}-content`}
        >
          <div className={styles.scrollContainer}>
            {title && <h5 className={styles.title}>{title}</h5>}
            {children}
          </div>
        </div>
      </div>
    )
  );
};
