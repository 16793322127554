import { BinaryFilter } from "@cubejs-client/core";
import { UseCubeQueryResult } from "@cubejs-client/react";
import { createContext, useContext, useMemo } from "react";
import { CubeDimension } from "../../types";
import { DashboardState, FilterOption, LabeledFilter, ReducerAction } from "./Dashboard.reducer";

type DashboardContextType = {
  state: DashboardState;
  dispatch: (action: ReducerAction) => void;
  queryResult: UseCubeQueryResult<unknown, unknown>;
};

export const DashboardContext = createContext<DashboardContextType | null>(null);

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);

  if (context === null) {
    throw new Error("Can only use `useDashboardContext` inside `DashboardContextProvider`");
  }

  const { dispatch, state, queryResult } = context;

  const actions = useMemo(
    () => ({
      addFilter: (label: string, filter: BinaryFilter) => dispatch({ type: "ADD_FILTER", filter, label }),
      resetState: () => dispatch({ type: "RESET" }),
      orderBy: (key: string) => dispatch({ type: "ORDER_BY", key }),
      removeFilter: (filter: LabeledFilter) => dispatch({ type: "REMOVE_FILTER", filter }),
      setEndDate: (date?: Date) => (date ? dispatch({ type: "SET_END_DATE", date }) : undefined),
      setSearch: (search: string) => dispatch({ type: "SET_SEARCH", search }),
      setStartDate: (date?: Date) => (date ? dispatch({ type: "SET_START_DATE", date }) : undefined),
      applyFilterForDimensions: (
        dimension: CubeDimension,
        values: FilterOption[],
        format?: (value: unknown) => string,
      ) => dispatch({ type: "APPLY_FILTER_FOR_DIMENSION", dimension, format, values }),
    }),
    [dispatch],
  );

  return { state, actions, queryResult } as const;
};
