import { useTranslation } from "@simplicate/translations";
import { Icon, Tooltip } from "@simplicate/ui";
import styles from "./UpSellTooltip.module.scss";

type UpSellTooltipProps = {
  title: string;
};

export const UpSellTooltip = ({ title }: UpSellTooltipProps) => {
  const { t } = useTranslation("main_menu");

  return (
    <Tooltip message={t("not_available_in_get_started_message")} position="right">
      <div className={styles.contentContainer}>
        <span>{title}</span>
        <Icon icon="infoCircle" />
      </div>
    </Tooltip>
  );
};
