import cube, { CubeApi } from "@cubejs-client/core";
import { useGetCubeConfigQuery } from "../../data";

export const useCubeDev = (): CubeApi | null => {
  const { isLoading, isError, data } = useGetCubeConfigQuery();

  if (isLoading || isError || !data) {
    return null;
  }

  const cubeApi = cube(data.token, {
    apiUrl: data.endpoint,
  });

  return cubeApi;
};
