import { useTranslation } from "@simplicate/translations";
import { Icon } from "@simplicate/ui";
import { ComponentProps } from "react";
import { TopBarButton } from "../TopBarButton";
import { HelpDropdown } from "./HelpDropdown";

type HelpProps = {
  module: ComponentProps<typeof HelpDropdown>["module"];
};

export const Help = ({ module }: HelpProps) => {
  const { t } = useTranslation("top_bar");

  return (
    <TopBarButton
      aria-label={t("label_help_button")}
      testId="top-bar-help-button"
      dropdownComponent={<HelpDropdown module={module} />}
      dropdownHeight="auto"
      title={t("help")}
    >
      <Icon icon="questionCircle" />
    </TopBarButton>
  );
};
