import { Navigate } from "react-router-dom";
import { IFrameEnvironment, InvoicesRedirectComponent } from "../../components";
import { InvoicingTabs, ToBeInvoicedTabs, InvoiceDetailsPageTabs } from "../../components/IFrameEnvironment/Tabs";
import { InvoiceSubHeader, ToBeInvoicedSubHeader } from "../../components/NavigationWrapper/SubHeaders";
import { RouteObject } from "../types";
import { InvoicesBackButtonBehavior } from "../utils";

export const invoicingRoutes: RouteObject<"invoices" | "projects">[] = [
  {
    path: "invoicing",
    handle: {
      headerTitle: "module_invoicing",
      theme: "invoicing",
      permissions: [
        {
          module_key: "invoices",
          right_key: "view",
        },
      ],
    },
    children: [
      {
        index: true,
        element: <Navigate to="ready-for-invoicing" replace />,
      },
      {
        path: "ready-for-invoicing",
        handle: {
          tabsComponent: InvoicingTabs,
          permissions: [
            {
              module_key: "projects",
              right_key: "view",
            },
          ],
        },
        element: <IFrameEnvironment src="/v1/invoices" />,
      },
      {
        path: "approval",
        element: <IFrameEnvironment src="/v1/invoices?tab=approval" />,
        handle: { tabsComponent: InvoicingTabs },
      },
      {
        path: "draft-invoices",
        element: <IFrameEnvironment src="/v1/invoices?tab=concept" />,
        handle: { tabsComponent: InvoicingTabs },
      },
      {
        path: "outstanding",
        element: <IFrameEnvironment src="/v1/invoices?tab=unpaid" />,
        handle: { tabsComponent: InvoicingTabs },
      },
      {
        path: "all",
        element: <IFrameEnvironment src="/v1/invoices?tab=all" />,
        handle: { tabsComponent: InvoicingTabs },
      },
      {
        path: "accounting",
        element: <IFrameEnvironment src="/v1/invoices?tab=journlize" />,
        handle: {
          tabsComponent: InvoicingTabs,
          permissions: [
            {
              module_key: "invoices",
              right_key: "accountancy_apps",
            },
          ],
        },
      },
      {
        path: "reporting",
        handle: {
          permissions: [
            {
              module_key: "invoices",
              right_key: "rapportage",
            },
          ],
        },
        children: [
          {
            index: true,
            element: <Navigate to="invoicing" replace />,
          },
          {
            path: "invoicing",
            element: <IFrameEnvironment src="/v1/reporting/invoicing" remountOnSearchChange={false} />,
            handle: {
              headerTitle: "reporting_invoicing",
            },
          },
          {
            path: "mrr",
            element: <IFrameEnvironment src="/v1/reporting/mrr" remountOnSearchChange={false} />,
            handle: {
              headerTitle: "reporting_mrr",
            },
          },
        ],
      },
      {
        path: ":id",
        children: [
          {
            index: true,
            element: <InvoicesRedirectComponent />,
          },
          {
            path: "ready",
            element: <IFrameEnvironment src="/v1/invoices/proposition?id=:id&tab=proposition_detail" />,
            handle: {
              tabsComponent: ToBeInvoicedTabs,
              backNavigationPath: "/invoicing/ready-for-invoicing",
              subHeaderComponent: ToBeInvoicedSubHeader,
            },
          },
          {
            path: "not-ready",
            element: <IFrameEnvironment src="/v1/invoices/proposition?id=:id&tab=proposition_notready" />,
            handle: {
              tabsComponent: ToBeInvoicedTabs,
              backNavigationPath: "/invoicing/ready-for-invoicing",
              subHeaderComponent: ToBeInvoicedSubHeader,
            },
          },
          {
            path: "documents",
            element: <IFrameEnvironment src="/v1/invoices/invoice/view?id=:id&tab=dossier" />,
            handle: {
              tabsComponent: InvoiceDetailsPageTabs,
              backNavigationPath: InvoicesBackButtonBehavior,
              subHeaderComponent: InvoiceSubHeader,
            },
          },
          {
            path: "reverse-invoice",
            element: <IFrameEnvironment src="/v1/invoices/invoice/view?id=:id&tab=reverseinvoice" />,
            handle: {
              tabsComponent: InvoiceDetailsPageTabs,
              backNavigationPath: InvoicesBackButtonBehavior,
              subHeaderComponent: InvoiceSubHeader,
            },
          },
          {
            path: "services",
            element: <IFrameEnvironment src="/v1/invoices/invoice/view?id=:id&tab=services" />,
            handle: {
              tabsComponent: InvoiceDetailsPageTabs,
              backNavigationPath: InvoicesBackButtonBehavior,
              subHeaderComponent: InvoiceSubHeader,
            },
          },
          {
            path: "timeline",
            element: <IFrameEnvironment src="/v1/invoices/invoice/view?id=:id&tab=timeline" />,
            handle: {
              tabsComponent: InvoiceDetailsPageTabs,
              backNavigationPath: InvoicesBackButtonBehavior,
              subHeaderComponent: InvoiceSubHeader,
            },
          },
          {
            path: "invoice",
            element: <IFrameEnvironment src="/v1/invoices/invoice/view?id=:id&tab=invoice" />,
            handle: {
              tabsComponent: InvoiceDetailsPageTabs,
              backNavigationPath: InvoicesBackButtonBehavior,
              subHeaderComponent: InvoiceSubHeader,
            },
          },
          {
            path: "draft-invoice",
            element: <IFrameEnvironment src="/v1/invoices/invoice/view?id=:id&tab=concept_invoice" />,
            handle: {
              tabsComponent: InvoiceDetailsPageTabs,
              backNavigationPath: InvoicesBackButtonBehavior,
              subHeaderComponent: InvoiceSubHeader,
            },
          },
        ],
      },
    ],
  },
];
