import { useGetExportsQuery } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { EmptyState, List } from "@simplicate/ui";
import { useCallback } from "react";
import { ExportItem } from "./ExportItem";

// TODO: figure out if this is the correct interval -- maybe we need progressively increasing intervals?
const POLLING_INTERVAL = 30 * 1_000;

export const ExportDropdown = () => {
  const { t } = useTranslation("top_bar");
  const { data: exports, refetch } = useGetExportsQuery(undefined, {
    pollingInterval: POLLING_INTERVAL,
    refetchOnMountOrArgChange: true,
  });

  const handleDownload = useCallback(() => void refetch(), [refetch]);

  return exports?.data && exports.data.length > 0 ? (
    <List testId="exports-list">
      {exports.data.map((exportItem) => (
        <ExportItem key={exportItem.id} {...exportItem} onDownload={handleDownload} />
      ))}
    </List>
  ) : (
    <EmptyState message={t("no_exports_available")} />
  );
};
