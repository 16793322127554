import { useTranslation } from "@simplicate/translations";
import { Icon, Button } from "@simplicate/ui";
import { memo } from "react";
import { TopBarButton } from "../TopBarButton";
import styles from "./Announcements.module.scss";

const REFERRAL_URL = "https://www.simplicate.com/nl/referral-actie/";

export const Announcements = memo(function AnnouncementsComponent() {
  const { t } = useTranslation("top_bar");

  return (
    <TopBarButton
      aria-label={t("label_announcements_button")}
      testId="top-bar-announcements-button"
      dropdownComponent={
        <div className={styles.dropdown} data-testid="announcements-dropdown">
          <div className={styles.text}>
            <p>{t("referral_action_text")}</p>
          </div>
          <Button
            to={REFERRAL_URL}
            target="_blank"
            text={t("referral_action_button")}
            variant="primary"
            size="medium"
            testId="referral-link"
          />
        </div>
      }
    >
      <Icon icon="bullhorn" />
    </TopBarButton>
  );
});
