import { useEnvironmentSetting, useLicenseGuard } from "@simplicate/api-client";

export const useProjectReverseBillingTabPermissions = () => {
  const isSecondmentBranch = useEnvironmentSetting("environment", "branch_secondment")?.value === 1;
  const hasReverseBillingEnabled = useEnvironmentSetting("invoices", "is_reverse_billing")?.value === 1;

  const { enabled: hasReverseBillingLicense } = useLicenseGuard("invoicing_reverse_billing");

  return {
    showReverseBillingTab: isSecondmentBranch && hasReverseBillingEnabled,
    disableReverseBillingTab: !hasReverseBillingLicense,
  };
};
