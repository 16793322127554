import { useGetInvoiceQuery, skipToken, DRAFT_INVOICE_STATUS_ID } from "@simplicate/api-client";
import { BackButtonBehavior } from "@simplicate/top-bar";
import { useParams } from "react-router-dom";

/**
 * BackButtonBehavior for the invoices detail pages: Queries the invoice data based on the ID in the current URL, and
 * when the invoice is in draft, provides the draft invoices list page url. When the invoice is not in draft, the
 * outstanding invoices list page url is provided instead.
 */
export const InvoicesBackButtonBehavior: BackButtonBehavior = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useGetInvoiceQuery(id ?? skipToken, { refetchOnMountOrArgChange: true });

  if (!data) {
    return {
      target: undefined,
    };
  }

  const invoiceIsDraft = data.data.status.id === DRAFT_INVOICE_STATUS_ID;
  const invoiceIsOutstanding = data.data.total_outstanding > 0;

  const targetPath = invoiceIsDraft
    ? "/invoicing/draft-invoices"
    : invoiceIsOutstanding
      ? "/invoicing/outstanding"
      : "/invoicing/all";

  return {
    target: new URL(targetPath, window.location.origin),
  };
};
