import { HEADER_DATA_TAG, simplicateApiV2 } from "../components";
import { createTagTypes } from "../tags";
import { Response } from "./types";

export type Person = {
  id: string;
  first_name: string;
  family_name: string;
  full_name: string;
  family_name_prefix?: string;
  is_active?: boolean;
  relation_number?: string;
  relation_type?: {
    id: string;
    label: string;
    color: string;
  };
};

const tags = createTagTypes({ tagPrefix: "Person", tags: ["entity"] });

const endpoints = simplicateApiV2.enhanceEndpoints({ addTagTypes: Object.values(tags) }).injectEndpoints({
  endpoints: (builder) => ({
    getPerson: builder.query<Response<Person>, string>({
      query: (id) => ({
        url: `/crm/person/${id}`,
      }),
      providesTags: [tags.entity, HEADER_DATA_TAG],
    }),
  }),
});

export const { useGetPersonQuery } = endpoints;
