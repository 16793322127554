/* istanbul ignore file -- this is simple preset component */
import { useParams } from "react-router-dom";
import { DocumentEditor } from "./DocumentEditor";
import { DocumentEditorRouteParams } from "./types";

export const QuoteEditor = () => {
  const { id: saleId, qid: quoteId } = useParams<DocumentEditorRouteParams>();

  return <DocumentEditor mode="quote" saleId={saleId} quoteId={quoteId} />;
};
