export function writeToSessionStorage<Data>(key: string): (response: Response) => Promise<Data> {
  return (response) => {
    void response
      .clone()
      .json()
      .then((responseJson) => sessionStorage.setItem(key, JSON.stringify(responseJson)));

    return response.json();
  };
}
