/**
 * Extracts the `tab` search query parameter from a URL and converts it according to the provided conversion record.
 * @param location the URL to extract the `tab` parameter from
 * @param conversions a record of known `tab` values and their corresponding conversions
 * @returns the converted `tab` value or the original value if no conversion is found or an empty string when the tab query paramater is not present
 */
export function extractSelectedTab(location: URL, conversions: Record<string, string>) {
  const queryParamTab = location.searchParams.get("tab");

  return queryParamTab ? conversions[queryParamTab] ?? queryParamTab : "";
}
