type Concat<TPrefix extends string, TSuffix extends string> = `${TPrefix}_${TSuffix}`;
type TagTypes<TTagPrefix extends string, TTags extends string[]> = {
  [Key in TTags[number]]: Concat<TTagPrefix, Key>;
};

type CreateTagTypesArgs<TTagPrefix extends string, TTags extends string> = {
  tagPrefix: TTagPrefix;
  tags: TTags[];
};

/**
 * Create a type for tags that can be used in the `addTagTypes`, `providesTags` and `invalidatesTags` options of `enhanceEndpoints` and
 * `injectEndpoints` respectively. The returned record will contain a key for each tag, with the value being a string that is the
 * concatenation of the category and the tag, to ensure these tags do not collide with other tags created by other endpoint collections.
 * @param tagPrefix The category of the tags. You will probably want to use the name of the shape the endpoint collection is returning.
 * @param tags The individual tags to create types for.
 * @example
 * ```ts
 * const tags = createTagTypes({ tagPrefix: "some_entity", tags: ["list"] });
 *
 * apiClient
 *   .enhanceEndpoints({ addTagTypes: Object.values(tags) })
 *   .injectEndpoints({
 *     endpoints: (builder) => ({
 *       getSomeEntity: builder.query({
 *       (...),
 *       providesTags: [tags.list],
 *     }),
 *     updateSomeEntity: builder.mutation({
 *       (...),
 *       invalidatesTags: [tags.list],
 *     }),
 *   });
 * ```
 */
export function createTagTypes<TTagPrefix extends string, TTags extends string>({
  tagPrefix,
  tags,
}: CreateTagTypesArgs<TTagPrefix, TTags>): TagTypes<TTagPrefix, TTags[]> {
  const entries = tags.map((tag) => [tag, `${tagPrefix}_${tag}`] as const);

  return Object.fromEntries(entries) as TagTypes<TTagPrefix, TTags[]>;
}
