import { useEnvironmentSetting, useFeatureFlag, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";

/**
 * @deprecated The page this menu item points to has long been replaced. This menu item only exists for backwards compatibility.
 */
export const CostsMenuItem = () => {
  const { t } = useTranslation("main_menu");

  // This feature flag should hide the tab when enabled.
  const showMenuItem = useFeatureFlag("hours-hide-tab-register-costs-old").enabled === false;

  const hasApproveAsSupervisorPermission = usePermission("hours", "approve_as_supervisor").granted;
  const hasGeneralApprovalPermission = usePermission("hours", "hours_approval").granted;
  const hasEitherRequiredPermission = hasApproveAsSupervisorPermission || hasGeneralApprovalPermission;

  const projectPurchaseEnabled = useEnvironmentSetting("projects", "use_project_purchase")?.value === 1;

  // TODO: Remove this setting guard. See https://simplicate.atlassian.net/browse/SIM-35562
  const projectsModuleEnabled = useEnvironmentSetting("environment", "active_modules")?.value.includes("projects");
  const projectPurchaseByEmployee = useEnvironmentSetting("projects", "is_use_project_purchase_employee")?.value === 1;

  if (
    !showMenuItem ||
    !hasEitherRequiredPermission ||
    !projectPurchaseEnabled ||
    !projectsModuleEnabled ||
    !projectPurchaseByEmployee
  ) {
    return null;
  }

  return <NavMenu.SubItem href="/hours/declarations" title={t("legacy_hours_costs")} testId="hours-costs-menu-item" />;
};
