import { ForwardedRef, forwardRef } from "react";
import { Icon } from "../Icon";
import styles from "./EmptyState.module.scss";

type EmptyStateProps = {
  message: string;
};

export const EmptyState = forwardRef(function EmptyState(
  { message }: EmptyStateProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <div ref={ref} className={styles.emptystate}>
      <div className={styles.iconContainer}>
        <Icon icon="folderTimes" className={styles.icon} />
      </div>
      <span>{message}</span>
    </div>
  );
});
