import { CreateProjectServiceBody, InvoiceMethod, transformMoneyToMoneyFromAPI, Big } from "@simplicate/api-client";
import { format } from "date-fns";
import { transformToCreateServiceCostType } from "./transformCostTypes";
import { transformToCreateServiceHourType } from "./transformHourTypes";
import type { ValidProjectServiceForm } from "./types";

const transformHourTypeValuesToHourTypeConfiguration = ({
  canRegisterHours,
  hourTypes,
  hourTypesSpecifiedTotal,
  invoiceMethod,
}: Pick<ValidProjectServiceForm, "canRegisterHours" | "hourTypes" | "hourTypesSpecifiedTotal" | "invoiceMethod">) => {
  if (!canRegisterHours) {
    return null;
  }

  return {
    hourTypes: hourTypes?.map((hourType) => transformToCreateServiceHourType(hourType, invoiceMethod)) ?? [],
    employeeTariffs: [],
    specifiedTotal: transformMoneyToMoneyFromAPI(hourTypesSpecifiedTotal ?? { currency: "EUR", amount: Big(0) }),
  };
};

// eslint-disable-next-line complexity -- This function transforms a lot of data. It's easier to read when it's not split up.
export const transformFormToCreateProjectServiceBody = (
  values: ValidProjectServiceForm,
  projectId: string,
  hasResourcePlanner: boolean,
): CreateProjectServiceBody => {
  let body: CreateProjectServiceBody = {
    projectIdentifier: projectId,
    defaultServiceIdentifier: values.defaultService,
    invoiceMethod: values.invoiceMethod,
    timeFrame: {
      startDate: values.timeframe.startDate ? format(values.timeframe.startDate, "yyyy-MM-dd") : null,
      endDate: values.timeframe.endDate ? format(values.timeframe.endDate, "yyyy-MM-dd") : null,
    },
    description: values.description,
    explanation: values.explanation,
    revenueGroupIdentifier: values.revenueGroup,
    vatCodeIdentifier: values.vatCode,
    canRegisterHours: values.canRegisterHours,
    canRegisterCosts: values.canRegisterCosts,
    hourTypeConfiguration: transformHourTypeValuesToHourTypeConfiguration({
      canRegisterHours: values.canRegisterHours,
      hourTypes: values.hourTypes,
      hourTypesSpecifiedTotal: values.hourTypesSpecifiedTotal,
      invoiceMethod: values.invoiceMethod,
    }),
    isPlannable: hasResourcePlanner ? values.isPlannable ?? false : undefined,
    costTypes:
      values.canRegisterCosts && values.costTypes ? values.costTypes.map(transformToCreateServiceCostType) : [],
  };

  if (values.invoiceMethod === InvoiceMethod.fixed_price) {
    body = {
      ...body,
      invoiceInInstallments: values.invoiceInInstallments ?? false,
      invoiceableFrom: values.invoiceableFrom ? format(values.invoiceableFrom, "yyyy-MM-dd") : null,
      invoicePrice: transformMoneyToMoneyFromAPI(values.invoicePrice),
    };
  }

  if (values.invoiceMethod !== InvoiceMethod.time_and_expenses) {
    body.costTypes = body.costTypes.map((costType) => ({
      ...costType,
      isInvoicable: undefined,
    }));
  }

  return body;
};
