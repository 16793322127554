import classNames from "classnames";
import { PropsWithChildren, ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { Triangle } from "../../Triangle";
import { determineHref } from "../determineHref";
import { SubMenu } from "../SubMenu";
import styles from "./SubItem.module.scss";

type CommonProps = {
  title: ReactNode | string;
  testId?: string;
  disabled?: boolean;
};

type SimpleSubItem = {
  children?: never;
  href: string;
};

type SubItemWithSubItems = PropsWithChildren<{
  href?: never;
}>;

export type SubItem = CommonProps & (SimpleSubItem | SubItemWithSubItems);

export const SubItem = ({ children, href, title, disabled = false, testId }: SubItem) => {
  const targetHref = determineHref(children, href);
  const titlePropIsString = typeof title === "string";
  const classes = classNames(
    styles.subItem,
    children && styles.hoverContainer,
    disabled && styles.disabled,
    !titlePropIsString && styles.noPadding,
  );

  return (
    <li className={classes}>
      {!targetHref || disabled ? (
        <div className={styles.link} data-testid={testId}>
          {title}
        </div>
      ) : (
        <NavLink
          to={targetHref}
          className={styles.link}
          data-testid={testId}
          onMouseDown={(event) => event.preventDefault()}
        >
          {title}
        </NavLink>
      )}
      {children && (
        <>
          <div className={styles.triangleContainer}>
            <Triangle direction="right" />
          </div>
          {/* eslint-disable-next-line css-modules/no-undef-class -- subMenu is defined by used mixin */}
          <SubMenu className={styles.subMenu} testId={`${testId}-sub-menu`}>
            {children}
          </SubMenu>
        </>
      )}
    </li>
  );
};
