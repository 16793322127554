import { simplicateApiV1 } from "../components";
import { transformToIsoDates } from "./utils";

type Reference = {
  label: number | string;
  module: string | null;
  url?: string | null;
};

export type Notification = {
  id: string;
  created_at: string;
  updated_at: string;
  message: string;
  references: Record<string, Reference | undefined>;
  is_read: boolean;
  module: string | null;
  target_user_id: number;
  source: string;
  primary_url: string | null;
};

export type NotificationCount = {
  unread: number;
};

const endpoints = simplicateApiV1.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<{ data: Notification[] }, void>({
      query: () => ({
        url: "/v1/internal/notificationcenter/messages",
      }),
      transformResponse: transformToIsoDates(["created_at", "updated_at"]),
    }),
    getUnreadNotificationCount: builder.query<{ data: NotificationCount }, void>({
      query: () => ({
        url: "/v1/internal/notificationcenter/unread",
      }),
      transformResponse: (response: { data: { unread: string } }) => ({
        ...response,
        data: { unread: parseInt(response.data.unread) },
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetNotificationsQuery, useGetUnreadNotificationCountQuery } = endpoints;
export const getUnreadNotificationCountFulfilled = endpoints.endpoints.getUnreadNotificationCount.matchFulfilled;
