import { setHeader, useAppDispatch } from "@simplicate/state";
import { MessageBus } from "@simplicate/utils";
import { useEffect } from "react";

export const useBackdropEventListener = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const unsubscribe = MessageBus.subscribe("backdrop", ({ isVisible }) => {
      dispatch(setHeader({ showOverlay: isVisible }));
    });

    return () => {
      unsubscribe();
      dispatch(setHeader({ showOverlay: false }));
    };
  }, [dispatch]);
};
