import { useEnvironmentSetting, useGetCurrentUserQuery, useLicenseGuard, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { TabBar, Tab } from "@simplicate/ui";
import { useParams } from "react-router-dom";
import { IFrameActionButtons } from "../../IFrameEnvironment/IFrameActionButtons";
import { MessageBusActionButtons } from "../../IFrameEnvironment/MessageBusActionButtons";

const useBusinessToConsumerTabsPermissions = () => {
  const { data: userData } = useGetCurrentUserQuery();

  const isLightUser = userData?.data?.is_light_user ?? false;

  const hasViewSalesPermission = usePermission("acquisition", "view").granted;
  const hasViewProjectsPermission = usePermission("projects", "view").granted;

  const hasViewInvoicesPermission = usePermission("invoices", "view").granted;
  const hasInvoiceModule = useEnvironmentSetting("environment", "active_modules")?.value.includes("invoices");

  const hasBusinessToConsumerActivated = useEnvironmentSetting("general", "activation_b2c")?.value === 1;

  return {
    showSalesTab: hasViewSalesPermission && hasBusinessToConsumerActivated,
    showProjectsTab: hasViewProjectsPermission && hasBusinessToConsumerActivated,
    showInvoicesTab: hasViewInvoicesPermission && hasInvoiceModule && !isLightUser && hasBusinessToConsumerActivated,
  };
};

export const PersonTabs = () => {
  const { t } = useTranslation("tab_bar");

  const { id } = useParams<{ id: string }>();
  const { data: userData } = useGetCurrentUserQuery();

  const isLightUser = userData?.data?.is_light_user ?? false;

  const { enabled: hasWorkflowsEnabled } = useLicenseGuard("workflows");

  const { showSalesTab, showProjectsTab, showInvoicesTab } = useBusinessToConsumerTabsPermissions();

  if (!id) {
    return null;
  }

  return (
    <TabBar
      testId="person"
      actions={
        <>
          <IFrameActionButtons />
          <MessageBusActionButtons />
        </>
      }
    >
      <Tab href={`/crm/persons/${id}/overview`} title={t("overview")} />
      <Tab href={`/crm/persons/${id}/general`} title={t("person-general")} />
      <Tab href={`/crm/persons/${id}/contact-at`} title={t("contact-at")} />
      {showSalesTab && <Tab href={`/crm/persons/${id}/sales`} title={t("sales")} />}
      {showProjectsTab && <Tab href={`/crm/persons/${id}/projects`} title={t("projects")} />}
      {showInvoicesTab && <Tab href={`/crm/persons/${id}/invoices`} title={t("invoices")} />}
      {!isLightUser && (
        <Tab
          disabled={!hasWorkflowsEnabled}
          disabledText={t("not_available_in_get_started_message")}
          href={`/crm/persons/${id}/tasks`}
          title={t("tasks")}
        />
      )}
      {!isLightUser && <Tab href={`/crm/persons/${id}/documents`} title={t("documents")} />}
    </TabBar>
  );
};
