import { lightColorIconInvert } from "@simplicate-software/design-tokens";
import classNames from "classnames";
import { ReactNode } from "react";
import { Icon } from "../Icon";
import { ExpandButton } from "./ExpandButton";
import { MenuContextProvider } from "./NavMenu.context";
import styles from "./NavMenu.module.scss";

export type NavMenuProps = {
  children: ReactNode;
  expanded?: boolean;
  onToggleExpanded: () => void;
  testId?: string;
  skipToContentMessage: string;
  expandMenuMessage: string;
  collapseMenuMessage: string;
};

export const NavMenu = ({
  skipToContentMessage,
  collapseMenuMessage,
  expandMenuMessage,
  children,
  expanded = false,
  onToggleExpanded,
  testId,
}: NavMenuProps) => {
  return (
    <MenuContextProvider
      value={{
        expanded,
        toggleExpand: onToggleExpanded,
        expandMenuLabel: expandMenuMessage,
        collapseMenuLabel: collapseMenuMessage,
      }}
    >
      <nav className={styles.navMenu} aria-label="Main menu" data-testid={testId}>
        <a href="#main" className={classNames(styles.hiddenButton, styles.top)} title={skipToContentMessage}>
          <Icon icon="arrowDown" color={lightColorIconInvert} />
          {skipToContentMessage}
        </a>
        <div className={styles.primaryContainer}>
          {children}
          <ExpandButton testId="toggle-expand-menu-button" />
        </div>
      </nav>
    </MenuContextProvider>
  );
};
