import { useTranslation } from "@simplicate/translations";
import { Button } from "@simplicate/ui";

type FooterProps = {
  deleteServices: () => void;
  cancel: () => void;
};
export const Footer = ({ deleteServices, cancel }: FooterProps) => {
  const { t } = useTranslation("grouped_services_manager");

  return (
    <div>
      <Button variant="danger" text={t("delete")} onClick={deleteServices} />
      <Button variant="subtle" text={t("cancel")} onClick={cancel} />
    </div>
  );
};
