import { Big } from "@simplicate/api-client";
import { CurrencyFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { memo } from "react";
import styles from "./InvoicePriceInput.module.scss";
import type { MoneyInForm } from "../types";

type InvoicePriceInputProps = {
  value: MoneyInForm | undefined;
  onChange: (value: Big | undefined) => void;
};

export const InvoicePriceInput = memo(function InvoicePriceInput({ value, onChange }: InvoicePriceInputProps) {
  const { t } = useTranslation("project_services");

  return (
    <div className={styles.invoicePriceContainer}>
      <label htmlFor="invoice_price_input" className={styles.invoicePriceLabel}>
        {t("invoice_price_label")}
      </label>
      <CurrencyFormat
        id="invoice_price_input"
        testId="invoice-price"
        displayType="input"
        size="small"
        textAlign="right"
        value={value?.amount?.toString()}
        onValueChange={
          /* istanbul ignore next -- Component is mocked as <span/> making input test cases impossible */
          ({ value }, { source }) => {
            if (source.valueOf() === "prop") {
              return;
            }

            if (value.length === 0) {
              return onChange(undefined);
            }

            return onChange(Big(value));
          }
        }
        onBlur={() => {
          if (value?.amount === undefined) {
            onChange(Big(0));
          }
        }}
      />
    </div>
  );
});
