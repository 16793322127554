import { Icon } from "@simplicate/ui";
import styles from "./EnvironmentNotification.module.scss";

type EnvironmentNotificationProps = {
  title?: string;
  message: string;
  button?: JSX.Element;
};

export const EnvironmentNotification = ({ title, message, button }: EnvironmentNotificationProps) => {
  return (
    <div className={styles.environmentNotification}>
      <div className={styles.warningIcon}>
        <Icon icon="exclamationTriangle" />
      </div>
      <div className={styles.environmentNotificationContent}>
        {title && <h4 className={styles.title}>{title}</h4>}
        <p className={styles.message}>{message}</p>
        {button}
      </div>
    </div>
  );
};
