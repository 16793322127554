import { DeeplyReadonly, Query, TimeDimension } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import { format } from "date-fns";
import { useMemo } from "react";
import { DashboardState } from "../../../components/Dashboard/Dashboard.reducer";

function formatDate(date: Date) {
  return format(date, "yyyy-MM-dd");
}

type CubeQuery = DeeplyReadonly<Query> & {
  state?: DashboardState;
};

export const useDataset = ({ state, ...query }: CubeQuery) => {
  const { filters, order, timeDimensions } = useMemo(() => {
    const filters =
      state?.filters && state.filters.length > 0 ? [{ or: state.filters.map(({ filter }) => filter) }] : undefined;

    const order = state?.order ? { [state.order.key]: state.order.direction } : undefined;

    const dateRange = state?.dateRange
      ? ([formatDate(state.dateRange.start), formatDate(state.dateRange.end)] as const)
      : "this week";
    const timeDimensions: DeeplyReadonly<TimeDimension[] | undefined> = query.timeDimensions
      ? query.timeDimensions.map((timeDimension) => ({ ...timeDimension, dateRange }))
      : undefined;

    return { filters, order, timeDimensions };
  }, [query.timeDimensions, state?.dateRange, state?.filters, state?.order]);

  return useCubeQuery({
    ...query,
    filters,
    order,
    timeDimensions,
  });
};
