import { simplicateApiV2 } from "../../components";
import { createTagTypes } from "../../tags";
import { Response } from "../types";
import { writeToSessionStorage } from "../utils";
import { ModuleSettings } from "./types";

const tags = createTagTypes({
  tagPrefix: "Settings",
  tags: ["list"],
});

const endpoints = simplicateApiV2.enhanceEndpoints({ addTagTypes: Object.values(tags) }).injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query<Response<ModuleSettings[]>, void>({
      query: () => ({
        url: "/application/setting",
        responseHandler: writeToSessionStorage<ModuleSettings[]>("applicationSettings"),
      }),
      providesTags: [tags.list],
    }),
  }),
  overrideExisting: false,
});

export const { useGetSettingsQuery } = endpoints;
