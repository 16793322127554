import {
  Big,
  HourTypeInCreateServiceBody,
  HourTypeInService,
  InvoiceMethod,
  transformMoneyToMoneyFromAPI,
} from "@simplicate/api-client";
import { HourTypeInForm, ValidHourTypeInForm } from "./types";

export const transformToCreateServiceHourType = (
  hourTypeInForm: ValidHourTypeInForm,
  invoiceMethod: InvoiceMethod,
): HourTypeInCreateServiceBody => ({
  defaultHourTypeIdentifier: hourTypeInForm.identifier,
  tariff: transformMoneyToMoneyFromAPI(hourTypeInForm.tariff),
  amountOfHours: hourTypeInForm.amount,
  isInvoicable: invoiceMethod === InvoiceMethod.time_and_expenses ? hourTypeInForm.isInvoiceable : undefined,
});

export const transformToHourTypeInForm = (hourTypeFromApi: HourTypeInService): HourTypeInForm => ({
  identifier: hourTypeFromApi.defaultHourType.identifier,
  name: hourTypeFromApi.defaultHourType.name,
  amount: hourTypeFromApi.amountOfHours,
  isInvoiceable: true,
  tariff: {
    amount: Big(hourTypeFromApi.tariff.amount),
    currency: hourTypeFromApi.tariff.currency,
  },
  total: {
    amount: Big(hourTypeFromApi.tariff.amount).mul(hourTypeFromApi.amountOfHours),
    currency: hourTypeFromApi.tariff.currency,
  },
});
