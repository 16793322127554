import { CubeProvider as CubeDevProvider } from "@cubejs-client/react";
import { Spinner } from "@simplicate/ui";
import { PropsWithChildren } from "react";
import { useCubeDev } from "./useCubeDev";

export const CubeProvider = ({ children }: PropsWithChildren) => {
  const cubeApi = useCubeDev();

  if (cubeApi === null) {
    return <Spinner size="large" testId="cube-provider-loader" />;
  }

  return <CubeDevProvider cubeApi={cubeApi}>{children}</CubeDevProvider>;
};
