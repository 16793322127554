import { size5 } from "@simplicate-software/design-tokens";
import classNames from "classnames";
import { PropsWithChildren, useRef } from "react";
import styles from "./SubMenu.module.scss";

const SIZE_5_IN_PIXELS = Number(size5.replace("rem", "")) * 16;

export type SubMenu = PropsWithChildren<{
  className?: string;
  testId?: string;
}>;

export const SubMenu = ({ children, className, testId }: SubMenu) => {
  const elementRef = useRef<HTMLUListElement>(null);
  const currentOffset = useRef<number>(0);

  // The DOM is not accurately represented in the test environment.
  // Therefore, sizing and positioning of elements is not accurate.
  // Meaning it's nearly impossible to properly test this function.
  /* istanbul ignore next */
  const moveIntoViewport = () => {
    if (elementRef.current) {
      const boundingRect = elementRef.current.getBoundingClientRect();
      const unadjustedBottom = boundingRect.bottom + currentOffset.current;

      if (unadjustedBottom > window.innerHeight) {
        const adjustDistance = unadjustedBottom - window.innerHeight;
        const closestMultipleOfItemHeight = Math.ceil(adjustDistance / SIZE_5_IN_PIXELS) * SIZE_5_IN_PIXELS;

        currentOffset.current = closestMultipleOfItemHeight;
        elementRef.current.style.top = `${-1 * closestMultipleOfItemHeight}px`;
      } else {
        currentOffset.current = 0;
        elementRef.current.style.top = `0px`;
      }
    }
  };

  return (
    <ul
      className={classNames(styles.subMenu, className)}
      data-testid={testId}
      ref={elementRef}
      onTransitionEnd={moveIntoViewport}
    >
      {children}
    </ul>
  );
};
