import {
  Big,
  CostType,
  type CostTypeInService,
  type CostTypeInCreateServiceBody,
  transformMoneyToMoneyFromAPI,
} from "@simplicate/api-client";
import { randomUUID } from "./utils";
import type { CostTypeInForm, ValidCostTypeInForm } from "./types";

export const transformCostTypeInServiceToCostTypeInForm = (costTypeFromApi: CostTypeInService): CostTypeInForm => {
  const costType = {
    identifier: costTypeFromApi.identifier,
    name: costTypeFromApi.defaultCostType.name,
    defaultCostType: {
      identifier: costTypeFromApi.defaultCostType.identifier,
      name: costTypeFromApi.defaultCostType.name,
    },
    purchasePrice: {
      amount: Big(costTypeFromApi.purchasePrice.amount),
      currency: costTypeFromApi.purchasePrice.currency,
    },
    sellingPrice: {
      amount: Big(costTypeFromApi.sellingPrice.amount),
      currency: costTypeFromApi.sellingPrice.currency,
    },
    margin: costTypeFromApi.margin,
    total: {
      amount: Big(costTypeFromApi.total.amount),
      currency: "EUR" as const,
    },
    isInvoiceable: true,
  };

  if (costTypeFromApi.defaultCostType.usePricePerUnit && costTypeFromApi.defaultCostType.unit) {
    return {
      ...costType,
      usePricePerUnit: true,
      unit: costTypeFromApi.defaultCostType.unit,
      quantity: 1,
    };
  }

  return {
    ...costType,
    usePricePerUnit: false,
  };
};

export const transformCostTypeToCostTypeInForm = (costType: CostType, label: string): CostTypeInForm => {
  const costTypeInForm = {
    /*
      We only need a unique identifier to support editing the new costType's values in the form,
      the ProjectService is submitted without these costType generated identifiers
      and the back-end will generate the identifiers itself
    */
    identifier: randomUUID(),
    name: label,
    defaultCostType: {
      identifier: costType.identifier,
      name: costType.name,
    },
    purchasePrice: {
      amount: Big(costType.purchasePrice.amount),
      currency: costType.purchasePrice.currency,
    },
    sellingPrice: {
      amount: Big(costType.sellingPrice.amount),
      currency: costType.sellingPrice.currency,
    },
    margin: costType.margin,
    total: {
      amount: Big(costType.sellingPrice.amount),
      currency: "EUR" as const,
    },
    isInvoiceable: true,
  };

  if (costType.usePricePerUnit && costType.unit) {
    return {
      ...costTypeInForm,
      usePricePerUnit: true,
      unit: costType.unit,
      quantity: 1,
    };
  }

  return {
    ...costTypeInForm,
    usePricePerUnit: false,
  };
};

export const transformToCreateServiceCostType = (costType: ValidCostTypeInForm): CostTypeInCreateServiceBody => {
  return {
    identifier: costType.identifier,
    defaultCostTypeIdentifier: costType.defaultCostType.identifier,
    description: costType.name,
    purchasePrice: transformMoneyToMoneyFromAPI(costType.purchasePrice),
    sellingPrice: transformMoneyToMoneyFromAPI(costType.sellingPrice),
    quantity: costType.quantity,
    isInvoicable: costType.isInvoiceable,
  };
};
