import { Tag } from "@simplicate/ui";

type RelationTagProps = {
  organizationId?: string;
  personId?: string;
  name?: string;
};

export const RelationTag = ({ name, organizationId, personId }: RelationTagProps) => {
  if (!name) {
    return null;
  }

  const href = personId ? `/crm/persons/${personId}` : `/crm/organizations/${organizationId}`;

  return <Tag text={name} icon="userGroup" href={href} variant="dark" testId="relation-tag" size="small" />;
};
