import { PageHeader } from "@simplicate/top-bar";
import { useTitleTranslator } from "../../../routing/utils";
import { useRouteConfig } from "../useRouteConfig";

export const GeneralSubHeader = () => {
  const headerTitle = useRouteConfig().headerTitle;

  const title = useTitleTranslator(headerTitle) ?? "";

  return <PageHeader title={title} testId="generalSubHeader" />;
};
