import { useEnvironmentSetting, useLicenseGuard, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";

export const ReverseBillingMenuItem = () => {
  const { t } = useTranslation("main_menu");

  const hasProjectPurchasingPermission = usePermission("projects", "projectpurchase").granted;
  const { enabled: hasAppropriateLicense } = useLicenseGuard("invoicing_reverse_billing");

  const isBranchSecondment = useEnvironmentSetting("environment", "branch_secondment")?.value === 1;
  const reverseBillingEnabled = useEnvironmentSetting("invoices", "is_reverse_billing")?.value === 1;

  const showReverseBillingMenuItem = isBranchSecondment && reverseBillingEnabled;

  if (!hasAppropriateLicense || !hasProjectPurchasingPermission || !showReverseBillingMenuItem) {
    return null;
  }

  return (
    <NavMenu.SubItem
      href="/projects/reverse-billing"
      title={t("projects_reverse_billing")}
      testId="projects-reverse-billing-menu-item"
    />
  );
};
