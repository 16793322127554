import { useGetCurrentUserPermissionsQuery } from "@simplicate/api-client";
import { PropsWithChildren, useMemo } from "react";
import { Navigate, UIMatch } from "react-router-dom";
import { RouteHandle } from "../../routing/types";
import { useMatches } from "../../routing/useMatches";

export const PermissionBoundary = ({ children }: PropsWithChildren) => {
  const matches = useMatches();
  const { data, isLoading, isError } = useGetCurrentUserPermissionsQuery();

  const hasAllPermissions = useMemo(() => {
    const permissions = matches
      .filter(
        (match): match is UIMatch<unknown, Required<Pick<RouteHandle, "permissions">>> => !!match.handle?.permissions,
      )
      .map((match) => ({
        hasPermissionsForRoute:
          match.handle.permissions.length === 0 ||
          match.handle.permissions.some((requiredPermission) =>
            data?.data?.some(
              ({ module_key, right_key }) =>
                module_key === requiredPermission.module_key && right_key === requiredPermission.right_key,
            ),
          ),
      }));

    return permissions.every(({ hasPermissionsForRoute }) => hasPermissionsForRoute);
  }, [matches, data]);

  if (isLoading) {
    return null;
  }

  if (hasAllPermissions && !isError) {
    return children;
  }

  return <Navigate to="/403" replace />;
};
