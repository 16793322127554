import { useAppDispatch, logLocation } from "@simplicate/state";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const PlanhatLogger = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(logLocation(location.pathname));
  }, [location.pathname, dispatch]);

  return null;
};
