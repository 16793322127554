import {
  createApi,
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryArgs,
} from "@reduxjs/toolkit/query/react";

export const getAuthToken = async (forceRefresh: boolean = false) => {
  if (!forceRefresh) {
    const token = sessionStorage.getItem("client-v3-token");

    if (token) {
      return Promise.resolve(token);
    }
  }

  return fetchV3Token().then((response) => {
    sessionStorage.setItem("client-v3-token", response.data.token);

    return response.data.token;
  });
};

export const fetchV3Token = async () => {
  const v3TokenResponse = fetch("/v1/site/gettokenforrebuild", {
    headers: {
      "content-type": "application/json",
    },
    method: "POST",
  });

  return (await v3TokenResponse).json().then((result: { data: { token: string } }) => {
    if (!result.data?.token) {
      throw Error("V3 token response has unexpected format");
    }

    return result;
  });
};

export const insertAuthHeader = async (headers: Headers) => {
  const token = await getAuthToken();

  headers.set("accept", "application/json");
  headers.set("authorization", `Bearer ${token}`);

  return headers;
};

/* istanbul ignore next -- rest of the file is RTK config */
const baseQueryWithV3Token =
  (baseArgs: FetchBaseQueryArgs): BaseQueryFn<FetchArgs | string, unknown, FetchBaseQueryError> =>
  async (args, api, extraOptions) => {
    const adjustedBaseArgs = {
      ...baseArgs,
      prepareHeaders: insertAuthHeader,
    };

    const baseQuery = fetchBaseQuery(adjustedBaseArgs);
    const result = await baseQuery(args, api, extraOptions);

    if (result.error?.status === 401) {
      await getAuthToken(true);

      return baseQuery(args, api, extraOptions);
    }

    return result;
  };

/* istanbul ignore next -- rest of the file is RTK config */
export const simplicateApiV3 = createApi({
  baseQuery: baseQueryWithV3Token({
    baseUrl: "/api/v3/",
  }),
  endpoints: () => ({}),
  reducerPath: "apiV3",
});
