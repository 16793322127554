/**
 * Extracts a search query paramater from a URL.
 * @param location the URL to extract the query parameter from
 * @param key the requested query parameter
 * @throws if the requested query parameter is not found
 */
export function extractQueryParam(location: URL, key: string): string {
  const queryValue = location.searchParams.get(key);

  if (!queryValue) {
    throw new Error(`Expected '${key}' search param not found: ${location.toString()}`);
  }

  return queryValue;
}
