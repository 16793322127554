// istanbul ignore file -- this is a temporary workaround that we will remove asap
import { useGetCurrentUserQuery } from "@simplicate/api-client";

const ADMINISTRATOR_IDENTIFIER = "administrator";

/**
 * A small temporary utility to check if the current user is an administrator. This is used to show the insights menu
 * item, but not a desirable solution. For the short term it is acceptable as a workaround for being able to start
 * performance measuring on cube cloud without the need to enable the insights feature for all users.
 *
 * This should be removed ASAP.
 *
 * @deprecated Frontend should not need to know this. Use permissions instead.
 */
export const useIsAdministrator = () => {
  const { data, isLoading } = useGetCurrentUserQuery();

  return {
    isAdministrator: data?.data.username === ADMINISTRATOR_IDENTIFIER && data?.data.email === ADMINISTRATOR_IDENTIFIER,
    isLoading,
  };
};
