import { useTranslation } from "@simplicate/translations";
import { Spinner } from "@simplicate/ui";
import { MessageBus } from "@simplicate/utils";
import { useEffect, useRef, useState } from "react";
import { useBlocker, useNavigate } from "react-router-dom";
import { useNotificationListener } from "../LegacyWebComponent/useNotificationListener";
import styles from "./DocumentEditor.module.scss";

type EmailEditorProps = { mode: "email"; emailId?: string };
type QuoteEditorProps = { mode: "quote"; saleId?: string; quoteId?: string };
type TemplateEditorProps = { mode: "template"; templateId?: string };
type ContentBlockEditorProps = { mode: "content-block"; contentBlockId?: string };
type DocumentEditorProps = ContentBlockEditorProps | EmailEditorProps | QuoteEditorProps | TemplateEditorProps;

function propsToQueryParams(props: DocumentEditorProps) {
  return new URLSearchParams({
    ...props,
  }).toString();
}

export const DocumentEditor = (props: DocumentEditorProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation("general");
  const [navigationIsBlocked, setNavigationIsBlocked] = useState(false);
  const { state: blockedState, proceed, reset } = useBlocker(navigationIsBlocked);
  const targetSrc = `/document-editor.html?${propsToQueryParams(props)}`;
  const navigate = useNavigate();

  useNotificationListener();

  useEffect(
    () =>
      MessageBus.subscribe("navigation", (payload) => {
        if (payload.origin === "document-editor") {
          navigate(payload.path);
        }
      }),
    [navigate],
  );

  useEffect(
    () =>
      MessageBus.subscribe("blockNavigation", ({ block }) => {
        setNavigationIsBlocked(block);
      }),
    [],
  );

  useEffect(() => {
    if (blockedState === "blocked") {
      const shouldContinue = window.confirm(t("navigation_with_unsaved_changes_confirmation"));

      if (shouldContinue) {
        proceed();
      } else {
        reset();
      }
    }
  }, [blockedState, proceed, reset, t]);

  /* istanbul ignore next */
  const onLoad = () => {
    setIsLoading(false);

    const onUnload = () => MessageBus.publish("registerActionButton", []);

    iframeRef.current?.contentWindow?.addEventListener("unload", onUnload);
  };

  return (
    <>
      {isLoading && (
        <div className={styles.spinnerContainer}>
          <Spinner size="large" />
        </div>
      )}
      <iframe
        key="document-editor"
        ref={iframeRef}
        onLoad={onLoad}
        className={styles.editorContainer}
        title="Document Editor"
        src={targetSrc}
      />
    </>
  );
};
