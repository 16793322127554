import { HEADER_DATA_TAG, simplicateApiV2 } from "../components";
import { createTagTypes } from "../tags";
import { Response } from "./types";

export type SalesV2 = {
  id: string;
  name: string;
  subject: string;
  status: {
    id: string;
    label: string;
  };
  organization?: {
    id: string;
    name: string;
  };
  person?: {
    id: string;
    full_name: string;
  };
};

export type Quote = {
  id: string;
  sales_id?: string;
  quote_number?: string;
  quote_subject?: string;
};

const tags = createTagTypes({ tagPrefix: "Sales", tags: ["entity", "quote"] });

const endpoints = simplicateApiV2.enhanceEndpoints({ addTagTypes: Object.values(tags) }).injectEndpoints({
  endpoints: (builder) => ({
    getV2Sales: builder.query<Response<SalesV2>, string>({
      query: (id) => ({
        url: `/sales/sales/${id}`,
      }),
      providesTags: [tags.entity, HEADER_DATA_TAG],
    }),
    getQuote: builder.query<Response<Quote>, string>({
      query: (id) => ({
        url: `/sales/quote/${id}`,
      }),
      providesTags: [tags.quote, HEADER_DATA_TAG],
    }),
  }),
});

export const { useGetV2SalesQuery, useGetQuoteQuery } = endpoints;
