import { useTranslation } from "@simplicate/translations";
import { Modal, RadioButton } from "@simplicate/ui";
import { useState } from "react";
import { Footer } from "./Footer";

type DeleteGroupModalProps = {
  isOpen: boolean;
  deleteServiceGroup: (shouldDeleteServices: boolean) => void;
  onClose: () => void;
};

export const DeleteGroupModal = ({ isOpen, deleteServiceGroup, onClose }: DeleteGroupModalProps) => {
  const { t } = useTranslation("grouped_services_manager");
  const [selectedValue, setSelectedValue] = useState<"delete-services" | "retain-services">("delete-services");

  const handleChange = (value: "delete-services" | "retain-services") => {
    setSelectedValue(value);
  };

  const handleDelete = () => {
    const shouldDeleteServices = selectedValue === "delete-services";

    deleteServiceGroup(shouldDeleteServices);
    onClose();
  };

  return (
    <Modal
      title={t("delete_group")}
      isOpen={isOpen}
      onClose={onClose}
      footerComponent={<Footer deleteServices={handleDelete} cancel={onClose} />}
      testId="delete-group-modal"
    >
      <RadioButton
        name="delete-service-group-options"
        options={[
          { value: "delete-services", label: t("delete_services") },
          { value: "retain-services", label: t("retain_services") },
        ]}
        onChange={handleChange}
        value={selectedValue}
        label={t("service_deletion_options")}
      />
    </Modal>
  );
};
