import { usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";
import { asModuleTheme } from "@simplicate/utils";

export const Sales = () => {
  const { t } = useTranslation("main_menu");
  const hasViewPermission = usePermission("acquisition", "view").granted;
  const hasReportingPermission = usePermission("acquisition", "rapportage").granted;
  const themeClass = asModuleTheme("sales");

  if (!hasViewPermission) return null;

  return (
    <NavMenu.Item title={t("sales")} href="/sales" className={themeClass} icon="sales" testId="sales-menu-item">
      <NavMenu.SubItem href="/sales/open" title={t("sales_open")} testId="sales-open-menu-item" />
      <NavMenu.SubItem href="/sales/accepted" title={t("sales_accepted")} testId="sales-accepted-menu-item" />
      <NavMenu.SubItem href="/sales/rejected" title={t("sales_declined")} testId="sales-declined-menu-item" />
      <NavMenu.SubItem href="/sales/all" title={t("sales_all")} testId="sales-all-menu-item" />
      {hasReportingPermission && (
        <NavMenu.SubItem title={t("sales_reporting")}>
          <NavMenu.SubItem
            href="/sales/reporting/funnel"
            title={t("sales_funnel_reporting")}
            testId="sales-funnel-menu-item"
          />
        </NavMenu.SubItem>
      )}
    </NavMenu.Item>
  );
};
