import { useMemo } from "react";
import { Setting, SettingKey, SettingModule, SettingValue, useGetSettingsQuery } from "../../endpoints";

type Result<TModule extends SettingModule, TKey extends SettingKey<TModule>> = {
  key: TKey;
  module: TModule;
  options?: number[] | string[];
  value: SettingValue<TModule, TKey>;
};

export function parseSettingValue<TModule extends SettingModule>({
  key,
  type,
  value_type,
  value,
}: Setting<TModule>): SettingValue<TModule, SettingKey<TModule>> {
  const valueType = value_type ?? type;

  return (
    valueType === "string"
      ? value
      : valueType === "int"
        ? parseInt(String(value))
        : valueType === "float"
          ? parseFloat(String(value))
          : value
  ) as SettingValue<TModule, typeof key>;
}

export const useEnvironmentSetting = <TModule extends SettingModule, TKey extends SettingKey<TModule>>(
  module: TModule,
  key: TKey,
): Result<TModule, TKey> | undefined => {
  const { data } = useGetSettingsQuery();

  return useMemo(() => {
    if (!data || !data.data) {
      return undefined;
    }

    const setting = data.data
      .find((category) => category.module === module)
      ?.settings?.find((setting) => setting.key === key);

    if (!setting) {
      return undefined;
    }

    return {
      key,
      module,
      value: parseSettingValue<TModule>(setting),
      options: setting.values,
    } as Result<TModule, TKey>;
  }, [data, module, key]);
};
