import { usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Page } from "@simplicate/ui";
import { NavCard } from "../../components";
import { IndexPageTemplate } from "./IndexPage.template";

// eslint-disable-next-line import/no-unused-modules -- will be used
export const IndexPage = () => {
  const { t } = useTranslation("insights");

  const { granted: hasAccessToHours } = usePermission("hours", "view");
  const { granted: hasHoursReportingPermission } = usePermission("hours", "hoursrapportage_all");

  return (
    <Page>
      <IndexPageTemplate>
        <IndexPageTemplate.Title title={t("index_page_title")} />
        <IndexPageTemplate.Body>
          {hasAccessToHours && hasHoursReportingPermission && (
            <IndexPageTemplate.BodySlot>
              <NavCard
                dashboardId="hours-reporting"
                title={t("hours.report_title")}
                module="hours"
                description={t("hours.report_description")}
              />
            </IndexPageTemplate.BodySlot>
          )}
        </IndexPageTemplate.Body>
      </IndexPageTemplate>
    </Page>
  );
};
