import {
  lightColorIconWarning,
  lightColorIconError,
  lightColorIconSuccess,
  lightColorIconInfo,
} from "@simplicate-software/design-tokens";
import classnames from "classnames";
import { PropsWithChildren } from "react";
import { Clickable } from "../Clickable";
import { Icon, IconName } from "../Icon";
import styles from "./Toast.module.scss";

export type ToastTypeWithIcon = "error" | "info" | "success" | "warning";

export type ToastType = ToastTypeWithIcon | "none";
export type ToastProps = PropsWithChildren<{
  closeToast?: () => void;
  type?: ToastType;
  width?: "narrow" | "wide";
  iconTestId?: string;
  closeButtonTestId?: string;
}>;

const ICON_MAP: Record<ToastTypeWithIcon, IconName> = {
  error: "exclamationTriangle",
  info: "infoCircle",
  success: "check",
  warning: "exclamationCircle",
};

const ICON_COLOR_MAP: Record<
  ToastTypeWithIcon,
  typeof lightColorIconError | typeof lightColorIconInfo | typeof lightColorIconSuccess | typeof lightColorIconWarning
> = {
  error: lightColorIconError,
  info: lightColorIconInfo,
  success: lightColorIconSuccess,
  warning: lightColorIconWarning,
};

/**
 * @deprecated Toast is not meant to be used on its own. Instead, call `showToast`. If you want to render a component on the page use the `Alert` component instead
 */
export const Toast = ({
  type = "none",
  children,
  closeToast,
  width = "narrow",
  iconTestId,
  closeButtonTestId,
}: ToastProps) => {
  return (
    <div className={classnames(styles.toast, styles[type], styles[width])}>
      {type !== "none" ? (
        <span className={styles.icon}>
          <Icon testId={iconTestId} fixedWidth icon={ICON_MAP[type]} color={ICON_COLOR_MAP[type]} />
        </span>
      ) : (
        <div />
      )}
      {children}
      {closeToast && (
        <Clickable testId={closeButtonTestId} className={styles.closeButton} onClick={closeToast}>
          <Icon icon="times" />
        </Clickable>
      )}
    </div>
  );
};
