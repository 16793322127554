import { PropsWithChildren } from "react";
import styles from "./SegmentedControlGroup.module.scss";

// TODO: Make SegmentedControl offer a toggle mode, where only one button can be selected at a time.
type SegmentedControlGroupProps = PropsWithChildren;

export const SegmentedControlGroup = ({ children }: SegmentedControlGroupProps) => {
  return (
    <div className={styles.segmentedControlGroup} data-testid="segmented-button-container">
      {children}
    </div>
  );
};
