import { DoughnutChart, useSimpleTargetDataset } from "@simplicate/charts";
import { Widget } from "@simplicate/ui";
import { useMemo } from "react";
import { useDashboardContext } from "../../components";
import { AggregatorType, useAggregator } from "../../data";
import { CubeDimension } from "../../types";

type TargetKPIWidgetProps = {
  aggregation: AggregatorType;
  dimension: CubeDimension;
  title: string;
};

export const TargetKPIWidget = ({ aggregation, dimension, title }: TargetKPIWidgetProps) => {
  const {
    queryResult: { resultSet },
  } = useDashboardContext();
  const aggregator = useAggregator(dimension, aggregation);

  const value = useMemo(() => aggregator(resultSet) ?? 0, [aggregator, resultSet]);
  const dataset = useSimpleTargetDataset({ value });
  const centerText = `${value.toFixed(0)}%`;

  return (
    <Widget title={title}>
      <DoughnutChart data={dataset} centerText={centerText} />
    </Widget>
  );
};
