import { usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";

export const ReadyForInvoicingMenuItem = () => {
  const { t } = useTranslation("main_menu");

  const hasProjectsViewPermission = usePermission("projects", "view").granted;

  if (!hasProjectsViewPermission) {
    return null;
  }

  return (
    <NavMenu.SubItem
      href="/invoicing/ready-for-invoicing"
      title={t("invoicing_ready")}
      testId="invoices-ready-menu-item"
    />
  );
};
