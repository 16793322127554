import { ConversionRecord } from "../types";
import { extractQueryParam, extractSelectedTab } from "./utils";

export const HRMRouteConversions: ConversionRecord = {
  "/hrm/employee": (location) => {
    const suffix = extractSelectedTab(location, {
      furlough_employee: "leave",
      personnelFile: "documents",
      personalia: "employee",
      contact: "personal-info",
    });

    return {
      status: "pending-id-conversion",
      locationWithId: (id: string) => `/hrm/${id}/${suffix}?${location.searchParams.toString()}`,
      queryArguments: {
        v1Identifier: extractQueryParam(location, "id"),
        entityName: "employee",
      },
    };
  },

  "/v1/hrm": (location) => {
    const suffix = extractSelectedTab(location, {
      allEmployees: "all-employees",
      all: "all-employees",
      active_employees: "employees",
    });

    return { status: "complete", location: `/hrm/${suffix}` };
  },

  "v1/reporting/leave": (location) => {
    return { status: "complete", location: `/hrm/reporting/leave?${location.searchParams.toString()}` };
  },

  "v1/reporting/absence": (location) => {
    return { status: "complete", location: `/hrm/reporting/absence?${location.searchParams.toString()}` };
  },
};
