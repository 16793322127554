import { Big } from "@simplicate/api-client";
import { CurrencyFormat, NumberFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { Table, buildColumnProps, Icon } from "@simplicate/ui";
import { size5, size7, size8, size9, lightColorIconSuccess } from "@simplicate-software/design-tokens";
import { HourTypeActionDropdown } from "./HourTypeActionDropdown";
import styles from "./HourTypeGrid.module.scss";
import { HourTypeGridFooter } from "./HourTypeGridFooter";
import type { HourTypeInForm, MoneyInForm } from "../../types";

type HourTypeGridProps = {
  value?: HourTypeInForm[];
  onAmountChange: (identifier: string, amount: number | undefined) => void;
  onTariffChange: (identifier: string, tariff: Big | undefined) => void;
  onIsInvoiceableToggled: (identifier: string) => void;
  onAddNewHourTypeEntry: (entry: HourTypeInForm) => void;
  onCancelNewHourTypeEntry: () => void;
  onRemoveHourTypeEntries: (identifiers: string[]) => void;
  onChangeSpecifiedTotal: (specifiedTotal: Big | undefined) => void;
  specifiedTotal?: MoneyInForm;
  showIsInvoiceableColumn?: boolean;
  disabled?: boolean;
};

export const HourTypeGrid = ({
  value = [],
  onAmountChange,
  onTariffChange,
  onIsInvoiceableToggled,
  onAddNewHourTypeEntry,
  onCancelNewHourTypeEntry,
  onRemoveHourTypeEntries,
  onChangeSpecifiedTotal,
  specifiedTotal,
  showIsInvoiceableColumn = false,
  disabled = false,
}: HourTypeGridProps) => {
  const { t } = useTranslation("project_services");

  const { totalAmount } = value.reduce(
    (totals, current) => {
      totals.totalAmount += current.amount ?? 0;
      totals.totalMonetaryValue = totals.totalMonetaryValue.add(current.total.amount ?? 0);

      return totals;
    },
    { totalAmount: 0, totalMonetaryValue: Big(0) },
  );

  return (
    <Table
      testId="hour-type-grid"
      value={value}
      dataKey="identifier"
      emptyMessage={t("empty_hour_type_grid_message")}
      footer={
        <HourTypeGridFooter
          onConfirmNewEntry={onAddNewHourTypeEntry}
          onCancelNewEntry={onCancelNewHourTypeEntry}
          totalAmount={totalAmount}
          specifiedTotal={specifiedTotal}
          disabled={disabled}
          value={value}
          onChangeSpecifiedTotal={onChangeSpecifiedTotal}
        />
      }
      disabled={disabled}
    >
      <Table.Column
        {...buildColumnProps({
          header: t("hour_type_label_header"),
          field: "label",
          body: ({ name }: HourTypeInForm) => name,
          width: "dynamic",
        })}
      />
      {showIsInvoiceableColumn ? (
        <Table.Column
          {...buildColumnProps({
            header: t("hour_type_is_invoiceable_header"),
            field: "isInvoiceable",
            body: ({ isInvoiceable }: HourTypeInForm) =>
              isInvoiceable ? <Icon icon="check" color={lightColorIconSuccess} /> : <span>-</span>,
            width: size5,
          })}
        />
      ) : (
        <></>
      )}
      <Table.Column
        {...buildColumnProps({
          header: t("hour_type_quantity_header"),
          field: "amount",
          body: ({ identifier, amount }: HourTypeInForm) => (
            <NumberFormat
              testId="input-hour-type-amount"
              displayType="input"
              size="small"
              value={amount}
              decimalScale={2}
              textAlign="right"
              onValueChange={
                /* istanbul ignore next -- Component is mocked as <span/> making input test cases impossible */
                ({ floatValue }) => onAmountChange(identifier, floatValue)
              }
              onBlur={() => {
                if (amount === undefined) {
                  onAmountChange(identifier, 0);
                }
              }}
              disabled={disabled}
            />
          ),
          width: size7,
          align: "right",
        })}
      />
      <Table.Column
        {...buildColumnProps({
          header: t("hour_type_tariff_header"),
          field: "tariff",
          body: ({ identifier, tariff }: HourTypeInForm) => (
            <CurrencyFormat
              testId="input-hour-type-tariff"
              displayType="input"
              size="small"
              value={tariff?.amount?.toString()}
              decimalScale={2}
              textAlign="right"
              onValueChange={
                /* istanbul ignore next -- Component is mocked as <span/> making input test cases impossible */
                ({ value }) => {
                  if (value.length === 0) {
                    return onTariffChange(identifier, undefined);
                  }

                  return onTariffChange(identifier, Big(value));
                }
              }
              onBlur={() => {
                if (tariff === undefined) {
                  onTariffChange(identifier, Big(0));
                }
              }}
              disabled={disabled}
            />
          ),
          width: size9,
          align: "right",
        })}
      />
      <Table.Column
        {...buildColumnProps({
          header: t("hour_type_total_header"),
          field: "total",
          body: ({ total }: HourTypeInForm) => (
            <CurrencyFormat
              title={total.amount.toFixed(2)}
              className={styles.totalColumn}
              displayType="text"
              decimalScale={2}
              disabled={disabled}
              value={total.amount.toString()}
            />
          ),
          width: size8,
          align: "right",
        })}
      />
      <Table.Column
        {...buildColumnProps({
          body: ({ identifier, isInvoiceable }: HourTypeInForm) => (
            <HourTypeActionDropdown
              identifier={identifier}
              isInvoiceable={isInvoiceable}
              onClickMoveToTop={/* istanbul ignore next -- TODO: implement ordering */ () => {}}
              onClickMoveToBottom={/* istanbul ignore next -- TODO: implement ordering */ () => {}}
              onClickToggleIsInvoiceable={showIsInvoiceableColumn ? onIsInvoiceableToggled : undefined}
              onClickRemove={(identifier) => onRemoveHourTypeEntries([identifier])}
              disabled={disabled}
            />
          ),
          width: size5,
          align: "right",
        })}
      />
    </Table>
  );
};
