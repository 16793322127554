import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const LOCATION_EVENT_ACTIONS = {
  // GRID EVENTS:
  // Crm grid
  "/crm/organizations": "opened_crm_organizations_grid",
  "/crm/persons": "opened_crm_persons_grid",
  // Sales grid
  "/sales/open": "opened_sales_grid",
  "/sales/accepted": "opened_sales_grid",
  "/sales/rejected": "opened_sales_grid",
  "/sales/all": "opened_sales_grid",
  // Projects grid
  "/projects/active": "opened_projects_grid",
  "/projects/closed": "opened_projects_grid",
  "/projects/all": "opened_projects_grid",
  // Hours grid
  "/hours/register": "opened_hours_grid_new",
  "/hours/agenda": "opened_hours_grid",
  "/hours/timesheet": "opened_hours_grid",
  "/hours/mileage": "opened_hours_grid",
  // Invoicing grid
  "/invoicing/ready-for-invoicing": "opened_tobeinvoiced_grid",
  "/invoicing/draft-invoices": "opened_conceptinvoices_grid",
  "/invoicing/approval": "opened_invoices_grid",
  "/invoicing/outstanding": "opened_invoices_grid",
  "/invoicing/all": "opened_invoices_grid",
  "/invoicing/accounting": "opened_accounting_grid",
  // Capacity planning
  "/planning": "opened_capacity_planning",
  // Business intelligence
  "/business-intelligence": "opened_business-intelligence",
} as const;

type LocationEventAction = (typeof LOCATION_EVENT_ACTIONS)[keyof typeof LOCATION_EVENT_ACTIONS];

export type PlanhatEvent = {
  action: LocationEventAction;
  date: string;
};

export type PlanhatState = {
  events: PlanhatEvent[];
};

const initialState: PlanhatState = {
  events: [],
};

function asPlanhatEvent(pathName: string): PlanhatEvent | undefined {
  if (Object.keys(LOCATION_EVENT_ACTIONS).includes(pathName)) {
    return {
      action: LOCATION_EVENT_ACTIONS[pathName as keyof typeof LOCATION_EVENT_ACTIONS],
      date: new Date().toISOString(),
    };
  }

  return undefined;
}

const planhatSlice = createSlice({
  name: "planhat",
  initialState,
  reducers: {
    queueEvent: (state, action: PayloadAction<string>) => {
      const event = asPlanhatEvent(action.payload);

      if (event) {
        return {
          ...state,
          events: [...state.events, event],
        };
      }

      return state;
    },
    clearEvents: () => ({ events: [] }),
  },
});

export const { queueEvent, clearEvents } = planhatSlice.actions;
export const planhatReducer = planhatSlice.reducer;
