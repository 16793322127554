import { useEnvironmentSetting, useLicenseGuard } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";

export const InvoicesApprovalMenuItem = () => {
  const { t } = useTranslation("main_menu");

  const invoiceApprovalEnabled = useEnvironmentSetting("invoices", "is_use_invoice_approval")?.value === 1;
  const { enabled: hasInvoiceApprovalEnabled } = useLicenseGuard("invoicing_invoice_approval");

  if (!hasInvoiceApprovalEnabled || !invoiceApprovalEnabled) {
    return null;
  }

  return (
    <NavMenu.SubItem href="/invoicing/approval" title={t("invoicing_approval")} testId="invoices-approval-menu-item" />
  );
};
