import { useFeatureFlag, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";
import { asModuleTheme } from "@simplicate/utils";

export const ResourcePlanner = () => {
  const { t } = useTranslation("main_menu");
  const hasPermission = usePermission("resourceplanner", "view").granted;
  const featureEnabled = useFeatureFlag("resource-planner").enabled;
  const themeClass = asModuleTheme("resource-planner");

  if (!featureEnabled || !hasPermission) {
    return null;
  }

  return (
    <NavMenu.Item
      href="planning"
      className={themeClass}
      icon="resource-planner"
      title={t("resource_planner")}
      testId="resource-planner-menu-item"
    />
  );
};
