import { CurrencyFormat as UICurrency, NumberFormat as UINumber } from "@simplicate/number-format";
import { isValidElement, useMemo } from "react";
import { Formatter } from "../../types";

type FormatterProps = { value: unknown };

type FormattedValueProps = FormatterProps & {
  formatter: Formatter;
};

const UnformattedValue = ({ value }: FormatterProps) =>
  isValidElement(value) || typeof value === "string" || typeof value === "number" ? <span>{value}</span> : null;

const NumberFormatter = ({ value }: FormatterProps) =>
  typeof value === "number" ? <UINumber value={value} decimalScale={2} /> : <UnformattedValue value={value} />;

const PercentageFormatter = ({ value }: FormatterProps) =>
  typeof value === "number" ? (
    <UINumber value={value} decimalScale={0} suffix="%" />
  ) : (
    <UnformattedValue value={value} />
  );

const CurrencyFormatter = ({ value }: FormatterProps) =>
  typeof value === "number" ? <UICurrency value={value} decimalScale={2} /> : <UnformattedValue value={value} />;

export const FormattedValue = ({ formatter, value }: FormattedValueProps) => {
  const Formatter = useMemo(() => {
    switch (formatter) {
      case "number":
        return NumberFormatter;
      case "currency":
        return CurrencyFormatter;
      case "percentage":
        return PercentageFormatter;
      default:
        return UnformattedValue;
    }
  }, [formatter]);

  return <Formatter value={value} />;
};
