import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import localStorage from "redux-persist/lib/storage";

export type MainMenuState = { expanded: boolean };

const initialState: MainMenuState = { expanded: false };

const mainMenuSlice = createSlice({
  name: "locale",
  initialState,
  reducers: {
    toggleMainMenuExpanded: (state) => ({
      ...state,
      expanded: !state.expanded,
    }),
  },
});

export const { toggleMainMenuExpanded } = mainMenuSlice.actions;
export const mainMenuReducer = persistReducer(
  {
    key: "mainMenu",
    storage: localStorage,
  },
  mainMenuSlice.reducer,
);

export const _testMainMenuReducer = mainMenuSlice.reducer;
