import { ConversionRecord } from "../types";
import { extractQueryParam, extractSelectedTab } from "./utils";

export const ProjectsRouteConversions: ConversionRecord = {
  "/projects/project/view": (location) => {
    const suffix = extractSelectedTab(location, {
      terms: "installments",
      hours_cost: "hours",
      purchase_cost: "costs",
      dossier: "documents",
      time_line: "timeline",
    });

    return {
      status: "pending-id-conversion",
      locationWithId: (id: string) => `/projects/${id}/${suffix}`,
      queryArguments: {
        v1Identifier: extractQueryParam(location, "id"),
        entityName: "project",
      },
    };
  },

  "/projects/purchase/view": (location) => {
    return {
      status: "pending-id-conversion",
      locationWithId: (id: string) => `/projects/purchasing/${id}/purchase`,
      queryArguments: {
        v1Identifier: extractQueryParam(location, "id"),
        entityName: "purchase",
      },
    };
  },

  "/projects/reversebilling/view": (location) => {
    return {
      status: "pending-id-conversion",
      locationWithId: (id: string) => `/projects/reverse-billing/${id}`,
      queryArguments: {
        v1Identifier: extractQueryParam(location, "id"),
        entityName: "reversebilling",
      },
    };
  },

  "/v1/projects/purchase/placefile": (location) => {
    return {
      status: "pending-id-conversion",
      locationWithId: (id) => `/projects/purchasing/${id}/placefile?${location.searchParams.toString()}`,
      queryArguments: {
        v1Identifier: extractQueryParam(location, "id"),
        entityName: "purchase",
      },
    };
  },

  "/v1/projects/purchase/deleteFile": (location) => {
    return {
      status: "pending-id-conversion",
      locationWithId: (id) => `/projects/purchasing/${id}/deleteFile?${location.searchParams.toString()}`,
      queryArguments: {
        v1Identifier: extractQueryParam(location, "id"),
        entityName: "purchase",
      },
    };
  },

  "/v1/projects": (location) => {
    const suffix = extractSelectedTab(location, {
      purchase: "purchasing",
      pactive: "active",
      pclosed: "closed",
      reverse_billing: "reverse-billing",
    });

    if (location.search && !suffix) {
      // Choose active sub path, because it is the default redirect target from "/projects".
      // This ensures the search params are not lost when redirecting.
      return { status: "complete", location: `/projects/active?${location.searchParams.toString()}` };
    }

    return { status: "complete", location: `/projects/${suffix}` };
  },

  "/v1/reporting/revenue": (location) => {
    return { status: "complete", location: `/projects/reporting/revenue?${location.searchParams.toString()}` };
  },

  "/v1/reporting/process": (location) => {
    return { status: "complete", location: `/projects/reporting/result?${location.searchParams.toString()}` };
  },

  "/v1/reporting/combined": (location) => {
    return {
      status: "complete",
      location: `/projects/reporting/forecasted-invoiced?${location.searchParams.toString()}`,
    };
  },
};
