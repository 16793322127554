import { useLazyGetV2IdentifierQuery } from "@simplicate/api-client";
import { ConversionResult, iframeToReactUrl } from "@simplicate/utils";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NavigationWrapper } from "../components";
import { PlanhatLogger } from "../components/PlanhatLogger/PlanhatLogger";
import { useEnvironmentNotifications } from "../components/useEnvironmentNotifications";

// TODO: move to components directory and add a test suite
export const RootComponent = () => {
  const navigate = useNavigate();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [fetchV2Identifier, results] = useLazyGetV2IdentifierQuery();
  const [urlConversionResult, setConversionResult] = useState<ConversionResult | undefined>(undefined);

  useEffect(() => {
    if (isInitialLoad) {
      try {
        const conversion = iframeToReactUrl(window.location.href);

        if (conversion.status === "complete") {
          navigate(conversion.location, { replace: true });
        }
        if (conversion.status === "pending-id-conversion") {
          void fetchV2Identifier(conversion.queryArguments);
          setConversionResult(conversion);
        }
      } catch (e) {
        console.error(e);
        console.trace();

        navigate("/500", { replace: true });
      } finally {
        setIsInitialLoad(false);
      }
    }
  }, [isInitialLoad, fetchV2Identifier, navigate]);

  useEffect(() => {
    if (
      results.data &&
      !results.isFetching &&
      urlConversionResult &&
      urlConversionResult.status === "pending-id-conversion"
    ) {
      navigate(urlConversionResult.locationWithId(results.data), { replace: true });
      setConversionResult(undefined);
    }
  }, [navigate, results.data, results.isFetching, urlConversionResult]);

  useEnvironmentNotifications();

  if (urlConversionResult?.status === "pending-id-conversion") {
    return null;
  }

  return (
    <>
      <NavigationWrapper />
      <PlanhatLogger />
    </>
  );
};
