import { useGetV1IdentifierQuery } from "@simplicate/api-client";
import { useMemo } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

function withSearchParams(location: string, searchParams: URLSearchParams) {
  const searchQuery = searchParams.toString();

  if (searchQuery.length > 0) {
    const parsedLocation = new URL(location, window.origin);
    const combinedSearchParams = new URLSearchParams([...searchParams, ...parsedLocation.searchParams]);

    return `${parsedLocation.pathname}?${combinedSearchParams.toString()}${parsedLocation.hash}`;
  }

  return location;
}

function getIdentifierReplacementPair(location: string, params: Record<string, string>) {
  const identifierRegex = /:(\w+)/g; // ":<word>" matches words preceded by a colon

  const matches = [...location.matchAll(identifierRegex)];

  if (matches.length === 0) {
    return [undefined, undefined] as const;
  }

  // istanbul ignore next -- this is a safeguard against multiple identifiers in a single location, which never happens
  if (matches.length > 1) {
    throw new Error("Invalid location -- multiple identifiers are not supported");
  }

  // istanbul ignore next -- TS demands we use optional chaining, but we know the match exists
  const paramName = matches.at(0)?.at(1) ?? "id";

  return [paramName, params[paramName]] as const;
}

export const useLocationCorrection = (location: string) => {
  const topLevelLocation = useLocation();
  const [searchParams] = useSearchParams();
  const params = useParams<{ id: string }>();

  const [identifierKey, identifierValue] = useMemo(
    () => getIdentifierReplacementPair(location, params),
    [location, params],
  );

  const identifierRequiresConversion = identifierKey !== undefined;
  const { data: v1Identifier, isFetching } = useGetV1IdentifierQuery(identifierValue, {
    skip: !identifierRequiresConversion,
  });

  return useMemo(() => {
    if (identifierRequiresConversion) {
      if (isFetching) {
        return {
          isLoading: true,
          location: "",
        };
      }

      return {
        isLoading: false,
        location:
          withSearchParams(
            location.replace(`:${identifierKey}`, /* istanbul ignore next */ v1Identifier ?? ""),
            searchParams,
          ) + topLevelLocation.hash,
      };
    }

    return {
      isLoading: false,
      location: withSearchParams(location, searchParams) + topLevelLocation.hash,
    };
  }, [
    identifierKey,
    identifierRequiresConversion,
    isFetching,
    location,
    searchParams,
    topLevelLocation.hash,
    v1Identifier,
  ]);
};
