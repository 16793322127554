import { User } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";

export const MyHrmMenuItem = ({ is_light_user = true, employee_id }: User) => {
  const { t } = useTranslation("main_menu");

  if (is_light_user) {
    return null;
  }

  const myHrmRoute = `/hrm/${employee_id}/overview`;

  return <NavMenu.SubItem href={myHrmRoute} title={t("hrm_employee_data")} testId="hrm-my-data-menu-item" />;
};
