import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { History } from "./History";

const NAVIGATION_DELAY = 500;

/**
 * This hook fixes the browser's history behavior when using its back and forward buttons. Due to the necessity of using
 * iframes this is, sadly, necessary. Since iframe will - no matter what - always create a history entry whenever it is
 * mounted, the back button will attempt to unmount the iframe before actually navigating back, causing the user to stay
 * on the very same page unless they click the back button twice.
 *
 * See: https://simplicate.atlassian.net/browse/SIM-36704
 */
export const useHistoryFix = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [history] = useState(() => new History(navigate));

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.current = location.key;
    }, NAVIGATION_DELAY);

    return () => clearTimeout(timeout);
  }, [history, location.key]);

  useEffect(() => {
    const onPopState = (event: { state: { key: string } }) => {
      setTimeout(() => {
        const historyStateKey = event.state?.key;

        if (historyStateKey !== history.current) {
          history.goto(event.state.key);
        }
      }, NAVIGATION_DELAY);
    };

    window.addEventListener("popstate", onPopState);

    return () => window.removeEventListener("popstate", onPopState);
  }, [history]);
};
