import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import localStorage from "redux-persist/lib/storage";

const DEFAULT_LANGUAGE: Locale = "nl";
const SUPPORTED_LOCALES = ["en", "nl"] as const;

export type Locale = (typeof SUPPORTED_LOCALES)[number];
export type LocaleState = { locale: Locale };

export function getDefaultLocale(): Locale {
  return SUPPORTED_LOCALES.find((locale) => navigator.language.startsWith(locale)) ?? DEFAULT_LANGUAGE;
}

function getLocale(locale: string): Locale {
  return (
    SUPPORTED_LOCALES.find((supportedLocale) => supportedLocale.toLowerCase() === locale.toLowerCase()) ??
    getDefaultLocale()
  );
}

const initialState: LocaleState = { locale: getDefaultLocale() };

const localeSlice = createSlice({
  name: "locale",
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<string>) => {
      const locale = getLocale(action.payload);

      window.document.documentElement.lang = locale;

      void localStorage.setItem("locale", locale);

      return { ...state, locale };
    },
  },
});

export const { setLocale } = localeSlice.actions;
export const localeReducer = persistReducer(
  {
    key: "userLocale",
    storage: localStorage,
  },
  localeSlice.reducer,
);

export const _testLocaleReducer = localeSlice.reducer;
