const variantMap = {
  blue: "primary",
  transparent: "subtle",
  green: "secondary",
} as const;

export function getVariant(color?: string) {
  if (color && color in variantMap) {
    return variantMap[color as keyof typeof variantMap];
  }

  return "secondary";
}
