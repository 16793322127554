import styles from "./FormikErrors.module.scss";

type FormikErrorProps = {
  error: string | undefined;
  touched: boolean | undefined;
};

export const FormikErrors = ({ touched, error }: FormikErrorProps) => {
  return touched && error && <span className={styles.invalid}>{error}</span>;
};
