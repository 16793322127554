/* istanbul ignore file -- IntersectionObserver and MutationObserver does not exist in jest, nor does jsdom actually do any layouting. */

import classNames from "classnames";
import { PropsWithChildren, useCallback, useEffect, useReducer, useRef } from "react";
import { Icon } from "../../Icon";
import { SCROLL_OBSERVABLE_SELECTOR } from "./constants";
import styles from "./ScrollContainer.module.scss";

const VISIBILITY_THRESHOLD = 0.95;

type ScrollContainerProps = PropsWithChildren<{
  className?: string;
  testId?: string;
}>;

type ScrollContainerEvent = {
  type: "first-child" | "last-child";
  isIntersecting: boolean;
};
type ScrollContainerState = {
  firstChildVisible: boolean;
  lastChildVisible: boolean;
};
function scrollContainerReducer(state: ScrollContainerState, action: ScrollContainerEvent): ScrollContainerState {
  switch (action.type) {
    case "first-child":
      return { ...state, firstChildVisible: action.isIntersecting };

    case "last-child":
      return { ...state, lastChildVisible: action.isIntersecting };
  }
}

export const ScrollContainer = ({ children, className, testId }: ScrollContainerProps) => {
  const scrollContainer = useRef<HTMLDivElement>(null);
  const [{ firstChildVisible, lastChildVisible }, dispatch] = useReducer(scrollContainerReducer, {
    firstChildVisible: true,
    lastChildVisible: true,
  });

  const observeElements = (intersectionObserver: IntersectionObserver) => {
    scrollContainer.current
      ?.querySelectorAll(SCROLL_OBSERVABLE_SELECTOR)
      .forEach((element) => intersectionObserver.observe(element));
  };

  const intersectionObserverCallback: IntersectionObserverCallback = useCallback((entries) => {
    const firstChild = entries.find((entry) => entry.target.previousSibling === null);
    const lastChild = entries.find((entry) => entry.target.nextSibling === null);

    if (firstChild) {
      dispatch({ type: "first-child", isIntersecting: firstChild.isIntersecting });
    }

    if (lastChild) {
      dispatch({ type: "last-child", isIntersecting: lastChild.isIntersecting });
    }
  }, []);

  const mutationObserverCallback = useCallback(
    (mutations: MutationRecord[], intersectionObserver: IntersectionObserver) => {
      const hasRelevantChanges = mutations.some(
        (mutation) => mutation.addedNodes.length > 0 || mutation.removedNodes.length > 0,
      );

      if (hasRelevantChanges) {
        intersectionObserver.disconnect();
        observeElements(intersectionObserver);
      }
    },
    [],
  );

  useEffect(() => {
    if (scrollContainer.current === null) {
      return;
    }

    const intersectionObserver = new IntersectionObserver(intersectionObserverCallback, {
      root: scrollContainer.current,
      threshold: VISIBILITY_THRESHOLD,
    });

    observeElements(intersectionObserver);

    const mutationObserver = new MutationObserver((mutations) => {
      mutationObserverCallback(mutations, intersectionObserver);
    });

    mutationObserver.observe(scrollContainer.current, { childList: true, attributes: false });

    return () => {
      mutationObserver.disconnect();
      intersectionObserver.disconnect();
    };
  }, [mutationObserverCallback, intersectionObserverCallback]);

  const scrollLeft = useCallback(() => {
    scrollContainer.current?.scrollBy({
      left: -scrollContainer.current.clientWidth,
      behavior: "smooth",
    });
  }, []);

  const scrollRight = useCallback(() => {
    scrollContainer.current?.scrollBy({
      left: scrollContainer.current.clientWidth,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className={classNames(className, styles.container)}>
      <button
        className={classNames(styles.scrollButton, !firstChildVisible && styles.visible)}
        onClick={scrollLeft}
        aria-hidden
        tabIndex={-1}
        data-testid={`${testId}-scroll-left`}
      >
        <Icon icon="chevronLeft" />
      </button>
      <div ref={scrollContainer} className={styles.scrollContainer} data-testid={testId}>
        {children}
      </div>
      <button
        className={classNames(styles.scrollButton, !lastChildVisible && styles.visible)}
        onClick={scrollRight}
        aria-hidden
        tabIndex={-1}
        data-testid={`${testId}-scroll-right`}
      >
        <Icon icon="chevronRight" />
      </button>
    </div>
  );
};
