import { User, useGetAvatarQuery, useGetCurrentUserQuery } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Avatar, Icon, List, ListItem } from "@simplicate/ui";
import { lightColorIconDefault } from "@simplicate-software/design-tokens";
import { memo } from "react";
import { Link, NavLink } from "react-router-dom";
import styles from "./ProfileDropdown.module.scss";

export function constructFullName(
  user: Pick<User, "family_name_prefix" | "family_name" | "first_name"> | undefined,
): string {
  if (!user) {
    return "";
  }

  const nameParts = [user.first_name, user.family_name_prefix, user.family_name].filter(
    (part): part is string => !!part,
  );

  return nameParts.join(" ");
}

export const ProfileDropdown = memo(function ProfileDropdown() {
  const { t } = useTranslation("top_bar");
  const { data: user, isSuccess: hasUser } = useGetCurrentUserQuery();
  const { data: avatarData } = useGetAvatarQuery({ id: user?.data.employee_id }, { skip: !hasUser });

  const avatar = avatarData?.data[0];
  const fullName = constructFullName(user?.data);

  return (
    <List>
      <ListItem>
        <div className={styles.profileInfo}>
          <div className={styles.profileAvatar}>
            <Avatar src={avatar?.url_small} altText={avatar?.initials} size="large" />
          </div>
          <div className={styles.profileText}>
            <span className={styles.name}>{fullName}</span>
            <span className={styles.email}>{user?.data.email || ""}</span>
          </div>
        </div>
      </ListItem>
      <ListItem>
        <NavLink to="/dashboard/user" className={styles.link}>
          <Icon icon="user" fixedWidth className={styles.icon} color={lightColorIconDefault} />
          <span>{t("my_profile")}</span>
        </NavLink>
      </ListItem>
      <ListItem>
        <Link to="/site/logout" className={styles.link}>
          <Icon icon="powerOff" fixedWidth className={styles.icon} color={lightColorIconDefault} />
          <span>{t("logout")}</span>
        </Link>
      </ListItem>
    </List>
  );
});
