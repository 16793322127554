import { colorCommonGreen500, lightColorIconDefault, lightColorIconInvert } from "@simplicate-software/design-tokens";
import classnames from "classnames";
import { getContrastColor } from "../../utils/getContrastColor";
import { Clickable, ClickableProps } from "../Clickable";
import { Icon, IconName } from "../Icon";
import styles from "./Tag.module.scss";

type TagVariant = "color" | "dark" | "default" | "light";
type TagProps = ClickableProps & {
  text: string;
  variant?: TagVariant;
  color?: string;
  size?: "medium" | "small";
  onClose?: () => void;
  icon?: IconName;
  testId?: string;
  href?: string;
  onClick?: () => void;
};

const variantMap: Record<TagVariant, string> = {
  default: lightColorIconDefault,
  color: lightColorIconInvert,
  light: lightColorIconDefault,
  dark: lightColorIconInvert,
};

const InnerTag = ({ text, icon, color }: { text: string; icon?: IconName; color?: string }) => (
  <>
    {icon && (
      <span className={styles.icon}>
        <Icon icon={icon} color={color} />
      </span>
    )}
    <span>{text}</span>
  </>
);

export const Tag = ({
  text,
  size = "medium",
  variant = "default",
  icon,
  onClose,
  testId,
  color = colorCommonGreen500,
  href,
  onClick,
}: TagProps) => {
  const iconColor = variantMap[variant];
  const tagStyle = variant === "color" ? { "--background-color": color, color: getContrastColor(color) } : undefined;

  return (
    <span style={tagStyle} className={classnames(styles.tag, styles[variant], styles[size])}>
      {href || onClick ? (
        <Clickable onClick={onClick} data-testid={testId} className={styles.tagInner} to={href}>
          <InnerTag text={text} icon={icon} color={iconColor} />
        </Clickable>
      ) : (
        <span data-testid={testId} className={styles.tagInner}>
          <InnerTag text={text} icon={icon} color={iconColor} />
        </span>
      )}

      {onClose && (
        <Clickable onClick={onClose} data-testid="tag-close-button" className={classnames(styles.icon, styles.close)}>
          <Icon icon="times" color={iconColor} />
        </Clickable>
      )}
    </span>
  );
};
