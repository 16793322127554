import { Children, ReactElement, ReactNode } from "react";

export function determineHref(children: ReactNode, href: string | undefined) {
  if (!children || href) {
    return href;
  }

  const firstDefinedChild = Children.toArray(children).find(
    (child): child is ReactElement<{ href: string }> =>
      typeof child === "object" &&
      Object.hasOwn(child, "props") &&
      Object.hasOwn((child as { props: object }).props, "href") &&
      typeof (child as { props: { href: unknown } }).props.href === "string",
  );

  if (!firstDefinedChild) {
    return href;
  }

  return firstDefinedChild.props.href;
}
