import { createTagTypes, type CostType, simplicateApiV3 } from "@simplicate/api-client";

const tags = createTagTypes({
  tagPrefix: "cost_type",
  tags: ["entity", "list"],
});

const endpoints = simplicateApiV3.enhanceEndpoints({ addTagTypes: Object.values(tags) }).injectEndpoints({
  endpoints: (builder) => ({
    getCostType: builder.query<CostType, string>({
      query: (identifier) => ({
        url: `/costType.get/${identifier}`,
      }),
      providesTags: (result) => (result ? [{ type: tags.entity, id: result.identifier }] : []),
    }),
    getCostTypes: builder.query<CostType[], void>({
      query: () => ({
        url: "/costType.list",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map((costType) => ({
                type: tags.entity,
                id: costType.identifier,
              })),
              tags.list,
            ]
          : [tags.list],
    }),
  }),
  overrideExisting: false,
});

export const { useGetCostTypesQuery, useGetCostTypeQuery } = endpoints;
