import { useLazyGetV2IdentifierQuery, type Notification as NotificationDataType } from "@simplicate/api-client";
import { useElapsedTimeMessage } from "@simplicate/translations";
import { ListItem, ModuleIcon } from "@simplicate/ui";
import { ConversionResult, asModule, asModuleTheme, iframeToReactUrl } from "@simplicate/utils";
import classNames from "classnames";
import { format as formatDate } from "date-fns";
import { ForwardedRef, forwardRef, memo, PropsWithChildren, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Notification.module.scss";

const PLACEHOLDER_DELIMITER = /[{|}]/g;
const DATE_FORMAT = "dd MMM yyyy HH:mm";

type MessagePart = {
  message: string;
  isReference: boolean;
  url?: string;
  module?: string;
};

type NotificationProps = NotificationDataType & {
  testId?: string;
};

function splitMessage(message: string, references: NotificationDataType["references"]): MessagePart[] {
  return message.split(PLACEHOLDER_DELIMITER).map((part) => {
    const reference = references[`{${part}}`];

    if (!reference) {
      return { message: part, isReference: false };
    }

    return {
      message: String(reference.label),
      isReference: true,
      module: reference.module ?? undefined,
      url: reference.url ?? undefined,
    };
  });
}

export const Notification = memo(function Notification({
  message,
  references,
  module,
  created_at,
  is_read,
  testId,
  primary_url,
}: NotificationProps) {
  const parts = splitMessage(message, references);
  const { format } = useElapsedTimeMessage();

  const appropriateModule = asModule(module) ?? "environment-management";
  const moduleClassName = asModuleTheme(appropriateModule);
  const displayDate = new Date(created_at);
  const targetHref = primary_url ?? parts.find((part) => part.url)?.url;

  const [fetchV2Identifier, results] = useLazyGetV2IdentifierQuery();
  const [urlConversionResult, setConversionResult] = useState<ConversionResult | undefined>(undefined);

  const [convertedHref, setConvertedHref] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (
      results.data &&
      !results.isFetching &&
      urlConversionResult &&
      urlConversionResult.status === "pending-id-conversion"
    ) {
      setConvertedHref(urlConversionResult.locationWithId(results.data));
    }
  }, [urlConversionResult, results]);

  useEffect(() => {
    if (!targetHref) {
      return;
    }
    try {
      const conversion = iframeToReactUrl(targetHref);

      if (conversion.status === "no-known-conversions") {
        setConvertedHref(targetHref);

        return;
      }

      if (conversion.status === "pending-id-conversion") {
        void fetchV2Identifier(conversion.queryArguments);
        setConversionResult(conversion);

        return;
      }

      setConvertedHref(conversion.location);
    } catch (e) {
      console.error(e);
      console.trace();
      setConvertedHref(undefined);
    }
  }, [fetchV2Identifier, targetHref]);

  const Wrapper = forwardRef(function Wrapper(
    { children, className }: PropsWithChildren<{ className: string }>,
    ref: ForwardedRef<HTMLDivElement>,
  ) {
    return (
      <div ref={ref} data-testid={`link-${testId}`}>
        {convertedHref ? (
          <Link to={convertedHref} className={className}>
            {children}
          </Link>
        ) : (
          <div className={className}>{children}</div>
        )}
      </div>
    );
  });

  return (
    <ListItem className={moduleClassName} testId={testId}>
      <Wrapper className={classNames(styles.notification, is_read && styles.read)}>
        <div className={styles.moduleIndicator}>
          <ModuleIcon module={appropriateModule} />
        </div>
        <p className={styles.message}>
          {parts.map((part) =>
            part.isReference ? (
              <b key={part.message} className={styles.bold}>
                {part.message}
              </b>
            ) : (
              <span key={part.message}>{part.message}</span>
            ),
          )}
        </p>
        <div className={styles.dateLabel} title={formatDate(displayDate, DATE_FORMAT)}>
          {format(displayDate)}
        </div>
      </Wrapper>
    </ListItem>
  );
});
