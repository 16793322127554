import { simplicateApiV1 } from "../components";

export type JournalizeDetails = {
  active: boolean;
  count: number;
};

const endpoints = simplicateApiV1.injectEndpoints({
  endpoints: (builder) => ({
    getJournalizeDetails: builder.query<{ data: JournalizeDetails }, void>({
      query: () => ({
        url: "/v1/environmentmanagement/internal/journalizeDetails",
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetJournalizeDetailsQuery } = endpoints;
