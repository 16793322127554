import { useTranslation } from "@simplicate/translations";
import { Datepicker } from "@simplicate/ui";
import { memo } from "react";

type InvoiceableFromDatepickerProps = {
  value: Date | undefined;
  error: string | undefined;
  touched?: boolean;
  onChange: (date: Date | undefined) => void;
  disabled?: boolean;
};

export const InvoiceableFromDatepicker = memo(function InvoiceableFromDatepicker({
  value,
  error,
  onChange,
  touched = false,
  disabled = false,
}: InvoiceableFromDatepickerProps) {
  const { t } = useTranslation("project_services");

  return (
    <Datepicker
      name="invoiceableFrom"
      testId="invoiceable-from-datepicker"
      label={t("invoiceable_from")}
      value={value}
      onChange={onChange}
      invalid={touched && error !== undefined}
      disabled={disabled}
      showButtonBar
    />
  );
});
