/* istanbul ignore file -- this is a thin wrapper around a dependency component */
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer as ToastifyContainer, Slide } from "react-toastify";
import styles from "./ToastContainer.module.scss";

const DEFAULT_AUTO_CLOSE = 5_000;
const DEFAULT_NOTIFICATION_LIMIT = 5;

export const ToastContainer = () => {
  return (
    <ToastifyContainer
      autoClose={DEFAULT_AUTO_CLOSE}
      className={styles.toastContainer}
      closeButton={false}
      closeOnClick={false}
      draggable
      draggablePercent={50}
      hideProgressBar
      limit={DEFAULT_NOTIFICATION_LIMIT}
      position="top-right"
      transition={Slide}
    />
  );
};
