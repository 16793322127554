import { useTranslation } from "@simplicate/translations";
import { Tab, TabBar } from "@simplicate/ui";
import { useParams } from "react-router-dom";
import { IFrameActionButtons } from "../../../IFrameActionButtons";

export const ProjectPurchaseDetailsPageTabs = () => {
  const { t } = useTranslation("tab_bar");

  const { id } = useParams<{ id: string }>();

  return (
    <TabBar testId="project-purchase-detail-page-tabs" actions={<IFrameActionButtons />}>
      <Tab href={`/projects/purchasing/${id}/purchase`} title={t("add-project-purchase")} />
    </TabBar>
  );
};
