import { usePermission } from "@simplicate/api-client";
import { useAppSelector } from "@simplicate/state";
import { useTranslation } from "@simplicate/translations";
import { TabBar, Tab, Counter } from "@simplicate/ui";
import { lightColorTextWarning } from "@simplicate-software/design-tokens";
import { IFrameActionButtons } from "../../../IFrameActionButtons";
import { useProjectPurchaseTabPermissions } from "../useProjectPurchaseTabPermissions";
import { useProjectReverseBillingTabPermissions } from "../useProjectReverseBillingTabPermissions";

const PROJECT_PURCHASE_TAB_BADGE_KEY = "GridProjectsPurchase";

export const ProjectsTabs = () => {
  const { t } = useTranslation("tab_bar");

  const tabBadges = useAppSelector(/* istanbul ignore next */ ({ state }) => state.iframe.tabBadges);
  const { showProjectPurchaseTab, disableProjectPurchaseTab } = useProjectPurchaseTabPermissions();
  const { showReverseBillingTab, disableReverseBillingTab } = useProjectReverseBillingTabPermissions();
  const hasProjectsViewPermission = usePermission("projects", "view").granted;

  if (!hasProjectsViewPermission) {
    return null;
  }

  const badgeValue = tabBadges[PROJECT_PURCHASE_TAB_BADGE_KEY];

  return (
    <TabBar testId="projects" actions={<IFrameActionButtons />}>
      <Tab href="/projects/active" title={t("active-projects")} />
      <Tab href="/projects/closed" title={t("closed-projects")} />
      <Tab href="/projects/all" title={t("all-projects")} />
      {showProjectPurchaseTab && (
        <Tab
          href="/projects/purchasing"
          title={t("purchasing")}
          disabled={disableProjectPurchaseTab}
          disabledText={t("not_available_in_license_message")}
          badge={badgeValue ? <Counter value={badgeValue} background={lightColorTextWarning} /> : null}
        />
      )}
      {showReverseBillingTab && (
        <Tab
          href="/projects/reverse-billing"
          title={t("reverse-billing")}
          disabled={disableReverseBillingTab}
          disabledText={t("not_available_in_license_message")}
        />
      )}
    </TabBar>
  );
};
