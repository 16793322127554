import { simplicateApiV2 } from "../components";
import { Response } from "./types";
import { writeToSessionStorage } from "./utils";

export type FeatureFlag = {
  is_enabled: boolean;
  identifier: string;
  name: string;
};

const endpoints = simplicateApiV2.injectEndpoints({
  endpoints: (builder) => ({
    getFeatureFlags: builder.query<Response<FeatureFlag[]>, void>({
      query: () => ({
        url: "/application/feature",
        responseHandler: writeToSessionStorage<FeatureFlag[]>("featureFlags"),
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetFeatureFlagsQuery } = endpoints;
