/* c8 ignore start -- this file contains no logic */
/* istanbul ignore file -- this file contains no logic */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const simplicateApiV1 = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  endpoints: () => ({}),
  reducerPath: "apiV1",
});
/* c8 ignore stop */
