import { ConversionRecord } from "../types";
import { extractFragment } from "./utils";

export const HoursRouteConversions: ConversionRecord = {
  "/v1/hours": (location) => {
    const fragment = extractFragment(location);

    switch (fragment) {
      case "#agenda":
        return { status: "complete", location: "/hours/agenda" };

      case "#register_hours":
        return { status: "complete", location: "/hours/timesheet" };

      case "#registermileage":
        return { status: "complete", location: "/hours/mileage" };

      case "#registercosts":
        return { status: "complete", location: "/hours/declarations" };

      case "#projectapproval":
        return { status: "complete", location: "/hours/project" };

      case "#rapportage":
        return { status: "complete", location: "/hours/reporting" };

      default:
        return { status: "complete", location: "/hours/register" };
    }
  },
};
