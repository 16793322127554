import classNames from "classnames";
import { PropsWithChildren, Children } from "react";
import styles from "./Footer.module.scss";

type FooterRowProps = PropsWithChildren<{
  variant?: "primary" | "secondary";
  className?: string;
  cellClassName?: string;
  testId?: string;
}>;

export const Footer = ({ children, variant = "primary", className, cellClassName, testId }: FooterRowProps) => {
  return (
    <div className={classNames(styles.footer, styles[variant], className)} data-testid={testId}>
      {Children.map(children, (child) => {
        return <div className={classNames(styles.footerCell, cellClassName)}>{child}</div>;
      })}
    </div>
  );
};
