import { FeatureFlagSwitch, useFeatureFlag } from "@simplicate/api-client";
import { HoursReportPage, IndexPage } from "@simplicate/insights";
import { Navigate, Outlet } from "react-router-dom";
import { useIsAdministrator } from "../../components/MainMenu/Insights/useIsAdministrator";
import { RouteObject } from "../types";

const PermissionGuard = () => {
  const { isAdministrator, isLoading } = useIsAdministrator();
  const { enabled: endUserHasAccess, isFetching } = useFeatureFlag("insights-end-user-access");

  if (isLoading || isFetching) {
    return null;
  }

  if (!isAdministrator && !endUserHasAccess) {
    return <Navigate to="/404" replace />;
  }

  return <Outlet />;
};

export const insightsRoutes: RouteObject[] = [
  {
    path: "insights",
    element: <PermissionGuard />,
    children: [
      {
        index: true,
        element: (
          <FeatureFlagSwitch featureFlag="insights" ifEnabled={<IndexPage />} ifDisabled={<Navigate to="/404" />} />
        ),
      },
      {
        path: "hours-reporting",
        element: (
          <FeatureFlagSwitch
            featureFlag="insights"
            ifEnabled={<HoursReportPage />}
            ifDisabled={<Navigate to="/404" />}
          />
        ),
        handle: {
          backNavigationPath: "/insights",
          // TODO: fix the bug causing this necessity. See SIM-37919
          headerTitle: undefined,
          permissions: [{ module_key: "hours", right_key: "hoursrapportage_all" }],
        },
      },
    ],
    handle: {
      theme: "insights",
      headerTitle: "module_insights",
      permissions: [
        { module_key: "hrm", right_key: "rapportage" },
        { module_key: "acquisition", right_key: "rapportage" },
        { module_key: "hours", right_key: "hoursrapportage_all" },
      ],
    },
  },
];
