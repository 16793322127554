import { simplicateApiV2 } from "../components";
import { Response } from "./types";

export type Avatar = {
  color: string;
  full_name?: string;
  id?: string;
  initials: string;
  url_small?: string;
  url_large?: string;
};

type AvatarQueryParams = {
  id?: string;
};

const endpoints = simplicateApiV2.injectEndpoints({
  endpoints: (builder) => ({
    getAvatar: builder.query<Response<Avatar[]>, AvatarQueryParams>({
      query: (params) => `/hrm/avatar?q[id][in]=${params.id}`,
    }),
  }),
});

export const { useGetAvatarQuery } = endpoints;
