/* istanbul ignore file -- All of this is store config */
import { configureStore } from "@reduxjs/toolkit";
import { simplicateApiV3, simplicateApiV2, simplicateApiV1, setupListeners } from "@simplicate/api-client";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { persistStore } from "redux-persist";
import { RootState, rootReducer } from "./slices";

export const store = configureStore({
  reducer: {
    [simplicateApiV3.reducerPath]: simplicateApiV3.reducer,
    [simplicateApiV1.reducerPath]: simplicateApiV1.reducer,
    [simplicateApiV2.reducerPath]: simplicateApiV2.reducer,
    ...rootReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(simplicateApiV3.middleware, simplicateApiV2.middleware, simplicateApiV1.middleware),
});

// Typed selectors and dispatch for use in components
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const persistor = persistStore(store);

// Setup window focus listeners for API polling
setupListeners(store.dispatch);
