import { IntercomSettings, useGetIntercomSettingsQuery } from "@simplicate/api-client";
import { PropsWithChildren } from "react";
import { IntercomProvider } from "react-use-intercom";

export const buildProps = (intercomSettings?: IntercomSettings) => {
  const isAvailable = intercomSettings?.is_enabled === true && intercomSettings.settings.app_id.length > 0;

  return isAvailable
    ? {
        appId: intercomSettings.settings.app_id,
        autoBoot: true,
        shouldInitialize: true,
        autoBootProps: {
          userId: intercomSettings.settings.user_id,
          name: intercomSettings.settings.name,
          email: intercomSettings.settings.email,
          userHash: intercomSettings.settings.user_hash,
        },
      }
    : {
        appId: "",
        autoBoot: false,
        shouldInitialize: false,
      };
};

/* c8 ignore start */
/* istanbul ignore next */
export const SimplicateIntercomProvider = ({ children }: PropsWithChildren) => {
  const { data: intercomSettings } = useGetIntercomSettingsQuery();
  const intercomProviderProps = buildProps(intercomSettings);

  return <IntercomProvider {...intercomProviderProps}>{children}</IntercomProvider>;
};
