import { useGetLoggedInQuery } from "@simplicate/api-client";
import { showToast } from "@simplicate/ui";
import { differenceInMilliseconds, differenceInMinutes } from "date-fns";
import { PropsWithChildren, useEffect, useState } from "react";
import { setWindowLocation } from "../../routing/utils";
import { useErrorBoundaryContext } from "../ErrorBoundary";
import { SessionExpiryNotification } from "./SessionExpiryNotification";

const POLLING_INTERVAL = 5 * 60 * 1000; // 5 minutes
const SESSION_ABOUT_TO_EXPIRE_TIME_THRESHOLD = 15;
const LOGOUT_URL = "/site/logout";
const LOGOUT_EVENT = "logout";

export const LoggedInGuard = ({ children }: PropsWithChildren) => {
  const { reportEvent } = useErrorBoundaryContext();
  const [didShowToast, setDidShowToast] = useState(false);
  const { data, isLoading, isError } = useGetLoggedInQuery(undefined, {
    pollingInterval: POLLING_INTERVAL,
    refetchOnReconnect: true,
  });

  const timeRemaining = data?.expiration_date
    ? differenceInMinutes(new Date(data.expiration_date), new Date())
    : undefined;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!isError && data?.type === "guest") {
      reportEvent(LOGOUT_EVENT, {
        reason: "API yielded a 'you are not logged in' result",
        userType: data?.type,
        causedByError: isError,
      });
      setWindowLocation(LOGOUT_URL);
    }
  }, [data?.type, isError, isLoading, reportEvent]);

  useEffect(() => {
    if (
      !didShowToast &&
      data?.expiration_date &&
      timeRemaining !== undefined &&
      timeRemaining <= SESSION_ABOUT_TO_EXPIRE_TIME_THRESHOLD
    ) {
      const logOutNow = (reason: string) => {
        reportEvent(LOGOUT_EVENT, {
          reason,
          expirationDate: data.expiration_date,
          loggedOutAt: new Date().toISOString(),
        });
        setWindowLocation(LOGOUT_URL);
      };

      showToast({
        content: (
          <SessionExpiryNotification
            expirationDate={new Date(data.expiration_date).valueOf()}
            onLogout={() => logOutNow("User clicked 'logout now' button in session expiry notification")}
          />
        ),
        type: "info",
        options: {
          dismissible: false,
        },
      });
      setDidShowToast(true);

      setTimeout(
        () => logOutNow("Session expired"),
        differenceInMilliseconds(new Date(data.expiration_date), new Date()),
      );
    }
  }, [data?.expiration_date, data?.type, didShowToast, isError, reportEvent, timeRemaining]);

  if (isLoading || data?.type !== "user") {
    return null;
  }

  return children;
};
