import { PropsWithChildren, ReactNode } from "react";
import { Spinner } from "../Spinner";
import styles from "./List.module.scss";
import { ListItem } from "./ListItem";

type ListProps = PropsWithChildren<{
  emptyComponent?: ReactNode;
  isEmpty?: boolean;
  isLoading?: boolean;
  testId?: string;
}>;

export const List = ({ children, emptyComponent, isEmpty = false, isLoading = false, testId }: ListProps) => {
  if (isEmpty && emptyComponent) {
    return emptyComponent;
  }

  return (
    <ul className={styles.list} data-testid={testId}>
      {children}
      {isLoading && (
        <ListItem className={styles.loaderContainer}>
          <Spinner testId={`${testId}-spinner`} />
        </ListItem>
      )}
    </ul>
  );
};
