import { setupListeners as setupReduxListeners } from "@reduxjs/toolkit/query";

// Used to check if the document has focus -- make sure this is a sensible amount of seconds. The maximum value of which
// should be an acceptable wait time for polling on endpoints to resume. I.e. when the user refocuses the document, this
// triggers the focus event to be dispatches, which immediately resumes all paused pollable endpoints, triggering a
// network request for each one immediately.
const INTERVAL_TIMER = 6 * 1_000;

let initialized = false;

function initialiseFocusListeners(handleFocus: () => void, handleFocusLost: () => void) {
  let previousHasFocus: boolean | undefined = undefined;

  const interval = setInterval(() => {
    const hasFocus = document.hasFocus();

    if (previousHasFocus !== hasFocus) {
      if (hasFocus) {
        handleFocus();
      } else {
        handleFocusLost();
      }

      previousHasFocus = hasFocus;
    }
  }, INTERVAL_TIMER);

  return /* istanbul ignore next -- this is never called in production code */ () => {
    clearInterval(interval);
    previousHasFocus = false;
  };
}

function setupNetworkListeners(handleOnline: () => void, handleOffline: () => void) {
  window.addEventListener("online", handleOnline);
  window.addEventListener("offline", handleOffline);

  return /* istanbul ignore next -- this is never called in production code */ () => {
    window.removeEventListener("online", handleOnline);
    window.removeEventListener("offline", handleOffline);
  };
}

export function setupListeners(dispatch: Parameters<typeof setupReduxListeners>[0]): () => void {
  return setupReduxListeners(dispatch, (dispatch, actions) => {
    let clearFocusListeners: () => void;
    let clearNetworkListeners: () => void;

    if (!initialized) {
      clearFocusListeners = initialiseFocusListeners(
        () => dispatch(actions.onFocus()),
        () => dispatch(actions.onFocusLost()),
      );

      clearNetworkListeners = setupNetworkListeners(
        () => dispatch(actions.onOnline()),
        () => dispatch(actions.onOffline()),
      );

      initialized = true;
    }

    return /* istanbul ignore next -- this is never called in production code */ () => {
      clearFocusListeners?.();
      clearNetworkListeners?.();

      initialized = false;
    };
  });
}
