export const MODULES = [
  "business-intelligence",
  "crm",
  "dashboard",
  "environment-management",
  "hours",
  "hrm",
  "insights",
  "invoicing",
  "projects",
  "resource-planner",
  "sales",
] as const;

export type Module = (typeof MODULES)[number];
