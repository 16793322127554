import { useFeatureFlag } from "@simplicate/api-client";
import { useEffect } from "react";
import { useErrorBoundaryContext } from "./ErrorBoundary.context";

export const ErrorBoundaryActivator = () => {
  const { enableErrorReporting } = useErrorBoundaryContext();
  const errorTrackingEnabled = useFeatureFlag("system-error-reporting-browser-datadog").enabled;

  useEffect(() => {
    if (errorTrackingEnabled) {
      enableErrorReporting();
    }
  }, [enableErrorReporting, errorTrackingEnabled]);

  return null;
};
