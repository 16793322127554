import { usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { TabBar, Tab } from "@simplicate/ui";
import { useParams } from "react-router-dom";
import { IFrameActionButtons } from "../../IFrameActionButtons";

export const ToBeInvoicedTabs = () => {
  const { t } = useTranslation("tab_bar");
  const { id } = useParams<{ id: string }>();
  const hasViewProjectsPermission = usePermission("projects", "view").granted;

  if (!id || !hasViewProjectsPermission) {
    return;
  }

  return (
    <TabBar testId="to-be-invoiced" actions={<IFrameActionButtons />}>
      <Tab href={`/invoicing/${id}/ready`} title={t("ready")} />
      <Tab href={`/invoicing/${id}/not-ready`} title={t("not-ready")} />
    </TabBar>
  );
};
