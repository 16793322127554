import { MessageBus } from "@simplicate/utils";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useNavigationDispatch = () => {
  const location = useLocation();

  useEffect(() => {
    MessageBus.publish("navigation", { path: location.pathname, origin: "react" });

    return MessageBus.subscribe("mounted", (payload) => {
      // To load the correct page when the application is first loaded, let the
      // web component know the current path as soon as it is mounted.
      /* istanbul ignore next -- payload is forcibly shaped to accept no other values */
      if (payload.origin === "web-component") {
        MessageBus.publish("navigation", { path: location.pathname, origin: "react" });
      }
    });
  }, [location.pathname]);
};
