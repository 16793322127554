import { FeatureFlagSwitch } from "@simplicate/api-client";
import { ProjectServicePage } from "@simplicate/pages";
import { Navigate } from "react-router-dom";
import { IFrameEnvironment, LegacyWebComponent } from "../../components";
import {
  ProjectDetailsPageTabs,
  ProjectDetailsServicePageTabs,
  ProjectPurchaseDetailsPageTabs,
  ProjectsTabs,
} from "../../components/IFrameEnvironment/Tabs";
import { ProjectSubHeader } from "../../components/NavigationWrapper/SubHeaders";
import { RouteObject } from "../types";
import { followBackendRedirect, StandardBackButtonButtonBehavior } from "../utils";

export const projectsRoutes: RouteObject<"projects">[] = [
  {
    path: "projects",
    handle: {
      headerTitle: "module_projects",
      theme: "projects",
      permissions: [
        {
          module_key: "projects",
          right_key: "view",
        },
      ],
    },
    children: [
      {
        index: true,
        element: <Navigate to="active" replace />,
      },
      {
        path: "active",
        element: <IFrameEnvironment src="/v1/projects" />,
        handle: { tabsComponent: ProjectsTabs },
      },
      {
        path: "closed",
        element: <IFrameEnvironment src="/v1/projects?tab=closed" />,
        handle: { tabsComponent: ProjectsTabs },
      },
      {
        path: "all",
        element: <IFrameEnvironment src="/v1/projects?tab=all" />,
        handle: { tabsComponent: ProjectsTabs },
      },
      {
        path: "purchasing",
        handle: {
          permissions: [
            {
              module_key: "projects",
              right_key: "projectpurchase",
            },
          ],
        },
        children: [
          {
            index: true,
            element: <IFrameEnvironment src="/v1/projects?tab=purchase" />,
            handle: { tabsComponent: ProjectsTabs },
          },
          {
            path: ":id",
            handle: {
              tabsComponent: ProjectPurchaseDetailsPageTabs,
              backNavigationPath: "/projects/purchasing",
            },
            children: [
              {
                index: true,
                element: <Navigate to="purchase" replace />,
              },
              {
                path: "purchase",
                element: <IFrameEnvironment src="/v1/projects/purchase/view?id=:id" />,
                handle: { tabsComponent: ProjectPurchaseDetailsPageTabs },
              },
              {
                path: "placefile",
                element: (
                  <IFrameEnvironment
                    src="/v1/projects/purchase/placefile?id=:id"
                    expectedRedirect="/projects/purchasing/:id/purchase"
                  />
                ),
                handle: { tabsComponent: ProjectPurchaseDetailsPageTabs },
              },
              {
                path: "deleteFile",
                element: (
                  <IFrameEnvironment
                    src="/v1/projects/purchase/deleteFile?id=:id"
                    expectedRedirect="/projects/purchasing/:id/purchase"
                  />
                ),
                handle: { tabsComponent: ProjectPurchaseDetailsPageTabs },
              },
            ],
          },
        ],
      },
      {
        path: "reverse-billing",
        children: [
          {
            index: true,
            element: <IFrameEnvironment src="/v1/projects?tab=reverse_billing" />,
            handle: { tabsComponent: ProjectsTabs },
          },
          {
            path: ":id",
            element: <IFrameEnvironment src="/v1/projects/reversebilling/view?id=:id" />,
            handle: {
              tabsComponent: ProjectsTabs,
              backNavigationPath: "/projects/reverse-billing",
            },
          },
        ],
      },
      {
        path: "reporting",
        handle: {
          permissions: [
            {
              module_key: "projects",
              right_key: "rapportage",
            },
          ],
        },
        children: [
          {
            index: true,
            element: <Navigate to="revenue" replace />,
          },
          {
            path: "revenue",
            element: <IFrameEnvironment src="/v1/reporting/revenue" remountOnSearchChange={false} />,
            handle: {
              headerTitle: "projects_reporting_revenue",
            },
          },
          {
            path: "result",
            element: <IFrameEnvironment src="/v1/reporting/process" remountOnSearchChange={false} />,
            handle: {
              headerTitle: "projects_reporting_process",
            },
          },
          {
            path: "forecasted-invoiced",
            element: <IFrameEnvironment src="/v1/reporting/combined" />,
            handle: {
              headerTitle: "projects_reporting_combined",
            },
          },
        ],
      },
      {
        path: ":id",
        children: [
          {
            index: true,
            element: <Navigate to="services" replace />,
            handle: {
              tabsComponent: ProjectDetailsPageTabs,
              subHeaderComponent: ProjectSubHeader,
              backNavigationPath: "/projects/active",
            },
          },
          {
            path: "general",
            element: <IFrameEnvironment src="/v1/projects/project/view?id=:id&tab=general" />,
            handle: {
              tabsComponent: ProjectDetailsPageTabs,
              backNavigationPath: "/projects/active",
              subHeaderComponent: ProjectSubHeader,
            },
          },
          {
            path: "services",
            handle: { backNavigationPath: "/projects/active" },
            children: [
              {
                index: true,
                element: <IFrameEnvironment src="/v1/projects/project/view?id=:id&tab=services" />,
                handle: {
                  tabsComponent: ProjectDetailsServicePageTabs,
                  backNavigationPath: "/projects/active",
                  subHeaderComponent: ProjectSubHeader,
                },
              },
              {
                path: "addservice",
                element: (
                  <FeatureFlagSwitch
                    featureFlag="projects-new-service-ui"
                    ifEnabled={<ProjectServicePage />}
                    ifDisabled={<Navigate to="/projects/active" replace />}
                  />
                ),
                handle: {
                  backNavigationPath: StandardBackButtonButtonBehavior,
                  subHeaderComponent: ProjectSubHeader,
                },
              },
            ],
          },
          {
            path: "planning",
            handle: {
              tabsComponent: ProjectDetailsPageTabs,
              backNavigationPath: "/projects/active",
              subHeaderComponent: ProjectSubHeader,
            },
            children: [
              {
                index: true,
                element: <LegacyWebComponent />,
              },
              {
                path: ":serviceId",
                children: [
                  {
                    index: true,
                    element: <LegacyWebComponent />,
                  },
                  {
                    path: ":hoursTypeId",
                    element: <LegacyWebComponent />,
                  },
                ],
              },
            ],
          },
          {
            path: "installments",
            element: <IFrameEnvironment src="/v1/projects/project/view?id=:id&tab=terms" />,
            handle: {
              tabsComponent: ProjectDetailsPageTabs,
              backNavigationPath: "/projects/active",
              subHeaderComponent: ProjectSubHeader,
            },
          },
          {
            path: "hours",
            element: <IFrameEnvironment src="/v1/projects/project/view?id=:id&tab=hours_cost" />,
            handle: {
              tabsComponent: ProjectDetailsPageTabs,
              backNavigationPath: "/projects/active",
              subHeaderComponent: ProjectSubHeader,
            },
          },
          {
            path: "costs",
            element: <IFrameEnvironment src="/v1/projects/project/view?id=:id&tab=purchase_cost" />,
            handle: {
              tabsComponent: ProjectDetailsPageTabs,
              backNavigationPath: "/projects/active",
              subHeaderComponent: ProjectSubHeader,
            },
          },
          {
            path: "reverse-billing",
            element: <IFrameEnvironment src="/v1/projects/project/view?id=:id&tab=reverse_billing" />,
            handle: {
              tabsComponent: ProjectDetailsPageTabs,
              backNavigationPath: "/projects/active",
              subHeaderComponent: ProjectSubHeader,
            },
          },
          {
            path: "invoices",
            element: <IFrameEnvironment src="/v1/projects/project/view?id=:id&tab=invoices" />,
            handle: {
              tabsComponent: ProjectDetailsPageTabs,
              backNavigationPath: "/projects/active",
              subHeaderComponent: ProjectSubHeader,
            },
          },
          {
            path: "overview",
            element: <IFrameEnvironment src="/v1/projects/project/view?id=:id&tab=overview" />,
            handle: {
              tabsComponent: ProjectDetailsPageTabs,
              backNavigationPath: "/projects/active",
              subHeaderComponent: ProjectSubHeader,
            },
          },
          {
            path: "tasks",
            element: <IFrameEnvironment src="/v1/projects/project/view?id=:id&tab=tasks" />,
            handle: {
              tabsComponent: ProjectDetailsPageTabs,
              backNavigationPath: "/projects/active",
              subHeaderComponent: ProjectSubHeader,
            },
          },
          {
            path: "documents",
            element: <IFrameEnvironment src="/v1/projects/project/view?id=:id&tab=dossier" />,
            handle: {
              tabsComponent: ProjectDetailsPageTabs,
              backNavigationPath: "/projects/active",
              subHeaderComponent: ProjectSubHeader,
            },
          },
          {
            path: "timeline",
            element: <IFrameEnvironment src="/v1/projects/project/view?id=:id&tab=time_line" />,
            handle: {
              tabsComponent: ProjectDetailsPageTabs,
              backNavigationPath: "/projects/active",
              subHeaderComponent: ProjectSubHeader,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/v1/projects",
    loader: followBackendRedirect,
    element: null,
  },
];
