import { HEADER_DATA_TAG, simplicateApiV2 } from "../components";
import { Response } from "./types";

export const DRAFT_INVOICE_STATUS_ID = "invoicestatus:b313fe5974eca456";

export type Invoice = {
  id: string;
  contact_id: string;
  invoice_number?: string;
  status: {
    id: string;
    name: string;
  };
  organization?: {
    id: string;
    name: string;
    relation_number: string;
  };
  person?: {
    id: string;
    full_name: string;
    relation_number: string;
  };
  projects: {
    id: string;
    name: string;
    project_number: string;
    project_manager: {
      id: string;
      person_id: string;
      name: string;
    };
  }[];
  my_organization_profile_id: string;
  organization_id: string;
  person_id: string;
  date: string;
  subject: string;
  reference: string;
  project_id: string;
  composition_type: string;
  total_excluding_vat: number;
  total_including_vat: number;
  total_vat: number;
  total_outstanding: number;
};

export type Proposition = {
  id: string;
  project: {
    id: string;
    name: string;
    project_number: string;
    organization?: {
      id: string;
      name: string;
      relation_number: string;
    };
    person?: {
      id: string;
      full_name: string;
      relation_number: string;
    };
  };

  updated_at: string;
  created_at: string;
};

const endpoints = simplicateApiV2.injectEndpoints({
  endpoints: (builder) => ({
    getInvoice: builder.query<Response<Invoice>, string>({
      query: (id) => ({
        url: `/invoices/invoice/${id}`,
      }),
      providesTags: [HEADER_DATA_TAG],
    }),
    getReverseInvoiceForInvoice: builder.query<Response<{ id: string }[]>, string>({
      query: (invoiceId) => ({
        url: `/projects/reverseinvoice?q[invoice.id]=${invoiceId}`,
      }),
    }),
    getPropositionList: builder.query<Response<Proposition[]>, void>({
      query: () => ({
        url: "/invoices/proposition",
      }),
    }),
    getProposition: builder.query<Response<Proposition>, string>({
      query: (id) => ({
        url: `/invoices/proposition/${id}`,
      }),
      providesTags: [HEADER_DATA_TAG],
    }),
  }),
});

export const {
  useGetInvoiceQuery,
  useGetReverseInvoiceForInvoiceQuery,
  useGetPropositionListQuery,
  useGetPropositionQuery,
} = endpoints;
