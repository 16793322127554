export class History {
  private _history: string[];
  private _index: number;
  private _navigate: (delta: number) => void;

  constructor(navigate: (delta: number) => void) {
    this._history = [];
    this._index = -1;
    this._navigate = navigate;
  }

  /**
   * Sets the current route key in the history. If the key is already in the history, the key is not added again;
   * instead the history will point to the index of the provided key.
   *
   * @param key the key of the current route, probably generated by react-router-dom.
   */
  set current(key: string) {
    const indexOfKey = this._history.indexOf(key);

    if (indexOfKey !== -1) {
      this._index = indexOfKey;

      return;
    }

    this._index++;

    if (this._index === this._history.length) {
      this._history.push(key);
    } else if (this._index > this._history.length) {
      throw new Error("Index out of bounds");
    } else {
      this._history = [...this._history.slice(0, this._index), key];
    }
  }

  get current(): string {
    if (this._index < 0 || this._index >= this._history.length) {
      throw new Error("Index out of bounds");
    }

    // Assert existence. Should be safe since index is checked for being within bounds.
    return this._history.at(this._index)!;
  }

  /**
   * Determines the amount of history entries between the target and the reference keys.
   *
   * @returns A negative integer when the target is earlier in the history than the reference, a positive integer when
   * the reference is earlier in the history than the target, and 0 when the keys are the same.
   */
  private diff(target: string, current: string) {
    const targetIndex = this._history.indexOf(target);
    const currentIndex = this._history.indexOf(current);

    if (targetIndex === -1 || currentIndex === -1) {
      return -1;
    }

    return -1 * (currentIndex - targetIndex);
  }

  /**
   * After a timeout, will instruct the browser to navigate backwards or forwards by as many history entries as needed
   * to ensure we end up at the target key.
   *
   * @param key the key of the history entry to navigate to.
   */
  goto(key: string) {
    if (!this._history.includes(key)) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- react-router-dom adds this to the state
    const reference = window.history.state?.key as string;

    if (reference === undefined || reference === key) {
      return;
    }

    const diff = this.diff(key, reference);

    this._navigate(diff);
  }
}
