import { useAppSelector, usePageTitle } from "@simplicate/state";
import { PropsWithChildren } from "react";
import styles from "./PageHeader.module.scss";

type SubHeaderProps = PropsWithChildren<{
  title: string;
  testId?: string;
}>;

export const PageHeader = ({ title, testId, children }: SubHeaderProps) => {
  usePageTitle(title);

  const { title: displaytitle } = useAppSelector(/* istanbul ignore next */ ({ state }) => state.header);

  return (
    <div className={styles.container} data-testid={testId}>
      <h1 className={styles.title}>{displaytitle}</h1>
      <div className={styles.tags}>{children}</div>
    </div>
  );
};
