import { useEnvironmentSetting, useFeatureFlag, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";

const HOURS_MODES: (string | undefined)[] = ["grid", "both"];

/**
 * @deprecated The page this menu item points to has long been replaced. This menu item only exists for backwards compatibility.
 */
export const TimesheetMenuItem = () => {
  const { t } = useTranslation("main_menu");

  // This feature flag should hide the tab when enabled.
  const showMenuItem = useFeatureFlag("hours-hide-tab-register-hours-timesheet-old").enabled === false;

  const hasApproveAsSupervisorPermission = usePermission("hours", "approve_as_supervisor").granted;
  const hasGeneralApprovalPermission = usePermission("hours", "hours_approval").granted;
  const hasEitherRequiredPermission = hasApproveAsSupervisorPermission || hasGeneralApprovalPermission;

  const configuredRegistrationMode = useEnvironmentSetting("hours", "hours_view")?.value;

  if (!showMenuItem || !hasEitherRequiredPermission || !HOURS_MODES.includes(configuredRegistrationMode)) {
    return null;
  }

  return (
    <NavMenu.SubItem href="/hours/timesheet" title={t("legacy_hours_timesheet")} testId="hours-timesheet-menu-item" />
  );
};
