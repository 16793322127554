import { MessageBus } from "@simplicate/utils";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function stripOriginFromUrl(url: string) {
  return url.replace(window.location.origin, "");
}

export const useWebComponentNavigationEffect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    return MessageBus.subscribe("navigation", (payload) => {
      if (payload.origin === "web-component") {
        navigate(stripOriginFromUrl(payload.path));
      }
    });
  }, [navigate]);
};
