import { useEnvironmentSetting, useLicenseGuard, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";

export const HoursReportingMenuItem = () => {
  const { t } = useTranslation("main_menu");
  const { enabled } = useLicenseGuard("hours_reporting");
  const hasReportingPermission = usePermission("hours", "hoursrapportage").granted;

  // TODO: Remove this setting guard. See https://simplicate.atlassian.net/browse/SIM-35562
  const projectsModuleEnabled = useEnvironmentSetting("environment", "active_modules")?.value?.includes("projects");

  if ((enabled && !hasReportingPermission) || !projectsModuleEnabled) {
    return null;
  }

  return <NavMenu.SubItem href="/hours/reporting" title={t("hours_reporting")} testId="hours-reporting-menu-item" />;
};
