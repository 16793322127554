import { Big } from "@simplicate/api-client";
import { useNumericFormatter } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { Button, Alert } from "@simplicate/ui";
import styles from "./HourTypeTotalsInfo.module.scss";
import type { HourTypeInForm, MoneyInForm } from "../../../types";

type HourTypesTotalsInfo = {
  hourTypes?: HourTypeInForm[];
  specifiedTotal?: MoneyInForm;
  setSpecifiedTotal: (newTotal: Big) => void;
};

export const HourTypesTotalsInfo = ({ hourTypes = [], specifiedTotal, setSpecifiedTotal }: HourTypesTotalsInfo) => {
  const { t } = useTranslation("project_services");
  const calculatedTotal = hourTypes.reduce(
    (calculatedTotal, current) => calculatedTotal.add(current.total.amount ?? 0),
    Big(0),
  );

  const formattedCalculatedTotal = useNumericFormatter(calculatedTotal?.toString());

  if (!specifiedTotal?.amount) {
    return;
  }

  if (calculatedTotal.cmp(specifiedTotal.amount) === 0) {
    return;
  }

  return (
    <div className={styles.alertContainer} data-testid="hour-types-totals-info">
      <Alert type="info">
        <span>
          {t("specified_total_differs_from_calculated_{{value}}", {
            value: formattedCalculatedTotal,
          })}
        </span>
        <Button
          data-testid="reset-specified-total-button"
          text={t("reset_specified_total")}
          name="reset_specified_total"
          onClick={() => setSpecifiedTotal(calculatedTotal)}
          variant="subtle"
          type="button"
        />
      </Alert>
    </div>
  );
};
