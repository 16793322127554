import { useTranslation } from "@simplicate/translations";
import { TabBar, Tab } from "@simplicate/ui";
import { IFrameActionButtons } from "../../IFrameActionButtons";

export const UserTabs = () => {
  const { t } = useTranslation("tab_bar");

  return (
    <TabBar testId="dashboard" actions={<IFrameActionButtons />}>
      <Tab href="/dashboard/user/basic-info" title={t("basic-info")} />
      <Tab href="/dashboard/user/2fa" title={t("2fa")} />
      <Tab href="/dashboard/user/apps" title={t("apps")} />
      <Tab href="/dashboard/user/calendar-integration" title={t("calendar-integration")} />
      <Tab href="/dashboard/user/change-password" title={t("change-password")} />
    </TabBar>
  );
};
