import { datadogRum } from "@datadog/browser-rum";
import { ErrorInfo, PropsWithChildren, useCallback, useMemo } from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { getDatadogSecrets } from "../../env";
import { ErrorBoundaryProvider } from "./ErrorBoundary.context";

const ERROR_NAME = "PreNavigationError";

const FallbackComponent = () => {
  // TODO: prettify error message, or maybe redirect to error page?
  return <span>Something went wrong</span>;
};

export const ErrorBoundary = ({ children }: PropsWithChildren) => {
  const enableErrorReporting = useCallback(() => {
    datadogRum.init({ ...getDatadogSecrets() });
  }, []);

  const onError = useCallback((error: Error, info?: ErrorInfo, errorName?: string) => {
    const report = new Error(error.message);

    report.name = errorName ?? ERROR_NAME;
    /* istanbul ignore next */
    report.stack = info?.componentStack ?? error.stack;
    report.cause = error;

    datadogRum.addError(report);
  }, []);

  const onReportEvent = useCallback((actionName: string, payload: Record<string, unknown>) => {
    datadogRum.addAction(actionName, {
      type: "custom",
      ...payload,
    });
  }, []);

  const contextValue = useMemo(
    () => ({ enableErrorReporting, reportError: onError, reportEvent: onReportEvent }),
    [enableErrorReporting, onError, onReportEvent],
  );

  return (
    <ReactErrorBoundary FallbackComponent={FallbackComponent} onError={onError}>
      <ErrorBoundaryProvider value={contextValue}>{children}</ErrorBoundaryProvider>
    </ReactErrorBoundary>
  );
};
