import { useTranslation } from "@simplicate/translations";
import { ActionDropdown, Icon } from "@simplicate/ui";
import { lightColorIconError } from "@simplicate-software/design-tokens";
import { memo } from "react";

type HourTypeActionDropdownProps = {
  identifier: string;
  isInvoiceable?: boolean;
  onClickMoveToTop: (identifier: string) => void;
  onClickMoveToBottom: (identifier: string) => void;
  onClickToggleIsInvoiceable?: (identifier: string) => void;
  onClickRemove: (identifier: string) => void;
  disabled?: boolean;
};

export const HourTypeActionDropdown = memo(function HourTypeActionDropdown({
  identifier,
  isInvoiceable = false,
  onClickToggleIsInvoiceable,
  onClickRemove,
  disabled = false,
}: HourTypeActionDropdownProps) {
  const { t } = useTranslation("project_services");

  return (
    <ActionDropdown
      testId="hour-type-actions"
      button={
        <ActionDropdown.Button
          iconProps={{ icon: "ellipsisVertical" }}
          variant="subtle"
          disabled={disabled}
          testId="hour-type-actions-button"
        />
      }
    >
      {onClickToggleIsInvoiceable && (
        <ActionDropdown.Action onClick={() => onClickToggleIsInvoiceable(identifier)}>
          <Icon icon="fileInvoice" />
          {isInvoiceable ? t("set_is_not_invoiceable") : t("set_is_invoiceable")}
        </ActionDropdown.Action>
      )}
      <ActionDropdown.Action onClick={() => onClickRemove(identifier)}>
        <Icon icon="trash" color={lightColorIconError} />
        {t("remove_from_grid")}
      </ActionDropdown.Action>
    </ActionDropdown>
  );
});
