import classNames from "classnames";
import { PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";
import { ModuleIcon } from "../../ModuleIcon";
import { Triangle } from "../../Triangle";
import { determineHref } from "../determineHref";
import { useMenuContext } from "../NavMenu.context";
import { SubMenu } from "../SubMenu";
import styles from "./Item.module.scss";

export type Item = PropsWithChildren<{
  className?: string;
  icon: ModuleIcon["module"];
  title: string;
  dark?: boolean;
  large?: boolean;
  testId?: string;
  href?: string;
}>;

export const Item = ({ className, children, title, href, icon, testId, large = false, dark = false }: Item) => {
  const { expanded } = useMenuContext();
  const targetHref = determineHref(children, href);
  const classes = classNames(
    className,
    styles.container,
    children && styles.withSubMenu,
    expanded && styles.wide,
    dark && styles.dark,
  );

  return (
    <li className={classes} data-testid={testId}>
      {targetHref ? (
        <NavLink
          to={targetHref}
          className={styles.header}
          title={title}
          onMouseDown={(event) => event.preventDefault()}
        >
          <div className={classNames(styles.icon, large && styles.large)}>
            <ModuleIcon module={icon} />
          </div>
          <span className={styles.title}>{title}</span>
        </NavLink>
      ) : (
        <div className={styles.header}>
          <div className={classNames(styles.icon, large && styles.large)}>
            <ModuleIcon module={icon} />
          </div>
          <span className={styles.title}>{title}</span>
        </div>
      )}
      {children && (
        <>
          <div className={styles.triangleContainer}>
            <Triangle direction="right" />
          </div>
          {/* eslint-disable-next-line css-modules/no-undef-class -- subMenu is defined by used mixin */}
          <SubMenu className={styles.subMenu} testId={`${testId}-sub-menu`}>
            {children}
          </SubMenu>
        </>
      )}
    </li>
  );
};
