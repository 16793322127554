import { Locale, useAppSelector } from "@simplicate/state";
import { Input, type InputProps } from "@simplicate/ui";
import { NumericFormat, type NumericFormatProps, numericFormatter } from "react-number-format";

export type NumberFormatProps = NumericFormatProps<InputProps> & { locale?: Locale };

const defaultConfig = {
  displayType: "text",
  type: "text",
  customInput: Input,
} satisfies NumberFormatProps;

const getLocaleConfig = (locale?: Locale) => {
  switch (locale) {
    case "nl":
      return {
        decimalSeparator: ",",
        thousandSeparator: ".",
      };
    default:
      return {
        decimalSeparator: ".",
        thousandSeparator: ",",
      };
  }
};

export const useNumericFormatter = (numStr: string | undefined) => {
  const { locale: userLocale } = useAppSelector(/* istanbul ignore next */ ({ state }) => state.locale);

  if (!numStr) {
    return;
  }

  return numericFormatter(numStr, { ...defaultConfig, ...getLocaleConfig(userLocale) });
};

export const NumberFormat = ({ locale, ...props }: NumberFormatProps) => {
  const { locale: userLocale } = useAppSelector(/* istanbul ignore next */ ({ state }) => state.locale);

  const configuredProps = { ...defaultConfig, ...getLocaleConfig(locale ?? userLocale), ...props };

  return <NumericFormat {...configuredProps} />;
};
