import {
  colorCommonBlue300,
  colorCommonBrand300,
  colorCommonGreen300,
  colorCommonGrey300,
  colorCommonOrange300,
  colorCommonRed300,
  colorModulesYellow300,
} from "@simplicate-software/design-tokens";
import { Plugin } from "chart.js";
import Color from "color";

const COLORS = [
  colorCommonBlue300,
  colorCommonRed300,
  colorCommonGreen300,
  colorCommonOrange300,
  colorCommonBrand300,
  colorModulesYellow300,
  colorCommonGrey300,
] as const;

export const Colors = {
  id: "colors",
  beforeLayout: (chart) => {
    chart.config.data.datasets.forEach((dataset, index) => {
      const backgroundColor = new Color(COLORS[index % COLORS.length]).fade(0.3).hexa();

      dataset.backgroundColor ??= backgroundColor;
      dataset.borderColor ??= COLORS[index % COLORS.length];
    });
  },
} satisfies Plugin;
