import { ForwardedRef, forwardRef, memo } from "react";
import styles from "./IFrame.module.scss";

type IFrameProps = {
  src: string;
  title: string;
  onLoad?: (event: React.SyntheticEvent<HTMLIFrameElement>) => void;
  testId?: string;

  /**
   * NOTE: This is a band-aid fix for SIM-36710
   * TODO: remove this
   *
   * @deprecated -- do not use
   * */
  remountOnSearchChange?: boolean;
};

const IFrameComponent = forwardRef(function IFrame(
  { src, title, onLoad, testId }: IFrameProps,
  ref: ForwardedRef<HTMLIFrameElement>,
) {
  return (
    <iframe
      key={src}
      src={src}
      title={title}
      onLoad={onLoad}
      className={styles.iframe}
      ref={ref}
      data-testid={testId}
    />
  );
});

export const IFrame = memo(IFrameComponent, (previousProp, nextProps) => {
  const pathsAreEqual =
    new URL(previousProp.src, window.location.origin).pathname ===
    new URL(nextProps.src, window.location.origin).pathname;
  const searchParamsAreEqual =
    new URL(previousProp.src, window.location.origin).search === new URL(nextProps.src, window.location.origin).search;

  return /* istanbul ignore next */ nextProps.remountOnSearchChange === true
    ? searchParamsAreEqual && pathsAreEqual
    : pathsAreEqual;
});
