import { skipToken } from "@reduxjs/toolkit/query/react";
import {
  useEnvironmentSetting,
  useGetInvoiceQuery,
  useGetReverseInvoiceForInvoiceQuery,
  usePermission,
} from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { TabBar, Tab } from "@simplicate/ui";
import { useParams } from "react-router-dom";
import { IFrameActionButtons } from "../../IFrameActionButtons";

const CONCEPT_INVOICE_STATUS_ID = "invoicestatus:b313fe5974eca456";

const useReverseInvoiceTabPermissions = (invoiceId: string | undefined) => {
  const hasReverseBillingEnabled = useEnvironmentSetting("invoices", "is_reverse_billing")?.value === 1;
  const { data } = useGetReverseInvoiceForInvoiceQuery(hasReverseBillingEnabled && invoiceId ? invoiceId : skipToken);

  const hasReverseInvoicesForInvoice = (data?.data ?? []).length > 0;

  return { showReverseInvoiceTab: hasReverseInvoicesForInvoice };
};

export const InvoiceDetailsPageTabs = () => {
  const { t } = useTranslation("tab_bar");
  const { id } = useParams<{ id: string }>();
  const hasViewInvoicesPermission = usePermission("invoices", "view").granted;
  const { data } = useGetInvoiceQuery(hasViewInvoicesPermission && id ? id : skipToken);
  const { showReverseInvoiceTab } = useReverseInvoiceTabPermissions(id);

  if (!id || !hasViewInvoicesPermission) {
    return;
  }

  const invoiceIsDraft = data?.data.status.id === CONCEPT_INVOICE_STATUS_ID;
  const invoiceUsesServices = data?.data.composition_type === "service";

  return (
    <TabBar testId="draft-invoice" actions={<IFrameActionButtons />}>
      {invoiceIsDraft && <Tab href={`/invoicing/${id}/draft-invoice`} title={t("draft_invoice")} />}
      {!invoiceIsDraft && <Tab href={`/invoicing/${id}/invoice`} title={t("invoice")} />}
      {invoiceUsesServices && <Tab href={`/invoicing/${id}/services`} title={t("services")} />}
      {!invoiceIsDraft && <Tab href={`/invoicing/${id}/documents`} title={t("documents")} />}
      {showReverseInvoiceTab && <Tab href={`/invoicing/${id}/reverse-invoices`} title={t("reverse-invoices")} />}
      <Tab href={`/invoicing/${id}/timeline`} title={t("timeline")} />
    </TabBar>
  );
};
