import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { clearEvents, PlanhatEvent, PlanhatState, queueEvent } from "./planhatSlice";

const REPORTING_INTERVAL = 10_000; // 10 seconds

let timeoutInstance: NodeJS.Timeout | number | string | undefined;

function reportEvents(events: PlanhatEvent[], onSuccess: () => void) {
  fetch("/v1/internal/events/register", {
    body: JSON.stringify({ data: events }),
    method: "POST",
  })
    .then(onSuccess)
    .catch((error) => {
      console.error("Failed to report Planhat events", error);
    });
}

export const logLocation =
  (
    pathName: string,
  ): ThunkAction<
    void,
    { state: { planhat: PlanhatState } },
    unknown,
    Action<"planhat/clearEvents" | "planhat/queueEvent">
  > =>
  (dispatch, getState) => {
    dispatch(queueEvent(pathName));

    /* istanbul ignore next */
    if (timeoutInstance) {
      clearTimeout(timeoutInstance);
    }

    timeoutInstance = setTimeout(() => {
      timeoutInstance = undefined;

      const events = getState().state.planhat.events;

      if (events.length === 0) {
        return;
      }

      reportEvents(events, () => dispatch(clearEvents()));
    }, REPORTING_INTERVAL);
  };
