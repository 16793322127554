/* eslint-disable import/no-unused-modules -- wildcard exports are not recognized */
import { combineReducers } from "redux";
import { HeaderState, headerReducer } from "./headerSlice";
import { IframeState, iFrameReducer } from "./iFrameSlice";
import { LocaleState, localeReducer } from "./localeSlice";
import { MainMenuState, mainMenuReducer } from "./mainMenuSlice";
import { planhatReducer, PlanhatState } from "./planhatSlice";

export type RootState = {
  state: {
    header: HeaderState;
    iframe: IframeState;
    locale: LocaleState;
    mainMenu: MainMenuState;
    planhat: PlanhatState;
  };
};

export const rootReducer = {
  state: combineReducers({
    header: headerReducer,
    iframe: iFrameReducer,
    locale: localeReducer,
    mainMenu: mainMenuReducer,
    planhat: planhatReducer,
  }),
};

export { setHeader } from "./headerSlice";
export {
  type IframeEvent,
  type PostIFrameMessage,
  iframeMessageReceived,
  setPostIframeMessageHandler,
  postIframeMessage,
  clearMessages,
} from "./iFrameSlice";
export { type Locale, setLocale } from "./localeSlice";
export { toggleMainMenuExpanded } from "./mainMenuSlice";
export { logLocation } from "./planhatSlice";
