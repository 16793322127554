import { toggleMainMenuExpanded, useAppDispatch, useAppSelector } from "@simplicate/state";
import { TopBar } from "@simplicate/top-bar";
import { ToastContainer } from "@simplicate/ui";
import classNames from "classnames";
import { Outlet } from "react-router-dom";
import { MainMenu } from "../MainMenu";
import { PermissionBoundary } from "../PermissionBoundary";
import styles from "./NavigationWrapper.module.scss";
import { GeneralSubHeader } from "./SubHeaders";
import { useHistoryFix } from "./useHistoryFix";
import { useRouteConfig } from "./useRouteConfig";

export const NavigationWrapper = () => {
  const { expanded } = useAppSelector(/* istanbul ignore next */ ({ state }) => state.mainMenu);
  const { showOverlay } = useAppSelector(/* istanbul ignore next */ ({ state }) => state.header);
  const dispatch = useAppDispatch();

  const {
    theme: themeClass,
    module,
    backNavigationPath,
    subHeaderComponent: SubHeaderComponent,
    tabsComponent: TabsComponent,
  } = useRouteConfig();

  useHistoryFix();

  const hasSubHeaderComponent = !!SubHeaderComponent;
  const hasTabs = !!TabsComponent;
  const onToggleExpanded = () => dispatch(toggleMainMenuExpanded());

  return (
    <>
      <div id="nav-wrapper" className={classNames(themeClass, styles.layout, expanded && styles.expanded)}>
        <div className={styles.mainMenu}>
          <MainMenu expanded={expanded} onToggleExpanded={onToggleExpanded} />
        </div>
        <div className={classNames(styles.topBar, showOverlay && styles.overlay)} data-testid="top-bar-nav">
          <TopBar backButtonTarget={backNavigationPath} module={module}>
            {hasSubHeaderComponent ? <SubHeaderComponent /> : <GeneralSubHeader />}
          </TopBar>
          {hasTabs && <TabsComponent />}
        </div>
        <main id="main" className={classNames(styles.outlet, hasTabs && styles.withTabs, expanded && styles.expanded)}>
          <PermissionBoundary>
            <Outlet />
          </PermissionBoundary>
        </main>
      </div>
      <ToastContainer />
    </>
  );
};
