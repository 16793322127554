import classNames from "classnames";
import { PropsWithChildren } from "react";
import styles from "./ServiceRowCell.module.scss";

type ServiceRowCellProps = PropsWithChildren & {
  variant?: "button" | "default" | "grip" | "name" | "number";
};

const variantClasses: Record<NonNullable<ServiceRowCellProps["variant"]>, string> = {
  default: classNames([styles.cell]),
  grip: classNames([styles.cell, styles.grip]),
  name: classNames([styles.cell, styles.name]),
  number: classNames([styles.cell, styles.number]),
  button: classNames([styles.cell, styles.button]),
};

export const ServiceRowCell = ({ children, variant = "default" }: ServiceRowCellProps) => {
  return <div className={variantClasses[variant]}>{children}</div>;
};
