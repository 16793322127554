import { PropsWithChildren } from "react";
import styles from "./PageHeader.module.scss";

type PageHeaderProps = PropsWithChildren & {
  title?: string;
};

export const PageHeader = ({ title, children }: PageHeaderProps) => {
  return (
    <header className={styles.pageheader}>
      {title && <h2 className={styles.title}>{title}</h2>}
      {children}
    </header>
  );
};
