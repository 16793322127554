import { createContext, useContext } from "react";

type MenuState = {
  expanded: boolean;
  toggleExpand: () => void;
  expandMenuLabel: string;
  collapseMenuLabel: string;
};

const MenuContext = createContext<MenuState | null>(null);

export const useMenuContext = () => {
  const context = useContext(MenuContext);

  if (!context) {
    throw new Error("useMenuContext must be used within a MenuContextProvider");
  }

  return context;
};

export const MenuContextProvider = MenuContext.Provider;
