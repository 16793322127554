import { simplicateApiV1 } from "../components";
import { createTagTypes } from "../tags";

export type SimplicateSettings = {
  paymentMessage: string | undefined;
  showPaymentMessage: boolean;
  hasAcceptedGdpr?: 0 | 1;
};

const tags = createTagTypes({ tagPrefix: "SimplicateSettings", tags: ["list"] });

const THIRTY_MINUTES = 60 * 30;

const endpoints = simplicateApiV1.enhanceEndpoints({ addTagTypes: Object.values(tags) }).injectEndpoints({
  endpoints: (builder) => ({
    getSimplicateSettings: builder.query<SimplicateSettings, void>({
      query: () => ({
        url: "/internal/environment/simplicateSettings",
      }),
      keepUnusedDataFor: THIRTY_MINUTES,
      providesTags: [tags.list],
    }),
  }),
  overrideExisting: false,
});

export const { useGetSimplicateSettingsQuery } = endpoints;
