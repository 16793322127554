import { ConversionRecord } from "../types";
import { extractQueryParam, extractSelectedTab } from "./utils";

export const SalesRouteConversions: ConversionRecord = {
  "/acquisition/opportunity/view": (location) => {
    const suffix = extractSelectedTab(location, {
      dossier: "documents",
      service: "services",
      general: "general",
    });

    return {
      status: "pending-id-conversion",
      locationWithId: (id: string) => {
        const baseUrl = `/sales/${id}/${suffix}`;
        const searchParams = location.searchParams.toString();

        return suffix === "general" && searchParams ? `${baseUrl}?${searchParams}` : baseUrl;
      },
      queryArguments: {
        entityName: "opportunity",
        v1Identifier: extractQueryParam(location, "id"),
      },
    };
  },

  "/v1/acquisition": (location) => {
    const suffix = extractSelectedTab(location, {
      scored: "accepted",
      missed: "rejected",
    });

    return { status: "complete", location: `/sales/${suffix}` };
  },

  "v1/reporting/sales": (location) => {
    return { status: "complete", location: `/sales/reporting/funnel?${location.searchParams.toString()}` };
  },
};
