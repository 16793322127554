import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getUnreadNotificationCountFulfilled } from "@simplicate/api-client";
import { Module } from "@simplicate/utils";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { clearMessages, iframeMessageReceived } from "../iFrameSlice";

const DEFAULT_TITLE_SUFFIX = " | Simplicate";

function updateBrowserTabTitle({ title, unreadNotifications }: HeaderState) {
  const tabTitle = unreadNotifications > 0 ? `(${unreadNotifications}) ${title}` : title;

  document.title = tabTitle + DEFAULT_TITLE_SUFFIX;
}

export type HeaderState = {
  title: string;
  theme: Module;
  unreadNotifications: number;

  /**
   * When true, either the iframe environment or the legacy web component has requested an modal backdrop to be
   * overlayed the common navigation UI components, such as the header.
   */
  showOverlay: boolean;
};

const initialState: HeaderState = {
  title: "",
  theme: "dashboard",
  showOverlay: false,
  unreadNotifications: 0,
};

const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setHeader: (state, action: PayloadAction<Partial<HeaderState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: /* istanbul ignore next */ (builder) => {
    builder.addCase(iframeMessageReceived, (state, action) => {
      action.payload.params.forEach((message) => {
        if (message.action === "IFRAME_SUBHEADER_ENABLED") {
          state.showOverlay = !message.payload.enabled;
        }
      });
    });
    builder.addCase(clearMessages, (state) => {
      state.showOverlay = false;
    });
    builder.addMatcher(getUnreadNotificationCountFulfilled, (state, action) => {
      state.unreadNotifications = action.payload.data.unread;

      updateBrowserTabTitle(state);
    });
  },
});

/**
 * Set the header state.
 * Automatically keeps the browser tab title in sync with the title state.
 */
export const setHeader =
  (
    header: Partial<HeaderState>,
  ): ThunkAction<void, { state: { header: HeaderState } }, unknown, Action<"header/setHeader">> =>
  (dispatch, getState) => {
    dispatch(headerSlice.actions.setHeader(header));

    const { state } = getState();

    updateBrowserTabTitle(state.header);
  };

export const _setHeaderAction = headerSlice.actions.setHeader;
export const headerReducer = headerSlice.reducer;
