import {
  DropOptions,
  getBackendOptions,
  MultiBackend,
  NodeRender,
  Tree,
  TreeMethods,
  TreeProps,
} from "@minoru/react-dnd-treeview";
import { useCallback, useEffect, useRef } from "react";
import { DndProvider } from "react-dnd";
import { Placeholder, ServiceGroup, ServiceRow } from "./components";
import styles from "./GroupedServicesManager.module.scss";
import {
  GroupedServicesNodeModel,
  ServiceData,
  ServiceGroupData,
  ServiceGroupNodeModel,
  ServiceGroupType,
  ServiceNodeModel,
} from "./GroupedServicesManager.types";

export const isServiceGroup = (node?: GroupedServicesNodeModel): node is ServiceGroupNodeModel => !!node?.droppable;

export const canDrag = (node?: GroupedServicesNodeModel) =>
  (node && !isServiceGroup(node)) || (isServiceGroup(node) && node?.data?.groupType !== ServiceGroupType.UNGROUPED);

export const canDrop = (
  _tree: GroupedServicesNodeModel[],
  { dragSource, dropTarget, dropTargetId }: Omit<DropOptions<ServiceData | ServiceGroupData>, "monitor">,
) => {
  if (!dragSource) return;
  // Do not allow service groups to be dropped into other nodes.
  if (isServiceGroup(dragSource) && typeof dropTarget !== "undefined") {
    return false;
  }
  // Do not allow services to be dropped outside other nodes.
  if (!isServiceGroup(dragSource) && typeof dropTarget === "undefined") {
    return false;
  }
  // Allow moving within the same parent, required in combination with `sort={false}`.
  if (dragSource.parent === dropTargetId) {
    return true;
  }
  // @TODO Do not allow droppable nodes (containers) to be dropped above the ungrouped-container. This is currently not
  //   possible because the canDrop callback is not called with the destinationIndex of relativeIndex information. See
  //   also https://github.com/minop1205/react-dnd-treeview/issues/196.
};

type GroupedServicesManagerProps = {
  treeData: GroupedServicesNodeModel[];
  rootId: TreeProps["rootId"];
  onChangeTree?: (newTree: GroupedServicesNodeModel[]) => void;
  onDuplicateGroup?: () => void;
  onAutoGroup?: () => void;
};

export const GroupedServicesManager = ({
  treeData,
  rootId,
  onChangeTree,
  onDuplicateGroup,
  onAutoGroup,
}: GroupedServicesManagerProps) => {
  const treeRef = useRef<TreeMethods>(null);

  const renderNode: NodeRender<ServiceData | ServiceGroupData> = useCallback(
    (node, { hasChild, isDropTarget }) =>
      isServiceGroup(node) ? (
        <ServiceGroup
          node={node}
          hasChild={hasChild}
          isDropTarget={isDropTarget}
          onDuplicateGroup={onDuplicateGroup}
          onAutoGroup={onAutoGroup}
        />
      ) : (
        <ServiceRow node={node as ServiceNodeModel} />
      ),
    [onAutoGroup, onDuplicateGroup],
  );

  useEffect(() => treeRef?.current?.openAll(), [treeData]);

  /* c8 ignore start -- Not worth testing since it merely links a callback to a useState setter. */
  /* istanbul ignore next -- Not worth testing since it merely links a callback to a useState setter. */
  const handleDrop = (newTree: GroupedServicesNodeModel[]) => {
    onChangeTree && onChangeTree(newTree);
  };
  /* c8 ignore end */

  return (
    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
      <Tree
        ref={treeRef}
        tree={treeData}
        rootId={rootId}
        initialOpen={true}
        render={renderNode}
        onDrop={handleDrop}
        classes={{
          root: styles.treeRoot,
          container: styles.container,
          draggingSource: styles.draggingSource,
          placeholder: styles.placeholderContainer,
        }}
        sort={false}
        insertDroppableFirst={false}
        canDrop={canDrop}
        canDrag={canDrag}
        dropTargetOffset={10}
        placeholderRender={/* istanbul ignore next */ () => <Placeholder />}
      />
    </DndProvider>
  );
};
