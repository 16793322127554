import { useLicenseGuard, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";
import { UpSellTooltip } from "../UpSellTooltip";

export const InvoicesReportingMenuItem = () => {
  const { t } = useTranslation("main_menu");
  const { enabled: hasMRRReportingLicense } = useLicenseGuard("invoicing_mrr_reporting");
  const hasReportingPermission = usePermission("invoices", "rapportage").granted;

  return !hasMRRReportingLicense ? (
    <NavMenu.SubItem title={t("invoicing_reporting")}>
      <NavMenu.SubItem
        href="/invoicing/reporting/invoicing"
        title={t("invoicing_reporting_revenue")}
        testId="invoices-reporting-revenue-menu-item"
      />
      <NavMenu.SubItem
        title={<UpSellTooltip title={t("invoicing_reporting_mrr")} />}
        testId="invoices-mrr-menu-item"
        disabled
      />
    </NavMenu.SubItem>
  ) : (
    <>
      {hasReportingPermission && (
        <NavMenu.SubItem title={t("invoicing_reporting")}>
          <NavMenu.SubItem
            href="/invoicing/reporting/invoicing"
            title={t("invoicing_reporting_revenue")}
            testId="invoices-reporting-revenue-menu-item"
          />
          <NavMenu.SubItem
            href="/invoicing/reporting/mrr"
            title={t("invoicing_reporting_mrr")}
            testId="invoices-mrr-menu-item"
          />
        </NavMenu.SubItem>
      )}
    </>
  );
};
