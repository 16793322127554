import { ResultSet } from "@cubejs-client/core";
import { CubeDimension, cubeDimensionToKey } from "../../../types";
import { Aggregator } from "./aggregators.types";

export function buildAverageAggregator<T>(dimension: CubeDimension): Aggregator<T> {
  return (resultSet: ResultSet | null) => {
    if (!resultSet) {
      return undefined;
    }

    const pivot = resultSet.tablePivot();
    const sum = pivot.reduce((acc, row) => acc + Number(row[cubeDimensionToKey(dimension)]), 0);

    return sum / pivot.length;
  };
}
