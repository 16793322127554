import { useGetCurrentUserQuery, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";
import { asModuleTheme } from "@simplicate/utils";
import { AllEmployeesMenuItem } from "./AllEmployeesMenuItem";
import { HrmReportingMenuItem } from "./HrmReportingMenuItem";
import { MyHrmMenuItem } from "./MyHrmMenuItem";

export const Hrm = () => {
  const { t } = useTranslation("main_menu");
  const { data: userData } = useGetCurrentUserQuery();
  const hasViewPermission = usePermission("hrm", "view").granted;
  const themeClass = asModuleTheme("hrm");

  if (!hasViewPermission) return null;

  return (
    <NavMenu.Item title={t("hrm")} href="/hrm" className={themeClass} icon="hrm" testId="hrm-menu-item">
      <NavMenu.SubItem href="/hrm/employees" title={t("hrm_employees")} testId="hrm-employees-menu-item" />
      {userData?.data && (
        <>
          <AllEmployeesMenuItem {...userData.data} />
          <MyHrmMenuItem {...userData.data} />
        </>
      )}
      <HrmReportingMenuItem />
    </NavMenu.Item>
  );
};
