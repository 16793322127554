import { createContext, useContext } from "react";

type ActionDropdownContext = {
  closeDropdown: () => void;
  toggleDropdown: () => void;
  dropdownButtonId: string;
};

export const DropdownContext = createContext<Partial<ActionDropdownContext>>({});

export const useDropdownContext = () => useContext(DropdownContext);
