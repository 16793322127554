import { useLicenseGuard, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";
import { UpSellTooltip } from "../UpSellTooltip";

export const HrmReportingMenuItem = () => {
  const { t } = useTranslation("main_menu");
  const { enabled: hasHRMReportingEnabled } = useLicenseGuard("hrm_reporting");
  const hasViewReportingPermission = usePermission("hrm", "rapportage").granted;

  if (!hasHRMReportingEnabled) {
    return (
      <NavMenu.SubItem title={t("hrm_reporting")}>
        <NavMenu.SubItem
          title={<UpSellTooltip title={t("hrm_leave_reporting")} />}
          testId="hrm-leave-reporting-menu-item"
          disabled
        />
        <NavMenu.SubItem
          title={<UpSellTooltip title={t("hrm_absence_reporting")} />}
          testId="hrm-absence-reporting-menu-item"
          disabled
        />
      </NavMenu.SubItem>
    );
  }

  if (!hasViewReportingPermission) {
    return null;
  }

  return (
    <NavMenu.SubItem title={t("hrm_reporting")}>
      <NavMenu.SubItem
        href="/hrm/reporting/leave"
        title={t("hrm_leave_reporting")}
        testId="hrm-leave-reporting-menu-item"
      />
      <NavMenu.SubItem
        href="/hrm/reporting/absence"
        title={t("hrm_absence_reporting")}
        testId="hrm-absence-reporting-menu-item"
      />
    </NavMenu.SubItem>
  );
};
