import { useGetCurrentUserQuery } from "@simplicate/api-client";
import { setLocale, useAppDispatch } from "@simplicate/state";
import { PropsWithChildren, useEffect } from "react";
import { DateFnsProvider } from "../DateFnsProvider";

export const LocaleProvider = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const { data: user } = useGetCurrentUserQuery();

  const languageCode = user?.data?.country?.language_code;

  useEffect(() => {
    if (languageCode) {
      dispatch(setLocale(languageCode));
    }
  }, [dispatch, languageCode]);

  return <DateFnsProvider>{children}</DateFnsProvider>;
};
