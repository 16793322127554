import classNames from "classnames";
import { PropsWithChildren, ReactNode } from "react";
import styles from "./Widget.module.scss";

type WidgetProps = PropsWithChildren<{
  title: string;

  /**
   * An annotation to display next to the title. Accepts any ReactNode but works best with components such as Tag and
   * Counter, or other components that use a span element as their container.
   */
  annotation?: ReactNode;
  variant?: "borderless" | "default";
  titleSize?: "default" | "large";
}>;

export const Widget = ({ children, title, annotation, variant = "default", titleSize = "default" }: WidgetProps) => {
  return (
    <div className={classNames(styles.widget, variant === "borderless" && styles.borderless)}>
      <h5 className={classNames(styles.title, titleSize === "large" && styles.largeTitle)}>
        {title}
        {annotation}
      </h5>
      <div className={styles.body}>{children}</div>
    </div>
  );
};
