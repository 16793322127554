import { useAppSelector } from "@simplicate/state";
import { APIOptions, PrimeReactProvider as PRProvider, addLocale, PrimeReactContext } from "primereact/api";
import { PropsWithChildren, useContext, useEffect, useMemo } from "react";
import { locales } from "./locales";
import "./customTheme.scss";

const LocaleUpdater = ({ locale }: { locale: keyof typeof locales }) => {
  const context = useContext(PrimeReactContext);

  useEffect(() => {
    context?.setLocale?.(locale);
  }, [context, locale]);

  return null;
};

export const PrimeReactProvider = ({ children }: PropsWithChildren) => {
  const { locale } = useAppSelector(/* istanbul ignore next */ ({ state }) => state.locale);

  useEffect(() => {
    Object.entries(locales).forEach(([localeName, locale]) => {
      addLocale(localeName, locale);
    });
  }, []);

  const context: Partial<APIOptions> = useMemo(() => ({ locale }), [locale]);

  return (
    <PRProvider value={context}>
      {children}
      <LocaleUpdater locale={locale} />
    </PRProvider>
  );
};
