import { useGetAvatarQuery, useGetCurrentUserQuery } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Avatar } from "@simplicate/ui";
import { memo } from "react";
import { TopBarButton } from "../TopBarButton";
import { ProfileDropdown } from "./components";

export const Profile = memo(function ProfileComponent() {
  const { t } = useTranslation("top_bar");
  const { data: user, isSuccess: hasUser } = useGetCurrentUserQuery();
  const { data: avatar } = useGetAvatarQuery({ id: user?.data.employee_id }, { skip: !hasUser });

  return (
    <TopBarButton
      aria-label={t("label_account_button")}
      testId="top-bar-account-button"
      dropdownComponent={<ProfileDropdown />}
      dropdownWidth="small"
    >
      <Avatar
        src={avatar?.data[0]?.url_small}
        altText={avatar?.data[0]?.initials}
        color={avatar?.data[0]?.color}
        size="small"
        testId="top-bar-avatar-component"
      />
    </TopBarButton>
  );
});
