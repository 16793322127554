import { useFeatureFlag, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";
import { asModuleTheme } from "@simplicate/utils";
import { useIsAdministrator } from "./useIsAdministrator";

export const Insights = () => {
  const { t } = useTranslation("main_menu");
  const themeClass = asModuleTheme("insights");
  const featureEnabled = useFeatureFlag("insights").enabled;

  const endUserHasAccess = useFeatureFlag("insights-end-user-access").enabled;
  const { isAdministrator } = useIsAdministrator();
  const { granted: hasAccessToHours } = usePermission("hours", "view");
  const { granted: hasHoursReportingPermission } = usePermission("hours", "hoursrapportage_all");
  const { granted: hasSalesReportingPermission } = usePermission("acquisition", "rapportage");
  const { granted: hasHRMReportingPermission } = usePermission("hrm", "rapportage");

  const hasAppropriatePermissions =
    hasHRMReportingPermission || (hasAccessToHours && hasHoursReportingPermission) || hasSalesReportingPermission;

  const hasAccess = isAdministrator || (hasAppropriatePermissions && endUserHasAccess);

  if (!featureEnabled || !hasAccess) {
    return null;
  }

  return (
    <NavMenu.Item
      href="/insights"
      className={themeClass}
      icon="insights"
      title={t("insights")}
      testId="insights-menu-item"
    />
  );
};
