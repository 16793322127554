import { HEADER_DATA_TAG, simplicateApiV2 } from "../components";
import { createTagTypes } from "../tags";
import { Response } from "./types";

export type Organization = {
  id: string;
  name: string;
  url?: string;
  email?: string;
  phone?: string;
  note?: string;
  cocCode?: string;
  relation_number?: string;
  is_active?: boolean;
  relation_type?: {
    id: string;
    label: string;
    color: string;
  };
};

const tags = createTagTypes({ tagPrefix: "Organization", tags: ["entity"] });

const endpoints = simplicateApiV2.enhanceEndpoints({ addTagTypes: Object.values(tags) }).injectEndpoints({
  endpoints: (builder) => ({
    getOrganization: builder.query<Response<Organization>, string>({
      query: (id) => ({
        url: `/crm/organization/${id}`,
      }),
      providesTags: [tags.entity, HEADER_DATA_TAG],
    }),
  }),
});

export const { useGetOrganizationQuery } = endpoints;
