import { usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";
import { asModuleTheme } from "@simplicate/utils";
import { HoursPlanningMenuItem } from "./HoursPlanningMenuItem";
import { HoursReportingMenuItem } from "./HoursReportingMenuItem";
import { AgendaMenuItem, CostsMenuItem, MileageMenuItem, TimesheetMenuItem } from "./LegacyMenuItems";
import { ProjectReviewMenuItem } from "./ProjectReviewMenuItem";
import { WeekReviewMenuItem } from "./WeekReviewMenuItem";

export const Hours = () => {
  const { t } = useTranslation("main_menu");
  const hasViewHoursPermission = usePermission("hours", "view").granted;
  const themeClass = asModuleTheme("hours");

  if (!hasViewHoursPermission) return null;

  return (
    <NavMenu.Item title={t("hours")} href="/hours" className={themeClass} icon="hours" testId="hours-menu-item">
      <NavMenu.SubItem href="/hours/register" title={t("hours_register")} testId="hours-register-menu-item" />
      <AgendaMenuItem />
      <TimesheetMenuItem />
      <MileageMenuItem />
      <CostsMenuItem />
      <ProjectReviewMenuItem />
      <WeekReviewMenuItem />
      <HoursPlanningMenuItem />
      <HoursReportingMenuItem />
    </NavMenu.Item>
  );
};
