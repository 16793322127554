import { Navigate, useRouteError } from "react-router-dom";
import { useErrorBoundaryContext } from "./ErrorBoundary.context";

const ERROR_NAME = "PageError";

export const RouteErrorBoundary = () => {
  const { reportError } = useErrorBoundaryContext();
  const error = useRouteError() as Error;

  reportError(error, undefined, ERROR_NAME);

  return <Navigate to="/500" />;
};
