import { ConversionRecord } from "../types";
import { extractQueryParam, extractSelectedTab } from "./utils";

export const InvoicesRouteConversions: ConversionRecord = {
  "/invoices/proposition": (location) => {
    const suffix =
      extractSelectedTab(location, {
        proposition_detail: "ready",
        proposition_notready: "not-ready",
      }) || "ready";

    return {
      status: "pending-id-conversion",
      locationWithId: (id: string) => `/invoicing/${id}/${suffix}`,
      queryArguments: {
        v1Identifier: extractQueryParam(location, "id"),
        entityName: "proposition",
      },
    };
  },

  "/invoices/invoice/view": (location) => {
    const suffix = extractSelectedTab(location, {
      concept_invoice: "draft-invoice",
      time_line: "timeline",
      dossier: "documents",
    });

    return {
      status: "pending-id-conversion",
      locationWithId: (id: string) => {
        const searchParams = location.searchParams;

        searchParams.delete("id");
        searchParams.delete("tab");

        const target = suffix === "" ? `/invoicing/${id}` : `/invoicing/${id}/${suffix}`;

        return searchParams.toString() !== "" ? `${target}?${searchParams.toString()}` : target;
      },
      queryArguments: {
        v1Identifier: extractQueryParam(location, "id"),
        entityName: "invoicing",
      },
    };
  },

  "/v1/invoices": (location) => {
    const suffix = extractSelectedTab(location, {
      proposition: "ready-for-invoicing",
      concept: "draft-invoices",
      unpaid: "outstanding",
      journlize: "accounting",
    });

    return { status: "complete", location: `/invoicing/${suffix}` };
  },

  // REPORTING
  "/v1/reporting/invoicing": () => ({ status: "complete", location: "/invoicing/reporting/invoicing" }),
  "/v1/reporting/mrr": () => ({ status: "complete", location: "/invoicing/reporting/mrr" }),
};
