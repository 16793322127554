import { Counter, Dropdown } from "@simplicate/ui";
import { lightColorTextWarning } from "@simplicate-software/design-tokens";
import classnames from "classnames";
import {
  ButtonHTMLAttributes,
  ComponentProps,
  MouseEvent,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
} from "react";
import styles from "./TopBarButton.module.scss";

type TopBarButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  PropsWithChildren<{
    dropdownComponent?: ReactNode;
    dropdownWidth?: ComponentProps<typeof Dropdown>["width"];
    dropdownHeight?: ComponentProps<typeof Dropdown>["height"];
    testId?: string;
    title?: string;
    badge?: ReactNode | number;
    onDropdownClose?: () => void;
  }>;

export const TopBarButton = ({
  badge = 0,
  children,
  dropdownComponent,
  dropdownWidth,
  dropdownHeight,
  onClick,
  testId,
  title,
  onDropdownClose,
  ...buttonProps
}: TopBarButtonProps) => {
  const badgeContent = typeof badge === "number" && badge > 0 ? badge : undefined;
  const badgeComponent = typeof badge !== "number" ? badge : undefined;

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const closeDropdown = useCallback(() => {
    setDropdownOpen(false);
    onDropdownClose?.();
  }, [onDropdownClose]);

  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      onClick?.(e);

      if (dropdownOpen) {
        closeDropdown();

        return;
      }

      setDropdownOpen(true);
    },
    [closeDropdown, dropdownOpen, onClick],
  );

  const keyboardListener = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Escape") {
        closeDropdown();
      }
    },
    [closeDropdown],
  );

  return (
    <div className={styles.container} onKeyDownCapture={keyboardListener}>
      <button onClick={handleClick} className={classnames(styles.button)} data-testid={testId} {...buttonProps}>
        {children}
        {badgeContent && (
          <div className={styles.badge}>
            <Counter value={badgeContent} background={lightColorTextWarning} testId={`${testId}-badge`} />
          </div>
        )}
        {badgeComponent && (
          <div data-testid={`${testId}-icon`} className={styles.icon}>
            {badge}
          </div>
        )}
      </button>
      {dropdownComponent && (
        <div className={styles.dropdownContainer}>
          <Dropdown
            hasPadding={false}
            width={dropdownWidth}
            height={dropdownHeight}
            title={title}
            isOpen={dropdownOpen}
            onClickOutside={closeDropdown}
            onKeyupOutside={closeDropdown}
          >
            {dropdownComponent}
          </Dropdown>
        </div>
      )}
    </div>
  );
};
