/* istanbul ignore file -- this is simple preset component */
import { useParams } from "react-router-dom";
import { DocumentEditor } from "./DocumentEditor";
import { DocumentEditorRouteParams } from "./types";

export const TemplateEditor = () => {
  const { id } = useParams<DocumentEditorRouteParams>();

  return <DocumentEditor mode="template" templateId={id} />;
};
