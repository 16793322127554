import { ColumnsLayout } from "@simplicate/ui";
import { PropsWithChildren } from "react";
import classes from "./IndexPage.module.scss";

export const IndexPageTemplate = ({ children }: PropsWithChildren) => {
  return <ColumnsLayout>{children}</ColumnsLayout>;
};

IndexPageTemplate.Title = function TitleSlot({ title }: { title: string }) {
  return (
    <ColumnsLayout.Slot>
      <h2 className={classes.indexPageTitle}>{title}</h2>
    </ColumnsLayout.Slot>
  );
};

IndexPageTemplate.Body = function BodySlot({ children }: PropsWithChildren) {
  return (
    <ColumnsLayout.Slot hasSubgridOnColumns className={classes.indexPageBody}>
      {children}
    </ColumnsLayout.Slot>
  );
};

IndexPageTemplate.BodySlot = function CardContainer({ children }: PropsWithChildren) {
  return (
    <ColumnsLayout.Slot
      onMediumScreens={6}
      onLargeScreens={4}
      onHugeScreens={3}
      hasSubgridOnRows
      className={classes.indexPageCard}
    >
      {children}
    </ColumnsLayout.Slot>
  );
};
