import { usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";
import { asModuleTheme } from "@simplicate/utils";

export const Crm = () => {
  const { t } = useTranslation("main_menu");
  const hasPermission = usePermission("crm", "view").granted;
  const hasInvoicingPermissions = usePermission("invoices", "view").granted;
  const themeClass = asModuleTheme("crm");

  if (!hasPermission) return null;

  return (
    <NavMenu.Item title={t("crm")} href="/crm" className={themeClass} icon="crm" testId="crm-menu-item">
      <NavMenu.SubItem href="/crm/organizations" title={t("crm_companies")} testId="crm-organizations-menu-item" />
      <NavMenu.SubItem href="/crm/persons" title={t("crm_persons")} testId="crm-persons-menu-item" />
      {hasInvoicingPermissions && (
        <NavMenu.SubItem href="/crm/debtors" title={t("crm_debtors")} testId="crm-debtors-menu-item" />
      )}
    </NavMenu.Item>
  );
};
