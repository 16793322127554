import { colorCommonGrey200 } from "@simplicate-software/design-tokens";
import { getContrastColor, capNumber } from "../../utils";
import styles from "./Counter.module.scss";

const COLOR_BACKGROUND: string = "--background";
const COLOR: string = "--color";

type CounterProps = {
  background?: string;
  value?: number | string;
  variant?: "default" | "light";
  size?: "medium" | "small";
  testId?: string;

  /**
   * Maximum value to be displayed. If the value exceeds the maximum, the maximum value will be displayed with a "+" sign.
   * If set to false, the value will be displayed as is.
   */
  max?: number | false;
};

export const Counter = ({
  value,
  background = colorCommonGrey200,
  size = "small",
  variant = "default",
  testId,
  max = 99,
}: CounterProps) => {
  const textColor = getContrastColor(background);

  if (max !== false) {
    const intValue = parseInt(value as string);

    value = capNumber(intValue, max);
  }

  if (!value) {
    return null;
  }

  return (
    <span
      className={`${styles.counter} ${styles[variant]} ${styles[size]}`}
      style={{ [COLOR]: textColor, [COLOR_BACKGROUND]: background }}
      data-testid={testId}
    >
      {value}
    </span>
  );
};
