import { usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";

export const ProjectsReportingMenuItem = () => {
  const { t } = useTranslation("main_menu");

  const hasProjectReportingPermission = usePermission("projects", "rapportage").granted;

  if (!hasProjectReportingPermission) {
    return null;
  }

  return (
    <NavMenu.SubItem title={t("projects_reporting")}>
      <NavMenu.SubItem
        href="/projects/reporting/revenue"
        title={t("projects_revenue_reporting")}
        testId="projects-revenue-reporting-menu-item"
      />
      <NavMenu.SubItem
        href="/projects/reporting/result"
        title={t("projects_result_reporting")}
        testId="projects-result-reporting-menu-item"
      />
      <NavMenu.SubItem
        href="/projects/reporting/forecasted-invoiced"
        title={t("projects_forecasted_invoiced_reporting")}
        testId="projects-forecasted-invoiced-menu-item"
      />
    </NavMenu.SubItem>
  );
};
