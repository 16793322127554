import { ConversionRecord } from "../types";
import { extractQueryParam, extractSelectedTab } from "./utils";

export const CRMRouteConversions: ConversionRecord = {
  "/crm/organization/view": (location) => {
    const suffix = extractSelectedTab(location, {
      acquisition: "sales",
      client: "invoicing",
      dossier: "documents",
      personsatthisorganization: "contacts",
    });

    return {
      status: "pending-id-conversion",
      locationWithId: (id) => `/crm/organizations/${id}/${suffix}`,
      queryArguments: {
        v1Identifier: extractQueryParam(location, "id"),
        entityName: "organization",
      },
    };
  },

  "/crm/person/view": (location) => {
    const suffix = extractSelectedTab(location, {
      acquisition: "sales",
      client: "invoices",
      dossier: "documents",
      personatorganizations: "contact-at",
      person_general: "general",
    });

    return {
      status: "pending-id-conversion",
      locationWithId: (id) => `/crm/persons/${id}/${suffix}`,
      queryArguments: {
        v1Identifier: extractQueryParam(location, "id"),
        entityName: "person",
      },
    };
  },

  "/v1/crm": (location) => {
    const tab = extractSelectedTab(location, {});

    return { status: "complete", location: `/crm/${tab}` };
  },
};
