/* c8 ignore start -- this file contains no logic */
/* istanbul ignore file -- this file contains no logic */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const HEADER_DATA_TAG = "HEADER_DATA";
export const LOCALE_DATA_TAG = "LOCALE_DATA";

const customBaseQuery = fetchBaseQuery({
  baseUrl: "/api/v2/",
  prepareHeaders: (headers) => {
    const currentLocale = localStorage.getItem("locale");

    if (currentLocale) {
      headers.set("Accept-Language", currentLocale);
    }

    return headers;
  },
});

export const simplicateApiV2 = createApi({
  baseQuery: customBaseQuery,
  endpoints: () => ({}),
  reducerPath: "apiV2",
  tagTypes: [HEADER_DATA_TAG, LOCALE_DATA_TAG],
});

export const invalidateTagsV2 = simplicateApiV2.util.invalidateTags;
/* c8 ignore stop */
