import { LegacyWebComponent } from "../../components";
import { RouteObject } from "../types";

export const businessIntelligenceRoutes: RouteObject[] = [
  {
    path: "business-intelligence",
    element: <LegacyWebComponent />,
    handle: { theme: "business-intelligence", headerTitle: "module_business_intelligence" },
  },
];
