import classnames from "classnames";
import { ReactNode } from "react";
import { Icon } from "../Icon";
import styles from "./Modal.module.scss";

type ModalProps = {
  title?: string;
  size?: "large" | "medium" | "small";
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  footerComponent?: ReactNode;
  testId?: string;
};

export const Modal = ({ title, isOpen, size = "small", onClose, children, footerComponent, testId }: ModalProps) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className={classnames(styles.modalOverlay)} data-testid={testId}>
      <div className={classnames(styles.modal, styles[size])}>
        {title ? <span className={classnames(styles.modalTitle)}>{title}</span> : null}
        <button
          className={classnames(styles.modalCloseButton)}
          onClick={onClose}
          data-testid={`${testId}-close-button`}
        >
          <Icon icon="times" />
        </button>
        <div className={classnames(styles.modalContentContainer)}>{children}</div>
        {footerComponent ? <div className={classnames(styles.modalFooterContainer)}>{footerComponent}</div> : null}
      </div>
    </div>
  );
};
