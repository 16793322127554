import { useId } from "react";
import styles from "./Toggle.module.scss";

type ToggleProps = {
  onChange: (newValue: boolean) => void;
  value: boolean;
  testId?: string;
  disabled?: boolean;
  label?: React.ReactNode | string;
};

export const Toggle = ({ onChange, value, testId, label, disabled = false }: ToggleProps) => {
  const id = useId();

  return (
    <label htmlFor={id} className={styles.switch}>
      <input
        id={id}
        className={styles.input}
        onChange={(event) => onChange(event.target.checked)}
        checked={value}
        type="checkbox"
        data-testid={testId}
        disabled={disabled}
      />
      <span className={styles.slider}></span>
      <span>{label}</span>
    </label>
  );
};
