import { useTranslation } from "@simplicate/translations";
import { Datepicker } from "@simplicate/ui";
import { memo } from "react";

type TimeframeEndDatepickerProps = {
  value: Date | undefined;
  minValue: Date | undefined;
  error: string | undefined;
  touched?: boolean;
  onChange: (date: Date | undefined) => void;
  disabled: boolean;
};

export const TimeframeEndDatePicker = memo(function TimeframeEndDatePicker({
  minValue,
  error,
  touched = false,
  disabled,
  ...props
}: TimeframeEndDatepickerProps) {
  const { t } = useTranslation("project_services");

  return (
    <Datepicker
      name="endDate"
      testId="end-date-datepicker"
      placeholder={t("choose_date")}
      label={t("end_date")}
      invalid={touched && error !== undefined}
      disabled={disabled}
      showButtonBar
      minDate={minValue}
      {...props}
    />
  );
});
