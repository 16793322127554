import { useTranslation } from "@simplicate/translations";
import { ServiceRowCell } from "../ServiceRowCell";
import styles from "./ServiceRowHeader.module.scss";

export const ServiceRowHeader = () => {
  const { t } = useTranslation("grouped_services_manager");

  return (
    <div className={styles.serviceRowHeader} data-testid="service-row-header">
      <ServiceRowCell variant="name">{t("column_labels.name")}</ServiceRowCell>
      <ServiceRowCell variant="number">{t("column_labels.hours_amount")}</ServiceRowCell>
      <ServiceRowCell variant="number">{t("column_labels.hours_budget")}</ServiceRowCell>
      <ServiceRowCell variant="number">{t("column_labels.purchase_budget")}</ServiceRowCell>
      <ServiceRowCell variant="number">{t("column_labels.price")}</ServiceRowCell>
      <ServiceRowCell variant="button"></ServiceRowCell>
    </div>
  );
};
