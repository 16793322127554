import { TFunction, useTranslation } from "@simplicate/translations";
import { ListItem, SkeletonLoader } from "@simplicate/ui";
import { useMemo } from "react";
import { useDashboardContext } from "../../../components/Dashboard";
import { FormattedValue } from "../../../components/FormattedValue";
import { AggregatorType, useAggregator } from "../../../data";
import { CubeDimension, Formatter } from "../../../types";
import styles from "./MultiKPIRow.module.scss";

export type MultiKPIRowProps = {
  dimension: CubeDimension;
  label: (t: TFunction<"insights">) => string;
  aggregation: AggregatorType;
  format?: Formatter;
};

export const MultiKPIRow = ({ dimension, label, aggregation, format }: MultiKPIRowProps) => {
  const { t } = useTranslation("insights");
  const {
    queryResult: { resultSet, isLoading },
  } = useDashboardContext();
  const aggregationFunction = useAggregator(dimension, aggregation);
  const value = useMemo(() => aggregationFunction(resultSet), [aggregationFunction, resultSet]);

  return (
    <ListItem className={styles.kpiRow}>
      <span>{label(t)}</span>
      {isLoading ? <SkeletonLoader /> : <FormattedValue value={value} formatter={format} />}
    </ListItem>
  );
};
