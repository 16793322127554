import { useGetCurrentUserPermissionsQuery, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";
import { asModuleTheme } from "@simplicate/utils";
import { memo } from "react";

export const EnvironmentManagement = memo(function EnvironmentManagement() {
  const { t } = useTranslation("main_menu");
  const { data } = useGetCurrentUserPermissionsQuery();
  const hasGeneralAccountPermission = usePermission("general", "account").granted;
  const themeClass = asModuleTheme("environment-management");

  const hasAnyManagePermission = data?.data?.some((p) => p.right_key === "manage") ?? false;

  return (
    (hasAnyManagePermission || hasGeneralAccountPermission) && (
      <NavMenu.Item
        href="/settings"
        className={themeClass}
        icon="environment-management"
        title={t("environment_management")}
        testId="environment-management-menu-item"
      />
    )
  );
});
