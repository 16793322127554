import { getApplicationInfo } from "./getApplicationInfo";

export function getDatadogSecrets() {
  const { env, version } = getApplicationInfo();

  return {
    applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    defaultPrivacyLevel: import.meta.env.VITE_DATADOG_PRIVACY_LEVEL,
    env,
    service: import.meta.env.VITE_DATADOG_SERVICE,
    sessionReplaySampleRate: Number(import.meta.env.VITE_DATADOG_SESSION_REPLAY_SAMPLE_RATE),
    sessionSampleRate: Number(import.meta.env.VITE_DATADOG_SESSION_SAMPLE_RATE),
    site: import.meta.env.VITE_DATADOG_SITE,
    trackLongTasks: Boolean(import.meta.env.VITE_DATADOG_TRACK_LONG_TASKS),
    trackResources: Boolean(import.meta.env.VITE_DATADOG_TRACK_RESOURCES),
    trackUserInteractions: Boolean(import.meta.env.VITE_DATADOG_TRACK_USER_INTERACTIONS),
    version,
  };
}
