import { Chart as ChartJS, ChartData, BarElement, CategoryScale, LinearScale } from "chart.js";
import { Bar } from "react-chartjs-2";
import { initialize } from "../../utils/initialize";
import styles from "./Bar.chart.module.scss";

initialize();

ChartJS.register(CategoryScale, LinearScale, BarElement);
// FIXME: SIM-38179: below three lines are commented out, due to build failures
// ChartJS.defaults.datasets.bar.maxBarThickness = 32; // size3
// ChartJS.defaults.datasets.bar.barPercentage = 0.9; // ChartJS default, preferred by designer
// ChartJS.defaults.datasets.bar.categoryPercentage = 0.9; // ChartJS default, preferred by designer

const DEFAULT_OPTIONS = {
  plugins: {
    legend: {
      align: "start",
      position: "bottom",
    },
  },
} as const;

type BarChartProps<TData extends number[], TLabel> = {
  data: ChartData<"bar", TData, TLabel>;
};

export const BarChart = <TData extends number[], TLabel>({ data }: BarChartProps<TData, TLabel>) => {
  return <Bar className={styles.chart} data={data} options={DEFAULT_OPTIONS} />;
};
