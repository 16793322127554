import { LOCALE_DATA_TAG, simplicateApiV2 } from "../components";
import { Response } from "./types";
import { writeToSessionStorage } from "./utils";

type Country = {
  country_code: string;
  id: string;
  language_code: string;
  name: string;
};

type Timezone = {
  description: string;
  id: string;
  location: string;
  timezone: string;
};

type HoursViewMode = "agenda" | "both" | "grid";

// TODO: move this to a "models" or "types" lib
export type User = {
  birthdate?: Date | null;
  country?: Country | null;
  email?: string | null;
  employee_id: string;
  family_name_prefix?: string | null;
  family_name?: string | null;
  first_name?: string | null;
  gender?: string | null;
  has_external_agenda_integration?: boolean | null;
  hours_view_mode?: HoursViewMode | null;
  initials?: string | null;
  is_account_owner?: boolean | null;
  is_authy_enabled?: boolean | null;
  is_blocked?: boolean | null;
  is_employee?: boolean | null;
  is_light_user?: boolean | null;
  is_lock_nav?: boolean | null;
  key_identifier?: string | null;
  name?: string | null;
  person_id?: string | null;
  timezone?: Timezone | null;
  username?: string | null;
};

export type UserPermission = {
  module_key: string;
  module_label: string;
  right_key: string;
  right_label: string;
  rightsgroup_label: string;
};

const endpoints = simplicateApiV2.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query<Response<User>, void>({
      query: () => ({
        url: "/users/user",
      }),
      providesTags: [LOCALE_DATA_TAG],
    }),
    getCurrentUserPermissions: builder.query<Response<UserPermission[]>, void>({
      query: () => ({
        url: "/users/rights",
        responseHandler: writeToSessionStorage("rights"),
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetCurrentUserQuery, useGetCurrentUserPermissionsQuery } = endpoints;
