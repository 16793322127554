import { ComponentProps, PropsWithChildren } from "react";
import { Announcements } from "../Announcements";
import { Exports } from "../Exports";
import { Help } from "../Help";
import { Notifications } from "../Notifications";
import { Profile } from "../Profile";
import { TopBarBackButton } from "../TopBarBackButton";
import styles from "./TopBar.module.scss";

type TopBarProps = Pick<ComponentProps<typeof Help>, "module"> &
  Pick<ComponentProps<typeof TopBarBackButton>, "backButtonTarget"> &
  PropsWithChildren;

export const TopBar = ({ children, backButtonTarget, module }: TopBarProps) => {
  return (
    <div className={styles.container} data-testid="top-bar-component">
      <div className={styles.backButton}>
        <TopBarBackButton backButtonTarget={backButtonTarget} testId="top-bar-back-button" />
      </div>
      <div className={styles.header}>
        <span>{children}</span>
      </div>
      <div className={styles.actionButtons}>
        <Exports />
        <Announcements />
        <Notifications />
        <Help module={module} />
        <Profile />
      </div>
    </div>
  );
};
