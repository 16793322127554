import { BackButtonBehavior } from "@simplicate/top-bar";
import { useLocation } from "react-router-dom";

export const StandardBackButtonButtonBehavior: BackButtonBehavior = () => {
  const { pathname } = useLocation();

  const pathnameParts = pathname.split("/");

  pathnameParts.pop();

  return {
    target: new URL(pathnameParts.join("/"), window.location.origin),
  };
};
