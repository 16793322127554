import { simplicateApiV1 } from "../components";

export type IntercomSettings =
  | {
      is_enabled: false;
      settings?: object;
    }
  | {
      is_enabled: true;
      settings: {
        app_id: string;
        user_id: string;
        name: string;
        email: string;
        user_hash: string;
      };
    };

const endpoints = simplicateApiV1.injectEndpoints({
  endpoints: (builder) => ({
    getIntercomSettings: builder.query<IntercomSettings, void>({
      query: () => ({
        url: "/v1/internal/environment/intercomSettings",
      }),
    }),
  }),
});

export const { useGetIntercomSettingsQuery } = endpoints;
