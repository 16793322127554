import { PropsWithChildren } from "react";
import styles from "./Footer.module.scss";

type FooterProps = PropsWithChildren<{
  testId?: string;
}>;

export const Footer = ({ testId, children }: FooterProps) => {
  return (
    <footer className={styles.footer} data-testid={`footer-${testId}`}>
      {children}
    </footer>
  );
};
