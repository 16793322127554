import classNames from "classnames";
import { ButtonHTMLAttributes, PropsWithChildren } from "react";
import { Link, LinkProps } from "react-router-dom";
import styles from "./Clickable.module.scss";

type ButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, "onClick"> & {
  to?: never;
};

type GenericProps = {
  className?: string;
  testId?: string;
  disabled?: boolean;
  title?: string;
};

type AnchorProps = Omit<LinkProps, "onClick"> & Required<Pick<LinkProps, "to">>;

export type ClickableProps = GenericProps & {
  onClick?: () => void;
} & (AnchorProps | ButtonProps);

function isLinkProps(props: ButtonProps | LinkProps): props is GenericProps & LinkProps {
  return (props as LinkProps).to !== undefined;
}

export function Clickable({
  children,
  testId,
  className,
  onClick,
  title,
  disabled,
  ...props
}: PropsWithChildren<ClickableProps>) {
  const commonProps = {
    "data-testid": testId,
    onClick,
    title,
    disabled,
  };

  const disabledProps = {
    "data-testid": testId,
    title,
    disabled,
  };

  if (isLinkProps(props)) {
    const anchorProps = props;

    if (disabled) {
      return (
        <span {...disabledProps} className={className}>
          {children}
        </span>
      );
    }

    return (
      <Link {...commonProps} {...anchorProps} className={classNames(styles.anchorReset, className)}>
        {children}
      </Link>
    );
  }

  props = props as ButtonProps;

  const buttonProps = props;

  return (
    <button {...commonProps} {...buttonProps} className={classNames(styles.buttonReset, className)}>
      {children}
    </button>
  );
}
