import { useLicenseGuard, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { TabBar, Tab } from "@simplicate/ui";
import { useParams } from "react-router-dom";
import { IFrameActionButtons } from "../../IFrameEnvironment/IFrameActionButtons";
import { MessageBusActionButtons } from "../../IFrameEnvironment/MessageBusActionButtons";

export const SalesTabs = () => {
  const { t } = useTranslation("tab_bar");

  const { id } = useParams<{ id: string }>();

  const hasViewSalesPermission = usePermission("acquisition", "view").granted;
  const { enabled: hasWorkflowsEnabled } = useLicenseGuard("workflows");

  if (!id || !hasViewSalesPermission) {
    return null;
  }

  return (
    <TabBar
      testId="sales"
      actions={
        <>
          <IFrameActionButtons />
          <MessageBusActionButtons />
        </>
      }
    >
      <Tab href={`/sales/${id}/overview`} title={t("overview")} />
      <Tab href={`/sales/${id}/general`} title={t("general")} />
      <Tab href={`/sales/${id}/services`} title={t("services")} />
      <Tab href={`/sales/${id}/quotes`} title={t("quotes")} />
      <Tab
        href={`/sales/${id}/tasks`}
        title={t("tasks")}
        disabled={!hasWorkflowsEnabled}
        disabledText={t("not_available_in_get_started_message")}
      />
      <Tab href={`/sales/${id}/documents`} title={t("documents")} />
    </TabBar>
  );
};
