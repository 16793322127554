import { simplicateApiV2 } from "../components";
import { createTagTypes } from "../tags";
import { Response } from "./types";

export type BetaFeature = {
  beta_feature: string;
  user_id: string;
  enabled: boolean;
};

type BetaFeaturePutBody = Omit<BetaFeature, "user_id">;

const betaFeatureTags = createTagTypes({ tagPrefix: "beta_features", tags: ["list"] });

const endpoints = simplicateApiV2.enhanceEndpoints({ addTagTypes: Object.values(betaFeatureTags) }).injectEndpoints({
  endpoints: (builder) => ({
    getBetaFeature: builder.query<Response<BetaFeature[]>, void>({
      query: () => ({
        url: "/users/betaFeatureFlag",
      }),
      providesTags: [betaFeatureTags.list],
    }),
    setBetaFeature: builder.mutation<BetaFeature, BetaFeaturePutBody>({
      query: (body) => ({
        url: "/users/betaFeatureFlag?operation=flag",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: [betaFeatureTags.list],
    }),
  }),
  overrideExisting: false,
});

export const { useGetBetaFeatureQuery, useSetBetaFeatureMutation } = endpoints;
