import { TFunction, useTranslation } from "@simplicate/translations";
import { Button, Datepicker, GroupedControls, Tag, TagList } from "@simplicate/ui";
import { addMonths, format } from "date-fns";
import { useMemo } from "react";
import { useDashboardContext, LabeledFilter } from "../../components/Dashboard";
import { DimensionValueSelect } from "../../components/DimensionValueSelect";
import { CubeDimension, cubeDimensionToKey } from "../../types";

export type DimensionFilterConfig = {
  valueDimension: CubeDimension;
  labelDimension: CubeDimension;
  placeholder?: (t: TFunction) => string;
  filterFormat?: (value: unknown, t: TFunction) => string;
};

type FilterWidgetProps = {
  dimensions: DimensionFilterConfig[];
};

export const FilterWidget = ({ dimensions }: FilterWidgetProps) => {
  const { t } = useTranslation("insights");
  const {
    actions: { removeFilter, resetState, setEndDate, setStartDate, applyFilterForDimensions },
    state: {
      filters,
      filterValues,
      dateRange: { start, end },
    },
  } = useDashboardContext();

  const dateRangeFilterLabel = useMemo(() => {
    const startDateLabel = format(start, "dd MMM yyyy");
    const endDateLabel = format(end, "dd MMM yyyy");

    return t("filters.time_filter", { start: startDateLabel, end: endDateLabel });
  }, [start, end, t]);

  const [min, max] = useMemo(() => {
    // Restrict the date range to 3 months to reduce the load the query will have on the database.
    // We are not sure what the exact impact will be, so it's better to tread on the side of caution.
    // TODO: Remove this restriction once we have a date-range-picker and better knowledge of the impact on performance.
    const min = addMonths(end, -3);
    const max = addMonths(start, 3);

    return [min, max] as const;
  }, [end, start]);

  return (
    <>
      <GroupedControls>
        <Datepicker value={start} onChange={setStartDate} minDate={min} maxDate={max} />
        <Datepicker value={end} onChange={setEndDate} minDate={min} maxDate={max} />
        {dimensions.map(({ labelDimension, valueDimension, placeholder, filterFormat }: DimensionFilterConfig) => (
          <DimensionValueSelect
            key={cubeDimensionToKey(valueDimension)}
            valueDimension={valueDimension}
            labelDimension={labelDimension}
            placeholder={placeholder?.(t)}
            onChange={(newValues) => {
              applyFilterForDimensions(
                valueDimension,
                newValues,
                filterFormat ? (value) => filterFormat(value, t) : undefined,
              );
            }}
            value={filterValues ? (filterValues[cubeDimensionToKey(valueDimension)] as string[]) : undefined}
          />
        ))}
      </GroupedControls>
      <TagList>
        <Tag text={dateRangeFilterLabel} />
        {filters.map((filter: LabeledFilter) => {
          return <Tag key={filter.label} text={filter.label} onClose={() => removeFilter(filter)} />;
        })}
        <Button
          variant="subtle"
          size="small"
          iconProps={{ icon: "times" }}
          text={t("filters.reset")}
          onClick={resetState}
        />
      </TagList>
    </>
  );
};
