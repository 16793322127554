import { simplicateApiV3 } from "../components";
import { createTagTypes } from "../tags";

export enum InvoiceMethod {
  fixed_price = "fixed_price",
  subscription = "subscription",
  time_and_expenses = "time_and_expenses",
}

export type SubscriptionCycle = "half_yearly" | "monthly" | "quarterly" | "yearly";

export type InvoiceMethodsResponse = {
  invoiceMethods: InvoiceMethod[];
};

const tags = createTagTypes({
  tagPrefix: "InvoiceMethod",
  tags: ["list"],
});

const endpoints = simplicateApiV3.enhanceEndpoints({ addTagTypes: Object.values(tags) }).injectEndpoints({
  endpoints: (builder) => ({
    getInvoiceMethods: builder.query<InvoiceMethodsResponse, void>({
      query: () => ({
        url: "/invoiceMethod.list",
      }),
      providesTags: [tags.list],
    }),
  }),
  overrideExisting: false,
});

export const { useGetInvoiceMethodsQuery } = endpoints;
