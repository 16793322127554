import { useTranslation } from "@simplicate/translations";
import { TabBar, Tab } from "@simplicate/ui";
import { useParams } from "react-router-dom";
import { MessageBusActionButtons } from "../../MessageBusActionButtons";

export const QuoteTemplateTabs = () => {
  const { t } = useTranslation("tab_bar");

  const { id } = useParams<{ id: string }>();

  if (!id) {
    return null;
  }

  return (
    <TabBar testId="settings-quote-templates-tab-bar" actions={<MessageBusActionButtons />}>
      <Tab href={`/settings/sales/templates/${id}/editor`} title={t("quote_editor")} />
      <Tab href={`/settings/sales/templates/${id}/email`} title={t("email_editor")} />
      <Tab href={`/settings/sales/templates/${id}/quoteportal`} title={t("quote_portal")} />
    </TabBar>
  );
};
