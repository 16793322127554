import {
  CRMRouteConversions,
  DashboardRouteConversions,
  EnvironmentManagementRouteConversions,
  HRMRouteConversions,
  HoursRouteConversions,
  InvoicesRouteConversions,
  ProjectsRouteConversions,
  SalesRouteConversions,
} from "./conversions";
import { ConversionRecord, ConversionResult } from "./types";

const urlReplacementRecord: ConversionRecord = {
  ...DashboardRouteConversions,
  ...CRMRouteConversions,
  ...HRMRouteConversions,
  ...SalesRouteConversions,
  ...ProjectsRouteConversions,
  ...HoursRouteConversions,
  ...InvoicesRouteConversions,
  ...EnvironmentManagementRouteConversions,
};

/**
 * Retains only the very first search query of the provided url; a necessary evil.
 *
 * Occassionally the backend will append a search query to an url that already contains a search query. The URL object
 * will treat everything after the very first question mark as the entiry search query, and split the individual search
 * params by the ampersand. Sometimes this will result in a search param such as `{ tab: "concept_invoice?id=7" }`
 * rather than `{ tab: "concept_invoice", id: 7 }`.
 */
function pruneDuplicateSearch(location: string): string {
  if (!location.includes("?")) {
    return location;
  }

  const [pathname, search] = location.split("?");

  return `${pathname}?${search}`;
}

/**
 * Converts yii routes to their react counterparts.
 * The pathname of the url is matched against a registry of known pathnames.
 * During the pattern matching phase the search query and fragment of the provided url are ignored.
 * @param location the yii route that requires conversion
 * @throws if the provided `location` cannot be parsed as an URL
 * @throws if the provided `location` can be matched against a known pathname, but the conversion fails
 */
export function iframeToReactUrl(location: string): ConversionResult {
  const parsedLocation = new URL(pruneDuplicateSearch(location), window.origin);
  const knownPaths = Object.keys(urlReplacementRecord);
  const matchedPath = knownPaths.find((path) => parsedLocation.pathname.endsWith(path));

  if (matchedPath) {
    /* istanbul ignore next*/
    const conversionResult = urlReplacementRecord[matchedPath]?.(parsedLocation);

    /* istanbul ignore next*/
    if (!conversionResult) {
      throw new Error(`Conversion for ${matchedPath} did not yield a result`);
    }

    return conversionResult;
  }

  return { status: "no-known-conversions" };
}
