import { User, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";

export const AllEmployeesMenuItem = ({ is_light_user = true }: User) => {
  const { t } = useTranslation("main_menu");
  const hasAllEmployeesViewPermission = usePermission("hrm", "viewall").granted;

  if (!hasAllEmployeesViewPermission || is_light_user) {
    return null;
  }

  return (
    <NavMenu.SubItem href="/hrm/all-employees" title={t("hrm_all_employees")} testId="hrm-all-employees-menu-item" />
  );
};
