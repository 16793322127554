import { useEnvironmentSetting } from "@simplicate/api-client";
import { NumberFormat, NumberFormatProps } from "./NumberFormat";

type CurrencyFormatProps = NumberFormatProps & { ccy?: string };

const defaultConfig = {
  decimalScale: 2,
  fixedDecimalScale: true,
  type: "text",
} satisfies NumberFormatProps;

const getCurrencyConfig = (
  ccy: string | undefined,
  displayType: NumberFormatProps["displayType"],
): Partial<NumberFormatProps> => {
  switch (ccy) {
    case "USD":
      return displayType === "input" ? { leftComponent: <span>$</span> } : { prefix: "$ " };

    default:
      return displayType === "input" ? { leftComponent: <span>€</span> } : { prefix: "€ " };
  }
};

export const CurrencyFormat = ({ ccy, ...props }: CurrencyFormatProps) => {
  const environmentCcy = useEnvironmentSetting("general", "ccy")?.value;

  const configuredProps = {
    ...defaultConfig,
    ...getCurrencyConfig(ccy ?? environmentCcy, props.displayType),
    ...props,
  };

  return <NumberFormat {...configuredProps} />;
};
