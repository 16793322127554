import { useEnvironmentSetting, useFeatureFlag, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";

/**
 * @deprecated The page this menu item points to has long been replaced. This menu item only exists for backwards compatibility.
 */
export const MileageMenuItem = () => {
  const { t } = useTranslation("main_menu");

  // This feature flag should hide the tab when enabled.
  const showMenuItem = useFeatureFlag("hours-hide-tab-register-mileage-old").enabled === false;

  const hasApproveAsSupervisorPermission = usePermission("hours", "approve_as_supervisor").granted;
  const hasGeneralApprovalPermission = usePermission("hours", "hours_approval").granted;
  const hasEitherRequiredPermission = hasApproveAsSupervisorPermission || hasGeneralApprovalPermission;

  const isEnabledInSettings = useEnvironmentSetting("environment", "is_show_mileage_tab")?.value === 1;

  if (!showMenuItem || !hasEitherRequiredPermission || !isEnabledInSettings) {
    return null;
  }

  return <NavMenu.SubItem href="/hours/mileage" title={t("legacy_hours_mileage")} testId="hours-mileage-menu-item" />;
};
