import {
  ChangeServiceTreeBody,
  Sales,
  Service,
  ServiceGroup,
  useChangeServiceTreeMutation,
  useDeleteSalesServiceMutation,
  useDuplicateServiceMutation,
  useGetSalesQuery,
  useDeleteServiceGroupMutation,
  skipToken,
  useAutoGroupServicesMutation,
} from "@simplicate/api-client";
import {
  GroupedServicesNodeModel,
  ServiceGroupNodeModel,
  ServiceGroupType,
  ServiceNodeModel,
  isServiceGroup,
} from "@simplicate/grouped-services-manager";
import { useTranslation } from "@simplicate/translations";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const UNGROUPED_GROUP_ID = "__ungrouped__";

export const useSalesServicesPageData = (saleId?: string) => {
  const [treeData, setTreeData] = useState<GroupedServicesNodeModel[]>();
  const { data } = useGetSalesQuery(/* istanbul ignore next */ saleId ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });
  const [deleteServiceGroup] = useDeleteServiceGroupMutation();
  const [duplicateService] = useDuplicateServiceMutation();
  const [deleteService] = useDeleteSalesServiceMutation();
  const [changeServiceTree] = useChangeServiceTreeMutation();
  const [autoGroupServices] = useAutoGroupServicesMutation();

  const navigate = useNavigate();
  const { t } = useTranslation("grouped_services_manager");
  const rootId = 0;

  const buildServiceNode = useCallback(
    (service: Service, parent: string) =>
      ({
        id: service.id,
        parent: parent,
        text: service.description,
        data: {
          hoursTotalAmount: service.hourTypeConfiguration.hourTypeTotals.hoursBudget,
          hoursTotalBudget: service.hourTypeConfiguration.hourTypeTotals.specifiedTotal,
          invoiceMethod: service.invoiceMethod,
          subscriptionCycle: service.subscriptionCycle,
          saleQuantity: service.saleQuantity,
          price: service.sellingPrice,
          purchaseTotalBudget: service.costTypeTotals.budget,
          deleteCallback: () => {
            void deleteService(service.id);
          },
          duplicateCallback: () => {
            void duplicateService({ serviceId: service.id });
          },
          editCallback: () => {
            if (service.id) {
              navigate(`/sales/${saleId}/service/${service.id}`);
            }
          },
        },
      }) satisfies ServiceNodeModel,
    [deleteService, duplicateService, navigate, saleId],
  );

  const buildServiceGroupNode = useCallback(
    (group: ServiceGroup, saleId: string) =>
      ({
        id: group.id,
        parent: rootId,
        droppable: true,
        text: group.name,
        data: {
          groupType: ServiceGroupType.NORMAL,
          deleteCallback: (shouldDeleteServices: boolean) => {
            void deleteServiceGroup({
              saleId: saleId,
              serviceGroupId: group.id,
              shouldDeleteServices,
            });
          },
        },
      }) satisfies ServiceGroupNodeModel,
    [deleteServiceGroup],
  );

  const buildGroupedServicesTree = useCallback(
    (sales: Sales): GroupedServicesNodeModel[] => {
      const groups = [
        {
          id: UNGROUPED_GROUP_ID,
          parent: rootId,
          droppable: true,
          text: t("ungrouped_services"),
          data: { groupType: ServiceGroupType.UNGROUPED },
        },
        ...sales.groupedServices.map((group) => buildServiceGroupNode(group, sales.id)),
      ];

      const ungroupedServices = sales.ungroupedServices.map((service) => buildServiceNode(service, UNGROUPED_GROUP_ID));

      const services = sales.groupedServices.flatMap((group) =>
        group.services.map((service) => buildServiceNode(service, group.id)),
      );

      return [...groups, ...ungroupedServices, ...services];
    },
    [buildServiceGroupNode, buildServiceNode, t],
  );

  const saveTreeStructure = useCallback(
    (treeData: GroupedServicesNodeModel[], saleId: string) => {
      // Process the groups first to preserve the order.
      const serviceGroups = treeData
        .filter((node) => isServiceGroup(node) && node.id !== UNGROUPED_GROUP_ID)
        .map((group) => ({
          groupId: group.id.toString(),
          serviceIds: treeData.filter((node) => node.parent === group.id).map((node) => node.id.toString()),
        }));

      const ungroupedServices = treeData
        .filter((node) => node.parent === UNGROUPED_GROUP_ID)
        .map((node) => node.id.toString());

      const payload: ChangeServiceTreeBody = {
        saleId: /* istanbul ignore next */ saleId ?? "",
        serviceGroupStructure: {
          structureForGroups: serviceGroups,
          ungroupedServiceIds: ungroupedServices,
        },
      };

      void changeServiceTree(payload);
    },
    [changeServiceTree],
  );

  useEffect(() => {
    if (data) {
      setTreeData(buildGroupedServicesTree(data));
    }
  }, [data, buildGroupedServicesTree]);

  const setAndSaveTreeData = (newTreeData: GroupedServicesNodeModel[]) => {
    /* istanbul ignore next */
    if (!newTreeData || !saleId) return;
    setTreeData(newTreeData);
    saveTreeStructure(newTreeData, saleId);
  };

  const groupServicesByInvoiceMethod = () => {
    if (saleId) {
      void autoGroupServices(saleId);
    }
  };

  return { treeData, setAndSaveTreeData, rootId, groupServicesByInvoiceMethod };
};
