import { useAppSelector } from "@simplicate/state";
import i18next from "i18next";
import { PropsWithChildren, useEffect } from "react";
import { initReactI18next, useTranslation } from "react-i18next";
import * as resources from "../../assets/locales";

const FALLBACK_LANGUAGE = "nl";

i18next
  .use(initReactI18next)
  .init({
    defaultNS: "translation",
    // also update tooling/translations/config/i18next-parser.config.json if you change this
    compatibilityJSON: "v3",
    lng: FALLBACK_LANGUAGE,
    fallbackLng: FALLBACK_LANGUAGE,
    supportedLngs: ["en", "nl", "ci"],
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    resources,
    cleanCode: true,
  })
  .catch(
    /* istanbul ignore next */ () => {
      /* c8 ignore next 2 */
      // TODO: implement error reporting
      console.error("Failed to initialize i18next -- translations will not work!");
    },
  );

export const TranslationsProvider = ({ children }: PropsWithChildren) => {
  const { locale } = useAppSelector(/* istanbul ignore next */ ({ state }) => state.locale);
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale).catch(
      /* istanbul ignore next */ () => {
        /* c8 ignore next 2 */
        // TODO: implement error reporting
        console.error(`Failed to change language to ${locale}`);
      },
    );
  }, [locale, i18n]);

  return children;
};
