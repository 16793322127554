import { format, isToday, isYesterday } from "date-fns";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const FULL_DATE_FORMAT = "dd MMM yyyy HH:mm";
const TIME_FORMAT = "HH:mm";

export function useElapsedTimeMessage() {
  const { t } = useTranslation("dates");

  return {
    format: useCallback(
      (date: Date): string =>
        isYesterday(date)
          ? t("yesterday", { time: format(date, TIME_FORMAT) })
          : isToday(date)
            ? t("today", { time: format(date, TIME_FORMAT) })
            : format(date, FULL_DATE_FORMAT),
      [t],
    ),
  };
}
