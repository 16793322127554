import { ColumnsLayout } from "@simplicate/ui";
import { Children, PropsWithChildren } from "react";
import styles from "./ProjectServicePage.template.module.scss";

export const ProjectServicePageTemplate = ({ children }: PropsWithChildren) => {
  return <ColumnsLayout>{children}</ColumnsLayout>;
};

const Alerts = ({ children }: PropsWithChildren) => {
  return Children.map(children, (child) => child && <ColumnsLayout.Slot>{child}</ColumnsLayout.Slot>);
};

const Title = ({ children }: PropsWithChildren) => {
  return <ColumnsLayout.Slot>{children}</ColumnsLayout.Slot>;
};

const Section = ({ children }: PropsWithChildren) => {
  return (
    <>
      <Separator />
      {children}
    </>
  );
};

const Separator = () => {
  return (
    <ColumnsLayout.Slot>
      <hr className={styles.separator} />
    </ColumnsLayout.Slot>
  );
};

const PropertiesBlock = ({ children }: PropsWithChildren) => {
  return (
    <ColumnsLayout.Slot onLargeScreens={8} hasSubgridOnColumns className={styles.optionsColumn}>
      {children}
    </ColumnsLayout.Slot>
  );
};

const PropertiesBlockColumn = ({ children }: PropsWithChildren) => {
  return (
    <ColumnsLayout.Slot onSmallScreens={12} onMediumScreens={6} onLargeScreens={4} className={styles.column}>
      {children}
    </ColumnsLayout.Slot>
  );
};

const OptionsBlock = ({ children }: PropsWithChildren) => {
  return (
    <ColumnsLayout.Slot
      hasSubgridOnColumns
      onSmallScreens={12}
      onMediumScreens={6}
      onLargeScreens={4}
      className={styles.optionsColumn}
    >
      {children}
    </ColumnsLayout.Slot>
  );
};

const OptionsBlockColumn = ({ children }: PropsWithChildren) => {
  return (
    <ColumnsLayout.Slot onSmallScreens={12} onMediumScreens={6} onLargeScreens={4}>
      {children}
    </ColumnsLayout.Slot>
  );
};

const TotalBlock = ({ children }: PropsWithChildren) => {
  return <ColumnsLayout.Slot className={styles.totalRow}>{children}</ColumnsLayout.Slot>;
};

const Content = ({ children }: PropsWithChildren) => {
  return <ColumnsLayout.Slot>{children}</ColumnsLayout.Slot>;
};

ProjectServicePageTemplate.Alerts = Alerts;
ProjectServicePageTemplate.Title = Title;
ProjectServicePageTemplate.Section = Section;
ProjectServicePageTemplate.PropertiesBlock = PropertiesBlock;
ProjectServicePageTemplate.PropertiesBlockColumn = PropertiesBlockColumn;
ProjectServicePageTemplate.OptionsBlock = OptionsBlock;
ProjectServicePageTemplate.OptionsBlockColumn = OptionsBlockColumn;
ProjectServicePageTemplate.TotalBlock = TotalBlock;
ProjectServicePageTemplate.Content = Content;
