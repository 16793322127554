import { useEnvironmentSetting, useGetJournalizeDetailsQuery, usePermission } from "@simplicate/api-client";
import { useAppSelector } from "@simplicate/state";
import { useTranslation } from "@simplicate/translations";
import { TabBar, Tab, Counter } from "@simplicate/ui";
import { lightColorTextWarning } from "@simplicate-software/design-tokens";
import { useEffect } from "react";
import { IFrameActionButtons } from "../../IFrameActionButtons";

const APPROVAL_BADGE_KEY = "GridInvoicesApproval";
const DRAFT_INVOICES_BADGE_KEY = "GridInvoicesConcept";

const useAccountingTabConfig = () => {
  const hasViewAccountingPermission = usePermission("invoices", "accountancy_apps").granted;
  const { FETCH_JOURNALIZE_COUNT } = useAppSelector(
    /* istanbul ignore next */ ({ state }) => state.iframe.latestMessages,
  );
  const { data, refetch } = useGetJournalizeDetailsQuery();
  const journalizeEnabled = data?.data.active ?? false;

  useEffect(() => {
    if (journalizeEnabled && FETCH_JOURNALIZE_COUNT) {
      void refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only need to refetch when the message is received, the content of the message is not relevant (as it is always empty)
  }, [journalizeEnabled, FETCH_JOURNALIZE_COUNT?.timestamp, refetch]);

  return { showAccountingTab: hasViewAccountingPermission && journalizeEnabled, badgeCount: data?.data.count };
};

export const InvoicingTabs = () => {
  const { t } = useTranslation("tab_bar");

  const tabBadges = useAppSelector(/* istanbul ignore next */ ({ state }) => state.iframe.tabBadges);
  const hasViewInvoicingPermission = usePermission("invoices", "view").granted;
  const hasViewProjectsPermission = usePermission("projects", "view").granted;
  const isInvoiceApprovalEnabled = useEnvironmentSetting("invoices", "is_use_invoice_approval")?.value === 1;
  const { showAccountingTab, badgeCount: accountingBadgeValue } = useAccountingTabConfig();

  if (!hasViewInvoicingPermission) {
    return null;
  }

  const approvalBadgeValue = tabBadges[APPROVAL_BADGE_KEY];
  const draftInvoicesBadgeValue = tabBadges[DRAFT_INVOICES_BADGE_KEY];

  return (
    <TabBar testId="invoicing" actions={<IFrameActionButtons />}>
      {hasViewProjectsPermission && <Tab href="/invoicing/ready-for-invoicing" title={t("ready-for-invoicing")} />}
      {isInvoiceApprovalEnabled && (
        <Tab
          href="/invoicing/approval"
          title={t("approval")}
          badge={approvalBadgeValue ? <Counter value={approvalBadgeValue} background={lightColorTextWarning} /> : null}
        />
      )}
      <Tab
        href="/invoicing/draft-invoices"
        title={t("draft-invoices")}
        badge={
          draftInvoicesBadgeValue ? (
            <Counter value={draftInvoicesBadgeValue} background={lightColorTextWarning} />
          ) : null
        }
      />
      <Tab href="/invoicing/outstanding" title={t("outstanding")} />
      <Tab href="/invoicing/all" title={t("all-invoices")} />
      {showAccountingTab && (
        <Tab
          href="/invoicing/accounting"
          title={t("accounting")}
          badge={
            accountingBadgeValue ? <Counter value={accountingBadgeValue} background={lightColorTextWarning} /> : null
          }
        />
      )}
    </TabBar>
  );
};
