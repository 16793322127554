import { useTranslation } from "@simplicate/translations";
import { Checkbox } from "@simplicate/ui";
import { memo } from "react";
import styles from "./InvoiceInInstallmentsCheckbox.module.scss";

type InvoiceInInstallmentsCheckboxProps = {
  value: boolean | undefined;
  onChange: (value: boolean) => void;
};

export const InvoiceInInstallmentsCheckbox = memo(function InvoiceInInstallmentsCheckbox({
  value = false,
  ...props
}: InvoiceInInstallmentsCheckboxProps) {
  const { t } = useTranslation("project_services");

  return (
    <div className={styles.invoiceInInstallmentsCheckboxContainer}>
      <Checkbox
        name="invoiceInInstallments"
        testId="invoice-in-installments-checkbox"
        label={t("invoice_in_installments")}
        value={value}
        {...props}
      />
    </div>
  );
});
