import { TFunction, useTranslation } from "@simplicate/translations";
import { Tag } from "@simplicate/ui";
import { Module } from "@simplicate/utils";
import {
  colorCommonBrand100,
  colorCommonGrey100,
  colorModulesBlue100,
  colorModulesCobalt100,
  colorModulesGreen100,
  colorModulesNavy100,
  colorModulesOrange100,
  colorModulesPink100,
  colorModulesPurple100,
  colorModulesRed100,
  colorModulesYellow100,
} from "@simplicate-software/design-tokens";

const COLORS: Record<Module, string> = {
  dashboard: colorCommonBrand100,
  crm: colorModulesPurple100,
  hrm: colorModulesPink100,
  sales: colorModulesRed100,
  projects: colorModulesOrange100,
  "resource-planner": colorModulesYellow100,
  hours: colorModulesGreen100,
  invoicing: colorModulesBlue100,
  "business-intelligence": colorModulesNavy100,
  insights: colorModulesCobalt100,
  "environment-management": colorCommonGrey100,
};

// TODO: move this as a utitlity function to @simplicate/translations
// istanbul ignore next -- Switch argument is strictly typed, with no default.
// eslint-disable-next-line complexity -- It's a switch statement, it's supposed to be complex
function translateModule(module: Module, t: TFunction<"general">) {
  switch (module) {
    case "dashboard":
      return t("modules.dashboard");
    case "crm":
      return t("modules.crm");
    case "hrm":
      return t("modules.hrm");
    case "sales":
      return t("modules.sales");
    case "projects":
      return t("modules.projects");
    case "resource-planner":
      return t("modules.resource_planner");
    case "hours":
      return t("modules.hours");
    case "insights":
      return t("modules.insights");
    case "invoicing":
      return t("modules.invoicing");
    case "business-intelligence":
      return t("modules.business_intelligence");
    case "environment-management":
      return t("modules.environment_management");
  }
}

export const ModuleTag = ({ module }: { module: Module }) => {
  const { t } = useTranslation("general");

  const textValue = translateModule(module, t);

  return <Tag text={textValue} variant="color" size="small" color={COLORS[module]} />;
};
