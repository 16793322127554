import { BinaryFilter } from "@cubejs-client/core";
import { endOfWeek, startOfWeek } from "date-fns";
import { CubeDimension, cubeDimensionToKey } from "../../types";

export type LabeledFilter = {
  label: string;
  format?: (value: string) => string;
  filter: BinaryFilter;
};

export type FilterOption = {
  label: string;
  value: string;
};

type FilterValues = {
  [key: string]: string[];
};

export type DashboardState = {
  filters: LabeledFilter[];
  filterValues: FilterValues;
  search: string;
  order?: {
    key: string;
    direction: "asc" | "desc";
  };
  dateRange: {
    start: Date;
    end: Date;
  };
  cubeQuery?: {
    filters: BinaryFilter[];
    order?: { [key: string]: "asc" | "desc" };
  };
};

type AddFilterAction = {
  type: "ADD_FILTER";
  filter: BinaryFilter;
  label: string;
};

type ResetAction = {
  type: "RESET";
};

type OrderByAction = {
  type: "ORDER_BY";
  key: string;
};

type ApplyFilterForDimensionsAction = {
  type: "APPLY_FILTER_FOR_DIMENSION";
  dimension: CubeDimension;
  format?: (value: string) => string;
  values: { value: string; label: string }[];
};

type RemoveFilterAction = {
  type: "REMOVE_FILTER";
  filter: LabeledFilter;
};

type SetSearchAction = {
  type: "SET_SEARCH";
  search: string;
};

type SetStartDateAction = {
  type: "SET_START_DATE";
  date: Date;
};

type SetEndDateAction = {
  type: "SET_END_DATE";
  date: Date;
};

export type ReducerAction =
  | AddFilterAction
  | ApplyFilterForDimensionsAction
  | OrderByAction
  | RemoveFilterAction
  | ResetAction
  | SetEndDateAction
  | SetSearchAction
  | SetStartDateAction;

export function createDefaultState(): DashboardState {
  return {
    filters: [],
    filterValues: {},
    search: "",
    dateRange: {
      start: startOfWeek(new Date()),
      end: endOfWeek(new Date()),
    },
  };
}

function buildOrderingState(
  { order }: Pick<DashboardState, "order">,
  action: OrderByAction,
): Pick<DashboardState, "order"> {
  if (!order) {
    return { order: { key: action.key, direction: "asc" } };
  }

  const { key, direction } = order;

  return {
    order: {
      key: action.key,
      direction: key === action.key && direction === "asc" ? "desc" : "asc",
    },
  };
}

const buildLabeledFilter = (
  label: string,
  value: string,
  dimension: CubeDimension,
  format?: (value: string) => string,
) => {
  const key = cubeDimensionToKey(dimension);
  const formattedLabel = format?.(label) ?? label;

  return {
    label: formattedLabel,
    filter: {
      member: key,
      operator: "equals" as const,
      values: [value],
    },
  };
};

function applyFilterForDimensions(state: DashboardState, action: ApplyFilterForDimensionsAction) {
  const filterValues = {
    ...state.filterValues,
    [cubeDimensionToKey(action.dimension)]: action.values.map(({ value }) => value),
  };
  const filters = [
    ...state.filters.filter((f) => f.filter.member !== cubeDimensionToKey(action.dimension)),
    ...action.values.map((value) => buildLabeledFilter(value.label, value.value, action.dimension, action.format)),
  ];

  return {
    ...state,
    filters,
    filterValues,
  };
}

function removeFilter(state: DashboardState, action: RemoveFilterAction) {
  const filters = state.filters.filter((filter) => filter.label !== action.filter.label);
  const filterValues = Object.fromEntries(
    Object.entries(state.filterValues)
      .map(([key, values]) => {
        if (key === action.filter.filter.member) {
          return [key, values.filter((value) => !action.filter.filter.values.includes(value))] as const;
        }

        return [key, values] as const;
      })
      .filter(([, values]) => values.length > 0),
  );

  return {
    ...state,
    filters,
    filterValues,
  };
}

function dashboardReducer(state: DashboardState, action: ReducerAction): Omit<DashboardState, "cubeQuery"> {
  switch (action.type) {
    case "APPLY_FILTER_FOR_DIMENSION":
      return applyFilterForDimensions(state, action);

    case "ADD_FILTER":
      return {
        ...state,
        filters: [...state.filters, { filter: action.filter, label: action.label }],
      };

    case "RESET":
      return {
        ...state,
        ...createDefaultState(),
      };

    case "ORDER_BY":
      return {
        ...state,
        ...buildOrderingState(state, action),
      };

    case "REMOVE_FILTER":
      return removeFilter(state, action);

    case "SET_END_DATE":
      return {
        ...state,
        dateRange: {
          ...state.dateRange,
          end: action.date,
        },
      };

    case "SET_SEARCH":
      return {
        ...state,
        search: action.search,
      };

    case "SET_START_DATE":
      return {
        ...state,
        dateRange: {
          ...state.dateRange,
          start: action.date,
        },
      };
  }
}

export function dashboardStateReducer(state: DashboardState, action: ReducerAction): DashboardState {
  const newState = dashboardReducer(state, action);

  return {
    ...newState,
    cubeQuery: {
      filters: newState.filters.map(({ filter }) => filter),
      order: newState.order ? { [newState.order.key]: newState.order.direction } : undefined,
    },
  };
}
