import { asModuleTheme } from "@simplicate/utils";
import { useMemo } from "react";
import { RouteHandle } from "../../routing/types";
import { useMatches } from "../../routing/useMatches";

export const useRouteConfig = () => {
  const matches = useMatches();

  return useMemo(() => {
    const reducedRouteHandle: Omit<RouteHandle, "permissions"> = matches.reduce(
      (accumulator: RouteHandle, currentMatch) => ({
        ...accumulator,
        ...currentMatch.handle,

        // Always use the first theme occurance
        theme: accumulator.theme ?? currentMatch.handle?.theme,

        // Permissions are not relevant here
        permissions: undefined,
      }),
      {},
    );

    return {
      ...reducedRouteHandle,
      module: reducedRouteHandle.theme,
      theme: asModuleTheme(reducedRouteHandle.theme),
    };
  }, [matches]);
};
