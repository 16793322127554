import { showToast } from "@simplicate/ui";
import { MessageBus } from "@simplicate/utils";
import { useEffect } from "react";
import { useErrorBoundaryContext } from "../ErrorBoundary";

export const useNotificationListener = () => {
  const { reportError } = useErrorBoundaryContext();

  useEffect(
    () =>
      MessageBus.subscribe("notification", ({ error, ...notification }) => {
        showToast(notification);

        if (error) {
          reportError(error);
        }
      }),
    [reportError],
  );
};
