import { simplicateApiV1 } from "../components";

export type LoggedIn = {
  type: "guest" | "user";
  expiration_date: string | null;
};

const endpoints = simplicateApiV1.injectEndpoints({
  endpoints: (builder) => ({
    getLoggedIn: builder.query<LoggedIn, void>({
      query: () => ({
        url: "/site/loggedInWithExpiration",
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetLoggedInQuery } = endpoints;
