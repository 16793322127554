import { useCallback, useMemo } from "react";
import { CubeDimension } from "../../../types";
import { Aggregator, AggregatorType } from "./aggregators.types";
import { buildAverageAggregator } from "./average";
import { buildCountAggregator } from "./count";
import { buildMaxAggregator } from "./max";
import { buildMinAggregator } from "./min";
import { buildSumAggregator } from "./sum";

const AGGREGATORS: Record<AggregatorType, <TRecord>(dimension: CubeDimension) => Aggregator<TRecord>> = {
  average: buildAverageAggregator,
  count: buildCountAggregator,
  max: buildMaxAggregator,
  min: buildMinAggregator,
  sum: buildSumAggregator,
};

function buildAggregator<TRecord>(dimension: CubeDimension, aggregator: AggregatorType): Aggregator<TRecord> {
  return AGGREGATORS[aggregator](dimension);
}

export const useAggregator = <TRecord>(dimension: CubeDimension, aggregator: AggregatorType): Aggregator<TRecord> => {
  const aggregatorFunction = useMemo(() => buildAggregator(dimension, aggregator), [dimension, aggregator]);

  return useCallback((resultSet) => aggregatorFunction(resultSet), [aggregatorFunction]);
};
