import { NodeModel } from "@minoru/react-dnd-treeview";
import { InvoiceMethod, SubscriptionCycle } from "@simplicate/api-client";
import { Money } from "@simplicate/api-client/src/types/money";

export type ServiceData = {
  invoiceMethod: InvoiceMethod;
  subscriptionCycle: SubscriptionCycle | null;
  saleQuantity?: number;
  price?: Money;
  hoursTotalAmount?: number;
  hoursTotalBudget?: Money;
  purchaseTotalBudget?: Money;
  duplicateCallback?: () => void;
  deleteCallback?: () => void;
  editCallback?: () => void;
};

export enum ServiceGroupType {
  NORMAL = "normal",
  UNGROUPED = "ungrouped",
}

export type ServiceGroupData = {
  groupType?: ServiceGroupType;
  deleteCallback?: (shouldDeleteServices: boolean) => void;
  autoGroupCallback?: () => void;
};

export type ServiceNodeModel = NodeModel<ServiceData>;
export type ServiceGroupNodeModel = NodeModel<ServiceGroupData>;
export type GroupedServicesNodeModel = NodeModel<ServiceData | ServiceGroupData>;
