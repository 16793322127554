import { ReactNode } from "react";
import { toast as toastify, type ToastOptions } from "react-toastify";
import { Toast, type ToastProps } from "./Toast";
import styles from "./Toast.module.scss";

type ToastWithMessage = Omit<ToastProps, "children"> & {
  content?: never;
  message: string;
};

type ToastWithContent = Omit<ToastProps, "children"> & {
  content: ReactNode;
  message?: never;
};

type ShowToastParameters = {
  options?: Omit<ToastOptions, "autoClose" | "draggable"> & {
    /**
     * Duration in milliseconds for the toast to be visible.
     * If set to `0` or `undefined`, the toast will not auto-dismiss.
     * Defaults to 10 seconds (`10_000 ms`)
     */
    duration?: number;
    /**
     * When true, the user can dismiss the toast by dragging it away.
     *
     * @note
     * Setting this props to `false` in combination with `duration` to `0` will result in an un-dismissible toast
     */
    dismissible?: boolean;
  };
} & (ToastWithContent | ToastWithMessage);

export function showToast({ options, ...toastProps }: ShowToastParameters) {
  const { duration = 10_000, dismissible, ...otherOptions } = options ?? {};
  const { closeToast, ...rest } = toastProps;

  const children =
    toastProps.message !== undefined ? (
      <span className={styles.message}>{toastProps.message}</span>
    ) : (
      <div className={styles.content}>{toastProps.content}</div>
    );

  toastify(
    <Toast {...rest} closeToast={dismissible ? closeToast : undefined}>
      {children}
    </Toast>,
    {
      ...otherOptions,
      autoClose: duration && duration !== 0 ? duration : false,
      draggable: dismissible,
    },
  );
}
