import { useTranslation } from "@simplicate/translations";
import { Tab, TabBar } from "@simplicate/ui";
import { useParams } from "react-router-dom";
import { MessageBusActionButtons } from "../../MessageBusActionButtons";

export const ContentBlockEditorTabs = () => {
  const { t } = useTranslation("tab_bar");

  const { id } = useParams<{ id: string }>();

  if (!id) {
    return null;
  }

  return (
    <TabBar testId="settings-content-block-editor-tab-bar" actions={<MessageBusActionButtons />}>
      <Tab href={`/settings/sales/quotecontentblocks/${id}/editor`} title={t("content_block_editor")} />
    </TabBar>
  );
};
