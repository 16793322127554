import { useLicenseGuard, usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";
import { asModuleTheme } from "@simplicate/utils";
import classNames from "classnames";
import styles from "./Dashboard.module.scss";

export const Dashboard = () => {
  const { t } = useTranslation("main_menu");
  const { enabled: hasDashboardModuleLicense } = useLicenseGuard("dashboard_module");
  const hasAllWorkflowsPermission = usePermission("dossier", "all_tasks").granted;
  const themeClass = asModuleTheme("dashboard");

  return !hasDashboardModuleLicense ? (
    <NavMenu.Item
      title=""
      className={classNames(themeClass, styles.nonInteractive)}
      icon="logo"
      large
      dark
      testId="dashboard-menu-item"
    />
  ) : (
    <NavMenu.Item
      title={t("dashboard")}
      href="/dashboard"
      className={themeClass}
      icon="logo"
      large
      dark
      testId="dashboard-menu-item"
    >
      <NavMenu.SubItem
        href="/dashboard/my-tasks"
        title={t("dashboard_my_workflows")}
        testId="dashboard-my-workflows-menu-item"
      />
      {hasAllWorkflowsPermission && (
        <NavMenu.SubItem
          href="/dashboard/all-tasks"
          title={t("dashboard_all_workflows")}
          testId="dashboard-all-workflows-menu-item"
        />
      )}
    </NavMenu.Item>
  );
};
