import { ResultSet } from "@cubejs-client/core";
import { CubeDimension, cubeDimensionToKey } from "../../../types";
import { Aggregator } from "./aggregators.types";

export function buildMaxAggregator<T>(dimension: CubeDimension): Aggregator<T> {
  return (resultSet: ResultSet | null) =>
    !resultSet
      ? undefined
      : Math.max(...resultSet.tablePivot().map((row) => Number(row[cubeDimensionToKey(dimension)])));
}
