import { useTranslation } from "@simplicate/translations";
import { ActionDropdown, Icon } from "@simplicate/ui";
import { lightColorIconError } from "@simplicate-software/design-tokens";

type CostTypeActionDropdownProps = {
  identifier: string;
  isInvoiceable?: boolean;
  onClickEdit: (identifier: string) => void;
  onClickToggleIsInvoiceable?: (identifier: string) => void;
  onClickRemove: (identifier: string) => void;
  disabled?: boolean;
};

export const CostTypeActionDropdown = ({
  identifier,
  onClickEdit,
  onClickRemove,
  disabled = false,
  isInvoiceable = false,
  onClickToggleIsInvoiceable,
}: CostTypeActionDropdownProps) => {
  const { t } = useTranslation("project_services");

  return (
    <ActionDropdown
      testId="cost-type-actions"
      button={
        <ActionDropdown.Button
          iconProps={{ icon: "ellipsisVertical" }}
          variant="subtle"
          testId="cost-type-actions-button"
          disabled={disabled}
        />
      }
    >
      <ActionDropdown.Action onClick={() => onClickEdit(identifier)}>
        <Icon icon="pen" />
        <span>{t("cost_type_edit_label")}</span>
      </ActionDropdown.Action>

      {onClickToggleIsInvoiceable && (
        <ActionDropdown.Action onClick={() => onClickToggleIsInvoiceable(identifier)}>
          <Icon icon="fileInvoice" />
          {isInvoiceable ? t("set_is_not_invoiceable") : t("set_is_invoiceable")}
        </ActionDropdown.Action>
      )}

      <ActionDropdown.Action onClick={() => onClickRemove(identifier)}>
        <Icon icon="trash" color={lightColorIconError} />
        <span>{t("remove_from_grid")}</span>
      </ActionDropdown.Action>
    </ActionDropdown>
  );
};
