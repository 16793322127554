import { Response } from "../../types";

function asUtcIsoString<TObject extends object>(record: TObject, keys: string[]): TObject {
  return Object.fromEntries(
    Object.entries(record).map(([key, value]) => {
      if (keys.includes(key) && typeof value === "string") {
        const parsedDate = new Date(value + "Z");

        if (!isNaN(parsedDate.valueOf())) {
          return [key, new Date(value + "Z").toISOString()];
        }
      }

      return [key, value];
    }),
  ) as TObject;
}

export const transformToIsoDates =
  (keys: string[]) =>
  <TRecord extends object>(response: Response<TRecord[]>) => ({
    ...response,
    data: response.data.map((record) => asUtcIsoString(record, keys)),
  });
