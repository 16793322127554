/* c8 ignore start */
/* istanbul ignore file */
/* eslint-disable import/no-unused-modules -- root component is loaded through document header */
import { createRoot } from "react-dom/client";
import { App } from "./App";

const rootEl = document.getElementById("root");

rootEl && createRoot(rootEl).render(<App />);
/* c8 ignore end */
