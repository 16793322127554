import { Navigate } from "react-router-dom";
import { IFrameEnvironment, LegacyWebComponent } from "../../components";
import { RouteObject } from "../types";

export const hoursRoutes: RouteObject<"hours">[] = [
  {
    path: "hours",
    handle: {
      headerTitle: "module_hours",
      theme: "hours",
      permissions: [
        {
          module_key: "hours",
          right_key: "view",
        },
      ],
    },
    children: [
      {
        index: true,
        element: <Navigate to="register" replace />,
      },
      {
        path: "register",
        element: <LegacyWebComponent />,
      },
      {
        path: "agenda",
        element: <IFrameEnvironment src="/v1/hours#agenda" />,
      },
      {
        path: "timesheet",
        element: <IFrameEnvironment src="/v1/hours#register_hours" />,
      },
      {
        path: "mileage",
        element: <IFrameEnvironment src="/v1/hours#registermileage" />,
      },
      {
        path: "declarations",
        element: <IFrameEnvironment src="/v1/hours#registercosts" />,
      },
      {
        path: "project",
        handle: {
          permissions: [
            {
              module_key: "hours",
              right_key: "hours_approval_project",
            },
          ],
        },
        element: <IFrameEnvironment src="/v1/hours#projectapproval" />,
      },
      {
        path: "review",
        handle: {
          permissions: [
            {
              module_key: "hours",
              right_key: "hours_approval",
            },
            {
              module_key: "hours",
              right_key: "approve_as_supervisor",
            },
          ],
        },
        element: <LegacyWebComponent />,
      },
      {
        path: "planning",
        element: <LegacyWebComponent />,
      },
      {
        path: "reporting",
        handle: {
          headerTitle: "hours_reporting",
          permissions: [
            {
              module_key: "hours",
              right_key: "hoursrapportage",
            },
          ],
        },
        element: <IFrameEnvironment src="/v1/hours#rapportage" />,
      },
    ],
  },
];
