import { FC, PropsWithChildren } from "react";

export type ComponentAndProps = [FC<PropsWithChildren<object>>, object?];

type ComposeComponentProps = PropsWithChildren<{
  items: ComponentAndProps[];
}>;

export const ComposeComponents = ({ items, children }: ComposeComponentProps) => {
  return items.reduceRight((acc, [Component, props]) => <Component {...props}>{acc}</Component>, children);
};
