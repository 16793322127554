import {
  skipToken,
  useFeatureFlag,
  useGetProjectQuery,
  useGetProjectServicesQuery,
  useLicenseGuard,
  usePermission,
} from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { TabBar, Tab } from "@simplicate/ui";
import { ReactNode } from "react";
import { useParams } from "react-router-dom";
import { IFrameActionButtons } from "../../../IFrameActionButtons";
import { useProjectPurchaseTabPermissions } from "../useProjectPurchaseTabPermissions";
import { useProjectReverseBillingTabPermissions } from "../useProjectReverseBillingTabPermissions";

const useProjectDetailReverseBillingTab = (projectId: string | undefined) => {
  const { showReverseBillingTab, disableReverseBillingTab } = useProjectReverseBillingTabPermissions();

  const { data } = useGetProjectQuery(projectId ?? skipToken);

  const projectUsesReverseBilling = data?.data.is_reverse_billing ?? false;
  const projectUsesInvoiceApproval = data?.data.is_invoice_approval ?? false;

  return {
    showReverseBillingTab: showReverseBillingTab && projectUsesReverseBilling && !projectUsesInvoiceApproval,
    disableReverseBillingTab,
  };
};

const useProjectPlanningRights = () => {
  const hasPlanningViewPermission = usePermission("resourceplanner", "view").granted;
  const hasResourcePlannerEnabled = useFeatureFlag("resource-planner").enabled;

  return { showProjectPlanningTab: hasPlanningViewPermission && hasResourcePlannerEnabled };
};

export const ProjectDetailsPageTabs = ({ actionButtons }: { actionButtons?: ReactNode }) => {
  const { t } = useTranslation("tab_bar");
  const { id } = useParams<{ id: string }>();

  const { showProjectPlanningTab } = useProjectPlanningRights();

  const { showProjectPurchaseTab, disableProjectPurchaseTab } = useProjectPurchaseTabPermissions();
  const { showReverseBillingTab, disableReverseBillingTab } = useProjectDetailReverseBillingTab(id);

  const { data } = useGetProjectServicesQuery(id ?? skipToken);

  const projectUsesInstallments = data?.data.some(({ invoice_in_installments }) => invoice_in_installments) ?? false;

  const { enabled: hasWorkflowsEnabled } = useLicenseGuard("workflows");

  const hasProjectsViewPermission = usePermission("projects", "view").granted;

  if (!id || !hasProjectsViewPermission) {
    return;
  }

  return (
    <TabBar
      testId="project-details"
      actions={
        <>
          <IFrameActionButtons />
          {actionButtons}
        </>
      }
    >
      <Tab href={`/projects/${id}/general`} title={t("project-general")} />
      <Tab href={`/projects/${id}/services`} title={t("services")} />
      {showProjectPlanningTab && <Tab href={`/projects/${id}/planning`} title={t("planning")} />}
      {projectUsesInstallments && <Tab href={`/projects/${id}/installments`} title={t("installments")} />}
      <Tab href={`/projects/${id}/hours`} title={t("hours")} />
      {showProjectPurchaseTab && (
        <Tab
          href={`/projects/${id}/costs`}
          title={t("costs")}
          disabled={disableProjectPurchaseTab}
          disabledText={t("not_available_in_license_message")}
        />
      )}
      {showReverseBillingTab && (
        <Tab
          href={`/projects/${id}/reverse-billing`}
          title={t("reverse-billing")}
          disabled={disableReverseBillingTab}
          disabledText={t("not_available_in_license_message")}
        />
      )}
      <Tab href={`/projects/${id}/invoices`} title={t("invoices")} />
      <Tab
        href={`/projects/${id}/tasks`}
        title={t("tasks")}
        disabled={!hasWorkflowsEnabled}
        disabledText={t("not_available_in_get_started_message")}
      />
      <Tab href={`/projects/${id}/documents`} title={t("documents")} />
      <Tab href={`/projects/${id}/timeline`} title={t("timeline")} />
    </TabBar>
  );
};
