import { useGetNotificationsQuery } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { EmptyState, List } from "@simplicate/ui";
import { Notification } from "../Notification";

export const NotificationList = () => {
  const { data, isSuccess } = useGetNotificationsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { t } = useTranslation("top_bar");

  return (
    <List
      emptyComponent={<EmptyState message={t("no_notifications")} />}
      isEmpty={(data?.data.length ?? 0) === 0}
      isLoading={!isSuccess}
    >
      {data?.data.map(
        /* istanbul ignore next */ (notification) => <Notification key={notification.id} {...notification} />,
      )}
    </List>
  );
};
