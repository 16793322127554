import { useTranslation } from "@simplicate/translations";
import { Datepicker } from "@simplicate/ui";
import { memo } from "react";

type TimeframeStartDatepickerProps = {
  value: Date | undefined;
  error: string | undefined;
  touched?: boolean;
  onChange: (date: Date | undefined) => void;
  disabled: boolean;
};

export const TimeframeStartDatePicker = memo(function TimeframeStartDatePicker({
  error,
  touched = false,
  disabled,
  ...props
}: TimeframeStartDatepickerProps) {
  const { t } = useTranslation("project_services");

  return (
    <Datepicker
      name="startDate"
      testId="start-date-datepicker"
      placeholder={t("choose_date")}
      label={t("start_date")}
      invalid={touched && error !== undefined}
      disabled={disabled}
      showButtonBar
      readOnlyInput={false}
      {...props}
    />
  );
});
