import { useAppDispatch, IframeEvent } from "@simplicate/state";
import { useTranslation } from "@simplicate/translations";
import { showToast } from "@simplicate/ui";
import { format } from "date-fns";
import { useEffect, RefObject } from "react";

/* istanbul ignore next -- these events do not work properly in jest, this should be tested through other means */
export const useProjectReportCache = (iFrameRef: RefObject<HTMLIFrameElement>) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("general");

  useEffect(() => {
    const onMessageReceived = (event: Event) => {
      // @ts-expect-error -- detail does not exist on default Event type. This is not the default Event type though.
      const message = event.detail as IframeEvent;

      const buildCachedReport = message.params.find((m) => m.action === "BUILD_REPORT_CACHE");

      if (buildCachedReport) {
        const buildCachedReportDate = buildCachedReport?.payload?.date;

        const date = buildCachedReportDate ? new Date(buildCachedReportDate) : undefined;
        const dateParams = date ? { date: format(date, "yyyy-MM-dd") } : undefined;

        fetch(`/v1/reporting/process/buildCache?q=${JSON.stringify(dateParams)}`, {
          method: "POST",
        })
          .then((response) => response.json())
          .then((response: { data: { status: number } }) => {
            const { data } = response;

            buildCachedReport?.payload?.callback?.(data);
            if (data.status !== 2) {
              showToast({
                message: t("report_cache_in_progress"),
              });
            }
          })
          .catch((error) => {
            showToast({ message: t("report_cache_build_failed"), type: "error" });
            console.error(error);
          });
      }
    };

    window.addEventListener("simplicateIframeMessage", onMessageReceived);

    return () => {
      window.removeEventListener("simplicateIframeMessage", onMessageReceived);
    };
  }, [dispatch, iFrameRef, t]);
};
