import { initiateGetV2Identifier } from "@simplicate/api-client";
import { store } from "@simplicate/state";
import { iframeToReactUrl } from "@simplicate/utils";
import { LoaderFunction, redirect } from "react-router-dom";

// Intended to be used as a loader function for a route that has expects to be redirected to a V1 URL by the backend.
export const followBackendRedirect: LoaderFunction = async ({ request }): Promise<Response> => {
  const response = await fetch(request.url, { redirect: "follow" });

  const conversion = iframeToReactUrl(response.url);

  switch (conversion.status) {
    case "pending-id-conversion": {
      const promise = store.dispatch(initiateGetV2Identifier.initiate(conversion.queryArguments));
      const { data } = await promise;

      promise.unsubscribe();

      if (!data) {
        return redirect("/404");
      }

      return redirect(conversion.locationWithId(data));
    }

    case "complete":
    case "navigate-top-level":
      return redirect(conversion.location);
  }

  if (request.url === response.url) {
    // Avoid an infinite loop.
    return redirect("/500");
  }

  return redirect(response.url);
};
