import {
  lightColorIconWarning,
  lightColorIconError,
  lightColorIconSuccess,
  lightColorIconInfo,
} from "@simplicate-software/design-tokens";
import classnames from "classnames";
import { PropsWithChildren } from "react";
import { Icon, IconName } from "../Icon";
import styles from "./Alert.module.scss";

export type AlertType = "error" | "info" | "success" | "warning";

type AlertProps = PropsWithChildren<{
  type: AlertType;
  width?: "intrinsic" | "max";
}>;

const ICON_MAP: Record<AlertType, IconName> = {
  error: "exclamationTriangle",
  info: "infoCircle",
  success: "check",
  warning: "exclamationCircle",
};

const ICON_COLOR_MAP: Record<
  AlertType,
  typeof lightColorIconError | typeof lightColorIconInfo | typeof lightColorIconSuccess | typeof lightColorIconWarning
> = {
  error: lightColorIconError,
  info: lightColorIconInfo,
  success: lightColorIconSuccess,
  warning: lightColorIconWarning,
};

export const Alert = ({ children, type, width = "intrinsic" }: AlertProps) => {
  return (
    <div className={classnames(styles.alert, styles[type], styles[width])}>
      <span>
        <Icon fixedWidth icon={ICON_MAP[type]} color={ICON_COLOR_MAP[type]} />
      </span>
      <div>{children}</div>
    </div>
  );
};
