import {
  BiIcon,
  CrmIcon,
  InsightsIcon,
  DashboardIcon,
  HoursIcon,
  HrmIcon,
  InvoicingIcon,
  PlanningIcon,
  ProjectsIcon,
  SalesIcon,
  SettingsIcon,
  SimplicateLogoIcon,
  SimplicateSmileIcon,
} from "@simplicate-software/icons";
import { SVGProps, memo } from "react";
import styles from "./ModuleIcon.module.scss";

type IconName =
  | "business-intelligence"
  | "crm"
  | "dashboard"
  | "environment-management"
  | "hours"
  | "hrm"
  | "insights"
  | "invoicing"
  | "logo"
  | "projects"
  | "resource-planner"
  | "sales"
  | "smile";

export type ModuleIcon = SVGProps<SVGSVGElement> & {
  module: IconName;
};

const IconMap = {
  "business-intelligence": BiIcon,
  "environment-management": SettingsIcon,
  "resource-planner": PlanningIcon,
  crm: CrmIcon,
  dashboard: DashboardIcon,
  hours: HoursIcon,
  hrm: HrmIcon,
  insights: InsightsIcon,
  invoicing: InvoicingIcon,
  projects: ProjectsIcon,
  sales: SalesIcon,
  logo: SimplicateLogoIcon,
  smile: SimplicateSmileIcon,
} as const satisfies Record<IconName, (props: SVGProps<SVGSVGElement>) => JSX.Element>;

export const ModuleIcon = memo(({ module, ...props }: ModuleIcon) => {
  const Icon = IconMap[module];

  return <Icon className={styles.moduleicon} {...props} />;
});

ModuleIcon.displayName = "ModuleIcon";
