import { LegacyWebComponent } from "../../components";
import { RouteObject } from "../types";

export const resourcePlannerRoutes: RouteObject[] = [
  {
    path: "planning",
    handle: { headerTitle: "module_resource_planner", theme: "resource-planner" },
    element: <LegacyWebComponent />,
  },
];
