import { useTranslation } from "@simplicate/translations";
import { NavMenu } from "@simplicate/ui";
import { UpSellTooltip } from "../UpSellTooltip";

export const ProjectsGetStarted = () => {
  const { t } = useTranslation("main_menu");

  return (
    <>
      <NavMenu.SubItem
        title={<UpSellTooltip title={t("projects_purchasing")} />}
        testId="projects-purchasing-menu-item"
        disabled
      />
      <NavMenu.SubItem title={t("projects_reporting")}>
        <NavMenu.SubItem
          title={<UpSellTooltip title={t("projects_revenue_reporting")} />}
          testId="projects-revenue-reporting-menu-item"
          disabled
        />
        <NavMenu.SubItem
          title={<UpSellTooltip title={t("projects_result_reporting")} />}
          testId="projects-result-reporting-menu-item"
          disabled
        />
        <NavMenu.SubItem
          title={<UpSellTooltip title={t("projects_forecasted_invoiced_reporting")} />}
          testId="projects-forecasted-invoiced-menu-item"
          disabled
        />
      </NavMenu.SubItem>
    </>
  );
};
