/* istanbul ignore file -- this is simple preset component */
import { useParams } from "react-router-dom";
import { DocumentEditor } from "./DocumentEditor";
import { DocumentEditorRouteParams } from "./types";

export const ContentBlockEditor = () => {
  const { id } = useParams<DocumentEditorRouteParams>();

  return <DocumentEditor mode="content-block" contentBlockId={id} />;
};
