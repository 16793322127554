import { useAppSelector, Locale } from "@simplicate/state";
import { Locale as DateFnsLocale, setDefaultOptions } from "date-fns";
import { nl, enGB } from "date-fns/locale";
import { PropsWithChildren, useEffect } from "react";

const LOCALE_MAP: Record<Locale, DateFnsLocale> = {
  nl: nl,
  en: enGB,
};

export const DateFnsProvider = ({ children }: PropsWithChildren) => {
  const { locale } = useAppSelector(/* istanbul ignore next */ ({ state }) => state.locale);

  useEffect(() => {
    setDefaultOptions({ locale: LOCALE_MAP[locale] });
  }, [locale]);

  return children;
};
