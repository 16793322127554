import { useEffect } from "react";
import { setHeader, useAppDispatch } from "../store";

export const usePageTitle = (title?: string) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (title !== undefined) {
      dispatch(setHeader({ title }));
    }
  }, [dispatch, title]);
};
